/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  selectedUserDetails,
  updateUser,
} from "../../store/actions/adminActions";
import StyledModal from "../../components/StyledModal/StyledModal";
import { Form, Input } from "antd";

const UserEditModal = ({ open, setOpen, user }) => {
  const [disabled, setDisabled] = useState(true);
  const [touched, setTouched] = useState(false);

  const [userBody, setUserBody] = useState({
    user_name: user.user_name,
    email: user.email,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    setUserBody({
      user_name: user.user_name,
      email: user.email,
    });
  }, [user]);
  const validateID = (val) => {
    return val.trim().length > 0; // Ensures the field is not empty
  };

  const dispatch = useDispatch();
  const handleEditInputChange = (e) => {
    setUserBody((val) => {
      if (!validateID(e.target.value)) {
        setDisabled(true);
        setTouched(true);

        const userP = { ...val };
        delete userP["emp_id"];
        return userP;
      }
      setDisabled(false);
      setTouched(false);
      return { ...val, emp_id: e.target.value };
    });
  };

  const callbackSuccessUpdate = () => {
    setOpen(false);
    dispatch(selectedUserDetails(user.id));
  };

  const handleSubmit = () => {
    dispatch(updateUser(user.id, userBody, callbackSuccessUpdate));
    form.resetFields();
  };
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
  };
  return (
    <StyledModal
      title={`Edit Details of ${user.user_name}`}
      centered
      open={open}
      onOk={handleSubmit}
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      width={"fit-content"}
      okButtonProps={{ disabled: disabled }}
    >
      <div className="modal-container">
        <Form form={form} {...formItemLayout} style={{ width: "100%" }}>
          <div className="bar-container">
            <Form.Item
              name="eId"
              label="ID"
              labelAlign="left"
              validateStatus={touched ? "error" : "success"}
              help={touched ? "ID is required: " : null}
            >
              <Input
                placeholder={user.emp_id === "CTE-0000" ? "" : user.emp_id}
                allowClear
                onChange={handleEditInputChange}
                data-testid="emp_id"
              />
            </Form.Item>
          </div>
          <div className="bar-container">
            <Form.Item name="email" label="Email" labelAlign="left">
              <Input
                disabled
                placeholder={user.email}
                allowClear
                onChange={handleEditInputChange}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </StyledModal>
  );
};

export default UserEditModal;
