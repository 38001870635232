import StyledModal from "../StyledModal/StyledModal";
import "./CandidateTestContainer.scss";
function StartConfirmTest({ open, handleClose, data }) {
  //   const dispatch = useDispatch();
  const handleOk = () => {
    handleClose();
    // dispatch(deleteTestDraftFromWindow(data.id, handleClose));
  };
  return (
    <StyledModal
      className={"start-test-modal"}
      centred
      title={"Start " + data?.test_name}
      visible={open}
      onCancel={handleClose}
      onOk={handleOk}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div className="start-confirm-test-container">
        <>
          <span>Test Instructions:</span>
          <ol>
            <li>
              Test Duration: You will have a limited time to complete the test.
            </li>
            <li>
              Tab Switching Prohibited: Switching tabs or leaving the test page
              during the test is strictly prohibited. Any attempt to switch tabs
              will result in the automatic submission of test. Ensure that you
              remain on the test page throughout the duration of the test.
            </li>
            <li>
              Technical Issues: In case of any technical issues or difficulties
              during the test, notify the invigilator immediately for
              assistance.
            </li>
          </ol>
        </>
      </div>
    </StyledModal>
  );
}

export default StartConfirmTest;
