import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faEnvelope,
  faFileCircleQuestion,
  faLock,
  faMicrochip,
  faSliders,
  faSwatchbook,
} from "@fortawesome/free-solid-svg-icons";

import { TeamOutlined } from "@ant-design/icons";
export const sidenavbar = {
  COMMON: [
    {
      to: "/",
      name: "Dashboard",
      key: "candidate_dashboard",
      icon: <FontAwesomeIcon icon={faChartPie} />,
    },
  ],
  CANDIDATE: [
    {
      to: "/courses",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
  ],
  PORTAL_ADMIN: [
    {
      to: "/courses/all",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
    {
      to: "/users",
      name: "Users",
      key: "admin_users",
      icon: <TeamOutlined style={{ fontSize: "21px" }} />,
    },
    {
      to: "/questionbank",
      name: "Question Bank",
      key: "question_bank",
      icon: (
        <FontAwesomeIcon
          icon={faFileCircleQuestion}
          style={{ fontSize: "21px" }}
        />
      ),
    },
  ],
  CREATOR: [
    {
      to: "/courses/all",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
  ],
  HR: [
    {
      to: "/courses",
      name: "Courses",
      key: "candidate_course",
      icon: (
        <FontAwesomeIcon icon={faSwatchbook} style={{ color: "#ffffff" }} />
      ),
    },
    {
      to: "/users",
      name: "Users",
      key: "admin_users",
      icon: <TeamOutlined style={{ fontSize: "21px" }} />,
    },
    {
      to: "/questionbank",
      name: "Question Bank",
      key: "question_bank",
      icon: (
        <FontAwesomeIcon
          icon={faFileCircleQuestion}
          style={{ fontSize: "21px" }}
        />
      ),
    },
  ],
  PORTAL_ADMIN_SETTINGS: [
    {
      to: "/admin-settings/general",
      name: "General",
      key: "general",
      icon: <FontAwesomeIcon icon={faSliders} style={{ color: "#ffffff" }} />,
    },
    {
      to: "/admin-settings/email",
      name: "Email",
      key: "email",
      icon: <FontAwesomeIcon icon={faEnvelope} style={{ color: "#ffffff" }} />,
    },
    {
      to: "/admin-settings/authentication",
      name: "Authentication",
      key: "authentication",
      icon: <FontAwesomeIcon icon={faLock} style={{ fontSize: "21px" }} />,
    },
    {
      to: "/admin-settings/aritifical-intelligence",
      name: "Artificial intelligence",
      key: "aritifical-intelligence",
      icon: <FontAwesomeIcon icon={faMicrochip} style={{ fontSize: "21px" }} />,
    },
  ],
};

export const CollapsedRoutes = ["/courses/:id"];
