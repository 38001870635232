/* eslint-disable no-unused-vars */
/* eslint-disable sort-imports */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearCourseList,
  loadCourses,
} from "../../../../../store/actions/courseAction";
import CourseCard from "../../../../../components/CourseCard/CourseCard";
import Spinner from "../../../../../components/Spinner/Spinner";
import { coursesListSelector } from "../../../../../store/selectors/coursesSelectors";
import "./AllCourseTabScreen.scss";
import { useSearchParams } from "react-router-dom";
import { PUBLISHED_COURSES_ID } from "../../../../../constants";
import {
  updateLimit,
  updateOffset,
} from "../../../../../store/actions/listParamsAction";
import Pagination from "../../../../../components/Pagination/Pagination";
import {
  getListLimit,
  getListOffset,
} from "../../../../../store/selectors/listParamsSelectors";

const Courses = ({ data }) => (
  <div
    className={`courses-container${data?.length === 0 ? "-empty" : ""}`}
    data-testid={`courses-container${data?.length === 0 ? "-empty" : ""}`}
  >
    {data?.length === 0 ? (
      <div>No results found</div>
    ) : (
      data?.map((course) => <CourseCard key={course.id} course={course} />)
    )}
  </div>
);

function AllCourseTabScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const coursesList = useSelector(coursesListSelector);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const searchListParams = useSelector(
    (state) => state.listParams[PUBLISHED_COURSES_ID]
  );

  const totalCount = coursesList?.count;
  const coursesListResult = coursesList?.results;

  const defaultValue = useSelector((state) =>
    getListLimit(state, PUBLISHED_COURSES_ID)
  );

  const offSetValue = useSelector((state) =>
    getListOffset(state, PUBLISHED_COURSES_ID)
  );

  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: PUBLISHED_COURSES_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };

  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: PUBLISHED_COURSES_ID,
        offset: value,
      })
    );
    dispatch(
      loadCourses(
        { status: "PUBLISHED" },
        {
          ...searchListParams,
          limit: defaultValue,
          offset: offSetValue,
        }
      )
    );
  };

  const loadCoursesList = () => {
    const filters = {};

    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }

    if (Object.keys(filters).length === 0) {
      dispatch(
        loadCourses(
          {
            ...searchListParams,
            status: "PUBLISHED",
            limit: defaultValue,
            offset: offSetValue,
          },
          setIsLoading
        )
      );
    }
  };

  // Initial setup when the component mounts
  useEffect(() => {
    dispatch(
      updateLimit({
        id: PUBLISHED_COURSES_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadCoursesList();
    return () => {
      dispatch(clearCourseList());
    };
  }, [defaultValue, offSetValue]);

  return (
    <div className="allCourse-container">
      {isLoading ? (
        <div className="allCourse-spinner">
          <Spinner />
        </div>
      ) : (
        <>
          <Courses data={coursesListResult} />
          <div className="allCourse-pagination">
            <Pagination
              totalCount={totalCount}
              defaultValue={defaultValue}
              onClickHandlerDefaultValue={changeDefaultValue}
              offSetValue={offSetValue}
              onClickHandlerOffSetValue={changeOffsetValue}
              userList={coursesList}
            />
          </div>
        </>
      )}{" "}
    </div>
  );
}

export default AllCourseTabScreen;
