import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../CourseEnrolled/CourseTabs.scss";
import PropTypes from "prop-types";
import { faPenToSquare, faTableList } from "@fortawesome/free-solid-svg-icons";
import { groupArraySelector } from "../../../../store/selectors/authSelectors";
import constants from "../../../../constants";
import { useNavigate } from "react-router-dom";

function UnEnrolledTabHeaders(props) {
  const groupsArr = useSelector(groupArraySelector);
  const [tabsContent, setTabContent] = useState([]);
  const allTabs = [
    {
      key: "statistics",
      name: "Statistics",
      icon: faTableList,
    },
    {
      key: "content",
      name: "Content",
      icon: faPenToSquare,
    },
    {
      key: "candidate",
      name: "Candidates",
      icon: faTableList,
    },
  ];
  useEffect(() => {
    if (groupsArr.length > 0) {
      if (
        groupsArr.includes(constants.PORTAL_ADMIN) ||
        groupsArr.includes(constants.HR)
      ) {
        setTabContent(allTabs);
      } else {
        allTabs.pop();
        setTabContent(allTabs);
      }
    }
  }, [groupsArr]);

  const { activeTab, setActiveTab } = props;
  const urlId = location.hash.split("/")[2];
  const navigate = useNavigate();
  const onItemClickHandle = (id) => {
    setActiveTab(id);
    navigate(`/courses/${urlId}/${id}/`);
  };
  return (
    <div className="courseTabs-container">
      {tabsContent?.map((content) => {
        return (
          <div
            data-testId="tabContainer"
            key={content.key}
            className={`tabItem ${activeTab === content.key ? "active" : ""} ${
              content.className
            }`}
            onClick={() => {
              onItemClickHandle(content.key);
            }}
          >
            <FontAwesomeIcon className="table-icon" icon={content.icon} />
            {content.name}
          </div>
        );
      })}
    </div>
  );
}

export default UnEnrolledTabHeaders;

UnEnrolledTabHeaders.propTypes = {
  tabsContent: PropTypes.array.isRequired,
};
