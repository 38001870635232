/* eslint-disable no-unused-vars */
import { SET_JOB_ACTION, SET_JOB_SELECTED } from "../actiontypes/index";

const initialState = {
  jobActionsList: [],
  isJobsSelected: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_JOB_ACTION: {
      return {
        ...state,
        jobActionsList: payload,
      };
    }
    case SET_JOB_SELECTED: {
      return {
        ...state,
        isJobsSelected: payload,
      };
    }
    default:
      return state;
  }
}
