import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  return localStorage.getItem("token") === null ? (
    <Navigate to="/" />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
