/* eslint-disable no-unused-vars */
import { actionFactory } from "../../libs/actionFactory";
import { encodeData } from "../../libs/jwt";
import {
  CLEAR_COURSES,
  GOOGLE_CLIENT_ID,
  ISCANCELLED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_MAIL,
} from "../actiontypes/index";
export const loadUserDetails = () => {
  return actionFactory({
    api: "get/api/users/user_details/",
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const picture = localStorage.getItem("picture");

            dispatch({
              type: LOGIN_SUCCESS,
              payload: { ...resp["data"].data, picture },
            });
          }
        })
        .catch((err) => {
          if (err["response"].data.status.status_message === "Token Expired") {
            clearLocalStorage();
            window.location.replace("/#/login");
          } else {
            clearLocalStorage();
            window.location.reload("/#/login");
          }
        });
    },
  });
};

export const registerUser = (user, picture) => {
  return actionFactory({
    api: "post/api/users/register/",
    actionBody: {
      body: user,
    },
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            // dispatch({
            //   type: LOGIN_SUCCESS,
            //   payload: { ...resp["data"].data.userDetails, picture },
            // });
            //commented beacuse it was calling API ?user=undefined while calling enroll api on userDAshboard page
            dispatch(loadUserDetails());
          } else {
            dispatch({ type: LOGIN_FAIL });
            dispatch(logoutUser());
          }
        })
        .catch((err) => {
          clearLocalStorage();
        });
    },
  });
};

export const validateTokenAction = (userData, handleError = () => {}) => {
  return actionFactory({
    api: "post/api/auth/validate-token",
    failureToast: true,
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            encodeData(resp["data"].data);
            localStorage.setItem("picture", resp["data"].data.picture);
            dispatch(
              registerUser(
                {
                  // emp_id: "CTE-1092",
                  user_name: resp["data"].data.name,
                  email: resp["data"].data.email,
                },
                resp["data"].data.picture
              )
            );
          } else {
            dispatch(logoutUser());
          }
        })
        .catch((_) => {
          clearLocalStorage();
          handleError();
        });
    },
  });
};
export const validateSignupemail = (
  userData,
  handleError = () => {},
  setSubmitting = () => {},
  setShowOtpFeild = () => {}
) => {
  return actionFactory({
    api: "post/api/users/signup/otp/generate/",
    failureToast: true,
    successToast: "OTP sent Sucessfully",
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp["data"] &&
            resp["data"].status &&
            resp["data"].status.status_type === "SUCCESS"
          ) {
            setSubmitting(false);
            setShowOtpFeild(true);
          }
        })
        .catch((_) => {
          handleError();
        });
    },
  });
};
export const validateOtp = (
  userData,
  handleError = () => {},
  setSubmitting = () => {},
  navigate = () => {}
) => {
  return actionFactory({
    api: "post/api/users/signup/otp/verify/",
    failureToast: true,
    successToast: "OTP verified Sucessfully",
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp["data"] &&
            resp["data"].status &&
            resp["data"].status.status_type === "SUCCESS"
          ) {
            setSubmitting(false);
            dispatch({ type: USER_MAIL, payload: userData.email });
            window.location.replace(
              `/#/users/${resp["data"]?.data?.id}/set-password`
            );
          }
        })
        .catch((_) => {
          userData.otp = "";
          navigate("/#/login");
          handleError();
        });
    },
  });
};
export const validateForgotPasswordemail = (
  userData,
  handleError = () => {},
  setSubmitting = () => {},
  setShowOtpFeild = () => {}
) => {
  return actionFactory({
    api: "post/api/users/signup/otp/generate/forgot-password/",
    failureToast: true,
    successToast: "OTP sent Sucessfully",
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp["data"] &&
            resp["data"].status &&
            resp["data"].status.status_type === "SUCCESS"
          ) {
            setSubmitting(false);
            setShowOtpFeild(true);
          }
        })
        .catch((_) => {
          handleError();
        });
    },
  });
};
export const validateOtpForgotPassword = (
  userData,
  handleError = () => {},
  setSubmitting = () => {},
  setShowOtpFeild = () => {}
) => {
  return actionFactory({
    api: "post/api/users/signup/otp/verify/",
    failureToast: true,
    successToast: "OTP verified Sucessfully",
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp["data"] &&
            resp["data"].status &&
            resp["data"].status.status_type === "SUCCESS"
          ) {
            setSubmitting(false);
            dispatch({ type: USER_MAIL, payload: userData.email });
            window.location.replace(
              `/#/users/${resp["data"]?.data?.id}/reset-password`
            );
          }
        })
        .catch((_) => {
          userData.otp = "";
          handleError();
        });
    },
  });
};

export const setTheUserPassword = (
  userData,
  resetPassword,
  handleError = () => {},
  setSubmitting = () => {},
  navigate = () => {}
) => {
  return actionFactory({
    api: `post/api/users/${userData.id}/set-password/`,
    failureToast: true,
    successToast: "Password set Sucessfully",
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp["data"] &&
            resp["data"].status &&
            resp["data"].status.status_type === "SUCCESS"
          ) {
            if (!resetPassword && userData.email) {
              dispatch(
                validateTokenAction(
                  {
                    username: userData.email,
                    password: userData.password,
                  },
                  () => {
                    setSubmitting(false);
                  }
                )
              );
            } else {
              navigate("/#/login");
              setSubmitting(false);
            }
          }
        })
        .catch((_) => {
          handleError();
        });
    },
  });
};

export const getClientId = () => {
  return actionFactory({
    api: "get/api/client-id",
    failureToast: true,
    actionBase: GOOGLE_CLIENT_ID,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: GOOGLE_CLIENT_ID,
            payload: resp["data"].data,
          });
        })
        .catch((_) => {});
    },
  });
};

const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("picture");
};

export const logoutUser = (dispatch) => {
  clearLocalStorage();
  dispatch({ type: CLEAR_COURSES });
  return dispatch({ type: LOGOUT });
};
export const isCancelled = (payload) => ({
  type: ISCANCELLED,
  payload: payload,
});
const redirectToSubdomain = (userData) => {
  const sign = require("jwt-encode");
  let jwtToken = sign(userData.user, "secret");
  window.location.href = `${window.location.protocol}//${userData.name}.${window.location.host}/#/signup?token=${jwtToken}`;
};

export const signUpAction = (
  userData,
  handleSuccess = () => {},
  handleError = () => {}
) => {
  return actionFactory({
    api: "post/api/users/signup/",
    failureToast: true,
    sucessToast: true,
    actionBody: {
      body: userData,
    },
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            handleSuccess();
            encodeData(resp["data"].data.userDetails);
            dispatch(loadUserDetails());
          }
        })
        .catch((_) => {
          handleError();
        });
    },
  });
};

export const createTenant = (
  userData,
  handleSuccess = () => {},
  handleError = () => {}
) => {
  return actionFactory({
    api: "post/api/tenant/",
    failureToast: true,
    sucessToast: true,
    actionBody: {
      body: userData,
    },
    callback: (respPromise) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            handleSuccess();
            if (resp["data"].data.name) redirectToSubdomain(resp["data"].data);
          }
        })
        .catch((_) => {
          clearLocalStorage();
          handleError();
        });
    },
  });
};

export const checkTenantExistence = (
  tenantName,
  handleSuccess = () => {},
  handleError = () => {}
) => {
  return actionFactory({
    api: "get/api/tenant/if_exist",
    failureToast: true,
    actionBody: {
      params: tenantName,
    },
    callback: (respPromise) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const data = resp?.data?.data;
            handleSuccess(data);
          }
        })
        .catch((_) => {
          handleError();
        });
    },
  });
};

// export const checkTheDomainUrl = (setDomainUrl) => {
//   return actionFactory({
//     api: "get/api/domain-url/",
//     failureToast: true,
//     actionBody: {},
//     callback: (respPromise) => {
//       respPromise
//         .then((resp) => {
//           if (resp["data"].status.status_type === "SUCCESS") {
//             setDomainUrl(resp["data"]?.data?.domain_url);
//           }
//         })
//         .catch((_) => {});
//     },
//   });
// };
