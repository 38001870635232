import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getQuestionsFromPdf,
  updateQuestionsInQuestionBank,
} from "../../../../store/actions/genAiActions";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ASK_AI_LIST_ID,
  GEN_AI_QUESTION_LIST_ID,
  REJECTED,
  REVIEWED,
} from "../../../../constants";
import {
  getListLimit,
  getListOffset,
} from "../../../../store/selectors/listParamsSelectors";
import {
  updateLimit,
  updateOffset,
} from "../../../../store/actions/listParamsAction";
import QuestionDeleteConfirmModal from "../../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionDeleteConfirmModal";
import { Breadcrumb, Checkbox, List } from "antd";
import { faBookOpen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Pagination from "../../../../components/Pagination/Pagination";
import Spinner from "../../../../components/Spinner/Spinner";

import { QuestionListItemHeader } from "../../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItemHeader";
import { QuestionListItem } from "../../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItem";
import "../../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionList.scss";
import "./QuestionsReviewList.scss";
import {
  genAiQuestionList,
  genAiSelectedJob,
} from "../../../../store/selectors/genAiSelectors";
import QuestionListHeader from "../../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListHeader";
import { ReactComponent as Arrow } from "../../../../assets/arrow.svg";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import { Link } from "react-router-dom";
import QuestionsReviewConfirmModal from "./QuestionsReviewConfirmModal";
import { userSelector } from "../../../../store/selectors/authSelectors";
import { getAllJobsList } from "../../../../store/actions/jobActions";
import { SET_JOB_SELECTED } from "../../../../store/actiontypes";
const CustomEmptyContent = () => (
  <div className="questionList-container-empty">No results found</div>
);
const QuestionsReviewList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const questions = useSelector(genAiQuestionList);
  const fileDetails = useSelector(genAiSelectedJob);
  const navigate = useNavigate();
  const [questionReviewModel, setQuestionReviewModel] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const user = useSelector(userSelector);
  const searchListParams = useSelector(
    (state) => state.listParams[GEN_AI_QUESTION_LIST_ID]
  );
  const [checkedArray, setCheckedArray] = useState([]);
  const defaultValue = useSelector((state) =>
    getListLimit(state, GEN_AI_QUESTION_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, GEN_AI_QUESTION_LIST_ID)
  );

  let limitValueJobList = useSelector((state) =>
    getListLimit(state, ASK_AI_LIST_ID)
  );
  let offSetValueJobList = useSelector((state) =>
    getListOffset(state, ASK_AI_LIST_ID)
  );
  useEffect(() => {
    if (questions !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [questions]);
  const totalCount = questions?.count;
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: GEN_AI_QUESTION_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: GEN_AI_QUESTION_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      getQuestionsFromPdf(fileDetails.id, {
        ...searchListParams,
        limit: defaultValue,
        offset: value,
      })
    );
  };
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: GEN_AI_QUESTION_LIST_ID,
          offset: 0,
        })
      );
      dispatch(
        getQuestionsFromPdf(fileDetails.id, {
          ...params,
        })
      );
    }
  };
  const onDeleteAll = (questionIds) => {
    setIsModalOpen(true);
    // Map each question ID to an object containing the ID and status
    const data = questionIds.map((question_id) => ({
      question_id: question_id,
      status: REJECTED, // Assuming REJECTED is a predefined constant
    }));
    setData(data); // Set the data to the array of objects
  };
  const AddAllToQuestionBank = (questionIds) => {
    const data = questionIds.map((question_id) => ({
      question_id: question_id,
      status: REVIEWED,
    }));
    setData(data);
    setIsLoading(false);
    dispatch(
      updateQuestionsInQuestionBank(
        fileDetails?.id,
        {
          questions: data,
        },
        successCallback
      )
    );
  };
  const onDelete = (question_id) => {
    setIsModalOpen(true);
    setData([
      {
        question_id: question_id[0],
        status: REJECTED,
      },
    ]);
  };
  const AddToQuestionBank = (question_id) => {
    setData([
      {
        question_id: question_id[0],
        status: REVIEWED,
      },
    ]);
    setIsLoading(false);
    dispatch(
      updateQuestionsInQuestionBank(
        fileDetails?.id,
        {
          questions: [
            {
              question_id: question_id[0],
              status: REVIEWED,
            },
          ],
        },
        successCallback
      )
    );
  };
  const loadAllQuestions = () => {
    setIsLoading(true);
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }
    if (Object.keys(filters).length === 0) {
      dispatch(
        getQuestionsFromPdf(fileDetails.id, {
          ...searchListParams,
          limit: defaultValue,
          offset: offSetValue,
        })
      );
    } else {
      handleSearch(filters);
    }
  };
  useEffect(() => {
    if (defaultValue !== undefined && offSetValue !== undefined) {
      loadAllQuestions();
    }
  }, [defaultValue]);
  useEffect(() => {
    dispatch(
      updateLimit({
        id: GEN_AI_QUESTION_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  // eslint-disable-next-line no-unused-vars
  const successCallback = (respPromise, dispatch, getState) => {
    setIsLoading(true);
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch(
            getQuestionsFromPdf(fileDetails.id, {
              limit: defaultValue,
              offset: offSetValue,
            })
          );
          const updatedCheckedArray = checkedArray.filter(
            (id) => !data.questions.includes(id)
          );
          setCheckedArray(updatedCheckedArray);
          setIsModalOpen(false);
          ("");
        }
      })
      .catch(() => {
        setIsModalOpen(false);
        setIsLoading(false);
      });
    setCheckedArray([]);
  };
  const onChange = (e) => {
    e.target.checked
      ? setCheckedArray([...checkedArray, e.target.value])
      : setCheckedArray(checkedArray.filter((item) => item !== e.target.value));
  };
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    setCheckAll(checkedArray.length === questions?.total_count);
  }, [checkedArray]);
  const onCheckAllChange = (e) => {
    setCheckedArray(
      e.target.checked ? questions?.results.map((item) => item.question_id) : []
    );
    setCheckAll(e.target.checked);
  };
  return !isLoading ? (
    <>
      <QuestionDeleteConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        data={data}
        onsuccesscallback={successCallback}
        loading={isLoading}
        setLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
        isQuestionReviewList={true}
        mcqId={fileDetails?.id}
      />
      <QuestionsReviewConfirmModal
        open={questionReviewModel}
        onCancel={() => setQuestionReviewModel(false)}
        mcqId={fileDetails?.id}
        setLoading={setIsLoading}
        onCallBack={() => {
          navigate("/ask-ai/jobs");
          dispatch(
            getAllJobsList(user.id, {
              limit: limitValueJobList,
              offset: offSetValueJobList,
            })
          );
        }}
      />

      <div className="questionList-container">
        <div className="questionList-breadcrumbs">
          {questions && (
            <Breadcrumb separator={<Arrow />}>
              <BreadcrumbItem>
                <Link
                  onClick={() => {
                    dispatch({
                      type: SET_JOB_SELECTED,
                      payload: false,
                    });
                  }}
                  to={"/ask-ai/jobs"}
                >
                  Jobs
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{fileDetails && fileDetails.name}</BreadcrumbItem>
            </Breadcrumb>
          )}
        </div>
        <QuestionListHeader
          isQuestionReviewList={true}
          fileName={fileDetails?.name}
          onDone={setQuestionReviewModel}
        />
        <div className="questionList-BanksearchBar">
          <div className="questionlist-checkbox-review">
            <Checkbox
              className="question-list-checkbox"
              onChange={onCheckAllChange}
              isChecked={checkAll}
            />
            <span className="question-list-checkbox-text">
              {checkedArray.length === 0
                ? `Questions (${questions?.results?.length}) `
                : `${checkedArray.length} Selected`}
            </span>
            {checkedArray.length === 0 ? null : (
              <>
                {" "}
                <span className="question-list-checkbox-separator">|</span>
                <span
                  className="question-list-delete-button"
                  onClick={() => onDeleteAll(checkedArray)}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ color: "black", marginBottom: "1px" }}
                  />
                  <span className="question-list-delete-button-text">
                    Delete
                  </span>
                </span>
                <span className="question-list-checkbox-separator">|</span>
                <span
                  className="question-list-delete-button"
                  onClick={() => AddAllToQuestionBank(checkedArray)}
                >
                  <FontAwesomeIcon
                    icon={faBookOpen}
                    style={{ color: "black", marginBottom: "1px" }}
                  />
                  <span className="question-list-delete-button-text">
                    Add to Question Bank
                  </span>
                </span>
              </>
            )}
          </div>
          <div className="questionList-searchbar-container">
            <SearchBar
              onSearch={(params, _) => {
                handleSearch(params);
              }}
            />
          </div>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={questions?.results}
          className="questionList-items"
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Checkbox
                    value={item.question_id}
                    onChange={(e) => onChange(e)}
                    checked={checkedArray.includes(item.question_id)}
                  />
                }
                title={
                  <QuestionListItemHeader
                    mcqId={fileDetails?.id}
                    isQuestionReviewList={true}
                    header={item}
                    index={index}
                    onDelete={onDelete}
                    AddToQuestionBank={AddToQuestionBank}
                  />
                }
                description={
                  <QuestionListItem
                    isQuestionReviewList={true}
                    index={index}
                    question={item}
                    options={item.options}
                    disabled={true}
                    givingTest={false}
                  />
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: <CustomEmptyContent /> }}
        />
        <div className="questionList-pagination">
          <Pagination
            totalCount={totalCount}
            userList={questions}
            defaultValue={defaultValue}
            offSetValue={offSetValue}
            onClickHandlerDefaultValue={changeDefaultValue}
            onClickHandlerOffSetValue={changeOffsetValue}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="questionReviewList">
      <Spinner />
    </div>
  );
};

export default QuestionsReviewList;
