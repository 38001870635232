import { useEffect } from "react";
import { useTimer } from "react-timer-hook";

const TestTimer = ({
  autoStart = undefined,
  testTime,
  onTimeComplete,
  isSubmitModal = false,
  raiseWarning = undefined,
}) => {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + testTime);

  const { seconds, minutes, hours, start } = useTimer({
    expiryTimestamp,
    autoStart: autoStart === undefined ? true : false,
    onExpire: () => onTimeComplete(),
  });
  useEffect(() => {
    if (hours * 60 * 60 + minutes * 60 + seconds < 300 && raiseWarning) {
      raiseWarning();
    }
  }, [minutes]);
  if (isSubmitModal) {
    // Render only seconds when isSubmitModal is true
    return (
      <div data-testid="test-timer">{String(seconds).padStart(2, "0")}</div>
    );
  }
  useEffect(() => {
    if (!autoStart) {
      start();
    }
  }, [autoStart]);
  return (
    <>
      {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
      {String(seconds).padStart(2, "0")}
    </>
  );
};

export default TestTimer;
