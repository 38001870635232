/* eslint-disable no-unused-vars */
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuestionManager.scss";
import { Breadcrumb, Button, Checkbox, Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import arrowDown from "../../../../assets/arrow-down.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Arrow } from "../../../../assets/arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import {
  questionFormOptions as options,
  quillFormats,
  quillModules,
} from "../../../../libs/constants";
import {
  currentEditingQuestion,
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";
import {
  createQuestion,
  getMcqs,
  getQuestion,
} from "../../../../store/actions/wizardActions";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import { isEmpty, isEqual } from "lodash";
import {
  createQuestioninQuestionBank,
  searchTag,
} from "../../../../store/actions/genAiActions";
import {
  genAiSelectedJob,
  getEditQuestion,
} from "../../../../store/selectors/genAiSelectors";
import { EDIT_QUESTION_QB } from "../../../../store/actiontypes";

const keyCodes = {
  commas: 188,
  enter: 13,
};
const delimiters = [keyCodes.commas, keyCodes.enter];

const TagInputField = ({ field, form }) => {
  const dispatch = useDispatch();
  const [suggestionsTags, setSuggestionsTags] = useState([]);
  useEffect(() => {
    dispatch(searchTag(setSuggestionsTags));
  }, []);
  const handleDelete = (i) => {
    const newTags = field.value.filter((tag, index) => index !== i);
    form.setFieldValue(field.name, newTags);
  };

  const handleAddition = (tag) => {
    const newTags = [...field.value, tag];
    form.setFieldValue(field.name, newTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...field.value];
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    form.setFieldValue(field.name, newTags);
  };
  return (
    <div id="tags">
      <ReactTags
        placeholder=""
        tags={field.value}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        inputFieldPosition="inline"
        autocomplete
        autofocus={false}
        suggestions={suggestionsTags}
      />
    </div>
  );
};

const QuestionForm = ({ handlCancel }) => {
  const [labelText, setLabelText] = useState("Single choice");
  const location = useLocation();
  const genAiCreationPage =
    location.pathname.includes("/new-question") ||
    location.pathname.includes("/edit-question");
  const selectedJob = useSelector(genAiSelectedJob);
  const currentEditingTestList = useSelector(currentEditingTestDetails);
  const currentEditingQuestionList = useSelector(currentEditingQuestions);
  const currentEditQuestion = useSelector(currentEditingQuestion);
  const dispatch = useDispatch();
  const courseId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const [SaveButtonLoader, setSaveButtonLoader] = useState(false);
  const testId = location.pathname.split("/")[4];

  const [initialValues, setinitialValues] = useState({
    question: "",
    questionType: "Single",
    answers: ["", ""],
    points: 1,
    correctAnswer: [],
    tags: [],
  });

  const question_details = useSelector(currentEditingQuestion);
  const edit_Question = useSelector(getEditQuestion);

  useEffect(() => {
    if (!location.search.includes("id=new") && !genAiCreationPage) {
      dispatch(getQuestion(location.search.split("=")[1]));
    }
  }, []);

  useEffect(() => {
    if (!location.search.includes("id=new") && !genAiCreationPage) {
      if (question_details) {
        const options = question_details[0].options;
        const answers = [];
        const correct_answer = [];

        options.forEach((option, index) => {
          answers.push(option.option_text);
          if (option.is_answer) {
            correct_answer.push(index);
          }
        });
        const test_data = {
          question: question_details[0].question_text,
          questionType: question_details[0].question_type,
          answers: answers,
          points: question_details[0].points,
          correctAnswer: correct_answer,
        };
        setinitialValues(test_data);
      }
    }
    if (genAiCreationPage && !isEmpty(edit_Question)) {
      const options = edit_Question.options;
      const answers = [];
      const correct_answer = [];
      const formattedTags = edit_Question?.tags.map((tag) => ({
        id: tag.id,
        text: tag.tag_name,
      }));
      options.forEach((option, index) => {
        answers.push(option.option_text);
        if (option.is_answer) {
          correct_answer.push(index);
        }
      });
      const test_data = {
        question: edit_Question.question_text,
        questionType: edit_Question.question_type,
        answers: answers,
        points: edit_Question.points,
        correctAnswer: correct_answer,
        tags: formattedTags,
      };
      setinitialValues(test_data);
    }
    const handleHashChange = () => {
      const hashValue = window.location.hash.slice(1);
      if (hashValue.includes("new-question")) {
        setinitialValues({
          question: "",
          questionType: "Multiple",
          answers: ["", ""],
          points: 1,
          correctAnswer: [],
          tags: [],
        });
      }
    };
    window.addEventListener("hashchange", handleHashChange);

    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [question_details, edit_Question, window.location.hash]);
  const isEditPage = location.pathname.includes("/edit-question");
  const isNewPage = location.pathname.includes("/new-question");
  return (
    <div className="question-manager-container" style={{ width: "100%" }}>
      <Breadcrumb separator={<Arrow />}>
        <BreadcrumbItem>
          <Link
            to={
              genAiCreationPage
                ? "/question-bank"
                : `/courses/${courseId}/test/${currentEditingTestList?.id}/manager`
            }
          >
            {genAiCreationPage ? "Question Bank" : "Question Manager"}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          {isNewPage
            ? "New Question"
            : isEditPage
            ? "Edit Question"
            : !location.search.includes("id=new") && question_details
            ? "Q" + question_details[0].order_no
            : "New Question"}
        </BreadcrumbItem>
      </Breadcrumb>
      <p className="heading-test-question">
        {isNewPage
          ? "New Question"
          : isEditPage
          ? "Edit Question"
          : !location.search.includes("id=new") && question_details
          ? "Q" + question_details[0].order_no
          : "New Question"}
      </p>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (
            !values.question ||
            (!values.question.includes("img") &&
              values.question.replace(/<(.|\n)*?>/g, "").trim().length === 0) ||
            values.question.trim() === " "
          ) {
            errors.question = "Field should not be empty";
          }
          if (values.answers.length < 1) {
            errors.answers = "Minimum allowed number of answers is 1 ";
          }
          if (
            values?.correctAnswer.length === 0 &&
            values.questionType === "Single"
          ) {
            errors.correctAnswer = "Please select only one correct answer";
          }
          if (
            values?.correctAnswer.length === 0 &&
            values.questionType === "Multiple"
          ) {
            errors.correctAnswer = "Please select at least one correct answer";
          }
          if (
            values?.correctAnswer.length === 0 &&
            values.questionType === "Subjective"
          ) {
            errors.correctAnswer = "Please provide answer.";
          }
          if (values.answers.includes("")) {
            errors.answers = "Options should not be empty";
          }
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          const data = !genAiCreationPage
            ? {
                test: currentEditingTestList?.id,
                order_no: !location.search.includes("id=new")
                  ? currentEditQuestion[0].order_no
                  : currentEditingQuestionList.count + 1,
                questions: {
                  id: !location.search.includes("id=new")
                    ? question_details[0].question_id
                    : null,
                  question_text: values.question,
                  question_type: values.questionType,
                  question_weightage: values.points,
                },
                options: values.answers.map((answer, index) => ({
                  option_text: answer,
                  is_answer: values?.correctAnswer.includes(index),
                })),
              }
            : {
                questions: {
                  id: edit_Question ? edit_Question.question_id : null,
                  question_text: values.question,
                  question_type: values.questionType,
                  question_weightage: values.points,
                  tags: values.tags.map((tag) => tag.text),
                },
                options: values.answers.map((answer, index) => ({
                  option_text: answer,
                  is_answer: values?.correctAnswer.includes(index),
                })),
              };
          !genAiCreationPage &&
            dispatch(createQuestion(data, setSaveButtonLoader));
          !genAiCreationPage && dispatch(getMcqs(testId));
          genAiCreationPage &&
            dispatch(
              createQuestioninQuestionBank(
                data,
                setSaveButtonLoader,
                isEditPage
              )
            );
          edit_Question && dispatch({ type: EDIT_QUESTION_QB, payload: {} });
          if (values.buttonClicked === "saveAndNext") {
            resetForm();
            setSaveButtonLoader(true);
          } else if (values.buttonClicked === "save" && !genAiCreationPage) {
            navigate(`/courses/${courseId}/test/${testId}/manager`);
          } else {
            if (
              location.pathname.includes("/edit-question") &&
              selectedJob.id
            ) {
              navigate(`/ask-ai/jobs/${selectedJob.id}`);
            } else {
              navigate("/question-bank");
            }
          }
        }}
      >
        {({ values, setFieldValue, setFieldTouched, errors, validateForm }) => (
          <Form>
            <div className="form-group">
              <h3 className="question-title">Question</h3>
              <Field name="question">
                {({ field }) => (
                  <ReactQuill
                    {...field}
                    name="question"
                    placeholder="Enter your question here."
                    style={{ height: "233px" }}
                    onChange={(content, delta, source, editor) => {
                      setFieldValue("question", content);
                      setTimeout(() => validateForm(), 0);
                    }}
                    onBlur={() => setFieldTouched("question", true)}
                    value={values.question}
                    modules={quillModules}
                    formats={quillFormats}
                  />
                )}
              </Field>
            </div>
            <ErrorMessage
              name="question"
              component="div"
              className="error-message"
            />

            <div className="form-group2">
              <label htmlFor="questionType" className="question-title">
                Question Type
              </label>
              <Field
                id="questionType"
                name="questionType"
                type="select"
                component={StyledInputField}
                defaultValue={values.questionType}
                onChange={(e) => {
                  const selectedValue = e;
                  setFieldValue("questionType", selectedValue);
                  setFieldValue("correctAnswer", []);
                  setLabelText(selectedValue);
                  setTimeout(() => validateForm(), 0);
                }}
                options={options}
                suffixIcon={<img src={arrowDown} alt="arrow-down" />}
              />
              {genAiCreationPage && (
                <>
                  <label htmlFor="tags" className="question-title">
                    Tags
                  </label>
                  <p id="tags-para" className="question-description">
                    Enter tags separated by comma.
                  </p>
                  <Field name="tags" component={TagInputField} />
                </>
              )}
            </div>

            <div className="form-group2">
              <label>Answers</label>
              <p className="question-description">
                After providing possible answers, select the correct one.
              </p>

              <ErrorMessage
                name="answers"
                component="div"
                className="error-message"
              />
              {errors.correctAnswer ? (
                <div className="error-message">{errors.correctAnswer}</div>
              ) : null}
              <FieldArray name="answers">
                {({ remove, push }) =>
                  values.questionType !== "Subjective" ? (
                    <div className="answers-array">
                      {values?.answers?.map((answer, index) => (
                        <div key={index} className="answer-item">
                          {values.questionType === "Single" ? (
                            <Radio.Group
                              onChange={(e) => {
                                const checkedValue = e.target.value;
                                const updatedCorrectAnswer = [checkedValue];
                                setFieldValue(
                                  "correctAnswer",
                                  updatedCorrectAnswer
                                );
                              }}
                              value={values?.correctAnswer[0]}
                            >
                              <Radio value={index}></Radio>
                            </Radio.Group>
                          ) : (
                            <Checkbox
                              className="square-checkbox"
                              value={index}
                              checked={values?.correctAnswer.includes(index)}
                              onChange={(e) => {
                                const checkedValue = e.target.value;
                                if (e.target.checked) {
                                  const updatedCorrectAnswer = [
                                    ...values.correctAnswer,
                                    checkedValue,
                                  ];
                                  setFieldValue(
                                    "correctAnswer",
                                    updatedCorrectAnswer
                                  );
                                } else {
                                  const updatedCorrectAnswer =
                                    values?.correctAnswer.filter(
                                      (value) => value !== checkedValue
                                    );
                                  setFieldValue(
                                    "correctAnswer",
                                    updatedCorrectAnswer
                                  );
                                }
                              }}
                            ></Checkbox>
                          )}

                          <Field
                            as="textarea"
                            name={`answers.${index}`}
                            className="form-control"
                            placeholder="Enter your option here."
                          />

                          <Button
                            type="button"
                            onClick={() => remove(index)}
                            className="remove-answer-btn"
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="delete-icon"
                            />
                          </Button>
                        </div>
                      ))}

                      <Button
                        type="button"
                        onClick={() => push("")}
                        className="add-answer-btn"
                      >
                        Add Answer
                      </Button>
                    </div>
                  ) : (
                    <div className="answers-array">
                      <Field
                        as="textarea"
                        name={"answers.0"}
                        className="form-control"
                        placeholder="Enter your answer here."
                        onChange={(e) => {
                          const updatedAnswer = e.target.value;
                          const updatedAnswers = [values.answers];
                          updatedAnswers[0] = updatedAnswer;
                          setFieldValue("answers", updatedAnswers);
                          setFieldValue("correctAnswer", [0]);
                          setTimeout(() => validateForm(), 0);
                        }}
                      />
                    </div>
                  )
                }
              </FieldArray>
            </div>
            <div>
              <h3>Score Settings</h3>
              <p className="question-description">
                Define the score for a correct answer.
              </p>
            </div>
            <div className="form-group">
              {/* <label htmlFor="points">Points for correct Answer</label> */}
              <p className="question-description">Points for correct Answer</p>
              <Field name="points" type="number" className="form-control" />
            </div>
            <div className="score-settings">
              <Button
                type="primary"
                onClick={
                  genAiCreationPage
                    ? () => {
                        if (
                          location.pathname.includes("/edit-question") &&
                          selectedJob.id !== undefined
                        ) {
                          dispatch({ type: EDIT_QUESTION_QB, payload: {} });
                          navigate(`/ask-ai/jobs/${selectedJob.id}`);
                        } else {
                          navigate("/question-bank");
                        }
                      }
                    : handlCancel
                }
                className="cancel-btn"
              >
                Cancel
              </Button>
              {location.search.includes("id=new") ||
              (genAiCreationPage && isEmpty(edit_Question)) ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  disabled={
                    isEqual(values, initialValues) ||
                    Object.keys(errors).length !== 0
                  }
                  loading={SaveButtonLoader}
                  onClick={() => {
                    setFieldValue("buttonClicked", "saveAndNext");
                  }}
                >
                  Save and add next
                </Button>
              ) : null}
              <Button
                type="primary"
                htmlType="submit"
                className="submit-btn"
                disabled={
                  isEqual(values, initialValues) ||
                  Object.keys(errors).length !== 0
                }
                onClick={() => {
                  setFieldValue("buttonClicked", "save");
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default QuestionForm;
