import "./GeneralSettingPage.scss";
import { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Breadcrumbs from "../../../components/BreadCrumb/BreadCrumbs";
import { adminSettingConstants } from "../../../libs/constants";
import { Button } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  GetGeneralSettingInstance,
  PostGeneralSettings,
} from "../../../store/actions/adminActions";
import { useSelector } from "react-redux";
import { getGeneralSettings } from "../../../store/selectors/adminSelectors";
import Spinner from "../../../components/Spinner/Spinner";
import { GeneralSettingUtils } from "../AdminSettingsUtils";
import { StyledInputField } from "../../../components/StyledFields/StyledInputField";
import withPrompt from "../../../hooks/withPrompt";
import { setSaveButtonDisabled } from "../../../store/actions/adminActions";
import { isSaveButtonDisabledSelector } from "../../../store/selectors/adminSelectors";
const GeneralSettingPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isloading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const userGeneralSettings = useSelector((state) => getGeneralSettings(state));
  const dispatch = useDispatch();
  const omittedPositions = [0];
  const [hasImageError, setHasImageError] = useState(false);
  const allowedTypes = ["image/png", "image/jpeg"];
  const [errorMessage, setErrorMessage] = useState("");
  const maxDimensions = 512;
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);

  const validateImage = (image) => {
    const fileSizeInMB = image.size / (1024 * 1024);

    if (!allowedTypes.includes(image.type)) {
      return {
        isValid: false,
        message: "Supported file types are PNG and JPEG.",
      };
    }

    if (fileSizeInMB > 2) {
      return {
        isValid: false,
        message: "Maximum supported image size is 2MB.",
      };
    }

    return { isValid: true, message: "" };
  };
  const path = useLocation();
  const INITIAL_FORM_VALUES = {
    instance_name: userGeneralSettings?.instance_name || "",
    instance_email: userGeneralSettings?.instance_email || "",
    instance_logo: userGeneralSettings?.instance_logo || null,
  };
  const isBase64 = (str) => {
    if (typeof str !== "string" || str.length % 4 !== 0) {
      return false;
    }
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
    return base64Regex.test(str);
  };

  const decodeBase64Image = (base64String, imageType = "jpeg") => {
    if (!base64String) {
      throw new Error("No Base64 string provided");
    }
    return `data:image/${imageType};base64,${base64String}`;
  };
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const image = e.target.files[0];
      const validation = validateImage(image);

      if (!validation.isValid) {
        setHasImageError(true);
        setErrorMessage(validation.message);
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          if (img.width > maxDimensions || img.height > maxDimensions) {
            setHasImageError(true);
            setErrorMessage(
              `Maximum supported dimensions are ${maxDimensions}px by ${maxDimensions}px.`
            );
            return;
          }

          setSelectedImage(URL.createObjectURL(image));
          setHasImageError(false);
          setErrorMessage("");
        };

        img.onerror = () => {
          setHasImageError(true);
          setErrorMessage("Failed to load the image. Please try another file.");
        };
      };
      reader.readAsDataURL(image);
    }
  };
  useEffect(() => {
    dispatch(GetGeneralSettingInstance(setLoading));
  }, [path.pathname]);

  const handleRemoveImage = (setFieldValue, e) => {
    setSelectedImage(null);
    setFieldValue("instance_logo", null);
    // Reset the file input value to allow uploading the same image again
    if (e) {
      e.target.value = null;
    }
  };

  const handleUpdateSettings = (values) => {
    setSaving(true);
    const instance_logo = isBase64(values.instance_logo)
      ? null
      : values.instance_logo;
    dispatch(
      PostGeneralSettings(
        values.instance_name,
        values.instance_email,
        instance_logo,
        setSaving
      )
    );
  };

  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  useEffect(() => {
    setInitialValues(INITIAL_FORM_VALUES);
    setFormValues(INITIAL_FORM_VALUES);
  }, [userGeneralSettings]);

  useEffect(() => {
    const isChanged =
      formValues.instance_name !== initialValues.instance_name ||
      formValues.instance_email !== initialValues.instance_email ||
      formValues.instance_logo !== initialValues.instance_logo;
    dispatch(setSaveButtonDisabled(!isChanged));
  }, [formValues, initialValues, dispatch]);

  const handleFormChange = (values) => {
    setFormValues(values);
  };

  return (
    <>
      {isloading ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className="admin-general-container">
          <Breadcrumbs omit={omittedPositions} />
          <div className="admin-general-heading">
            General Settings
            <p>{adminSettingConstants["GENERAL_SETTINGS"]}</p>
          </div>
          <p className="general-settings-instance-details">Instance details</p>
          <Formik
            initialValues={INITIAL_FORM_VALUES}
            validationSchema={GeneralSettingUtils}
          >
            {({ values, setFieldValue }) => (
              <Form
                className="generalsetting-form"
                onChange={() => handleFormChange(values)}
              >
                <div className="generalSetting-form-container">
                  <div>
                    <div className="admin-general-details">
                      <div className="field-container">
                        <Field
                          label="Name of Instance"
                          id="instance_name"
                          type="text"
                          component={StyledInputField}
                          name="instance_name"
                          placeholder="Some organization Pvt. Ltd."
                          onChange={(event) => {
                            setFieldValue("instance_name", event.target.value);
                            handleFormChange({
                              ...values,
                              instance_name: event.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="field-container">
                        <Field
                          label="Email"
                          id="instance_email"
                          type="email"
                          name="instance_email"
                          component={StyledInputField}
                          placeholder="admin-email.someorg.com"
                          onChange={(event) => {
                            setFieldValue("instance_email", event.target.value);
                            handleFormChange({
                              ...values,
                              instance_email: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="admin-general-branding">
                      Customize your branding
                      <p>{adminSettingConstants["CUSTOMIZE_BRANDING"]}</p>
                      {!hasImageError &&
                        (selectedImage ? (
                          <img src={selectedImage} alt="Selected" />
                        ) : (
                          <>
                            {values.instance_logo && (
                              <img
                                src={decodeBase64Image(
                                  values.instance_logo,
                                  "jpg"
                                )}
                                alt="Selected"
                              />
                            )}
                          </>
                        ))}
                      <p
                        className={
                          hasImageError
                            ? "image-error-show"
                            : "image-error-hide"
                        }
                      >
                        {errorMessage}
                      </p>
                      <div className="admin-general-branding-buttons">
                        <label>
                          Choose Image
                          <input
                            id="instance_logo"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(event) => {
                              setFieldValue(
                                "instance_logo",
                                event.target.files[0]
                                // URL.createObjectURL(event.target.files[0])
                              );

                              handleFormChange({
                                ...values,
                                instance_logo: event.target.files[0],
                              });

                              handleImageChange(event);
                            }}
                            onClick={(e) => (e.target.value = null)}
                            style={{ display: "none" }}
                          />
                        </label>
                        {selectedImage && !hasImageError && (
                          <label
                            onClick={(e) => handleRemoveImage(setFieldValue, e)}
                          >
                            Remove
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      disabled={isSaveButtonDisabled}
                      className="admin-general-save-button"
                      loading={saving}
                      onClick={() => handleUpdateSettings(values)}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withPrompt(GeneralSettingPage);
