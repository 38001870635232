import { useEffect, useState } from "react";
import "./OwnedCourseTabScreen.scss";
import EmptyContainerImage from "../../../../../assets/owned-courses-empty.png";
import GlobalEmptyContainer from "../../../../../components/GlobalEmptyContainer/GlobalEmptyContainer";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  // clearDraftCourseList,
  clearOwnedCourseList,
  draftCallback,
  loadOwnedCoursesList,
  ownedCallback,
} from "../../../../../store/actions/courseAction";
import { userSelector } from "../../../../../store/selectors/authSelectors";
import {
  draftCoursesSelector,
  ownedCoursesSelector,
} from "../../../../../store/selectors/coursesSelectors";
import DraftCourses from "./DraftCourses";
import Spinner from "../../../../../components/Spinner/Spinner";
import PublishedCourses from "./PublishedCourses";

function OwnedCourseTabScreen({ setIsWizardOpen, draftRefresher }) {
  // eslint-disable-next-line no-unused-vars
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const buttonProps = {
    label: "Get Started",
    className: "get-started-button",
    onClick: setIsWizardOpen,
  };
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const user = useSelector(userSelector);

  const loadDraftAndOwnedCoursesList = () => {
    dispatch(
      loadOwnedCoursesList(
        user.id,
        { status: "DRAFT,REJECTED,IN_REVIEW" },
        draftCallback
      )
    );
    const filters = {};

    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }
    if (Object.keys(filters).length === 0) {
      dispatch(
        loadOwnedCoursesList(user.id, { status: "PUBLISHED" }, ownedCallback)
      );
    }
  };

  const draftcoursesList = useSelector(draftCoursesSelector);
  const ownedCoursesList = useSelector(ownedCoursesSelector);

  useEffect(() => {
    setIsLoading(true);
    loadDraftAndOwnedCoursesList();
    return () => {
      // dispatch(clearDraftCourseList());
      dispatch(clearOwnedCourseList());
    };
  }, [draftRefresher]);

  useEffect(() => {
    if (draftcoursesList !== null && ownedCoursesList !== null) {
      setIsLoading(false);
      if (draftcoursesList.length !== 0 || ownedCoursesList.length !== 0) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    } else {
      setIsLoading(true);
    }
  }, [draftcoursesList, ownedCoursesList]);
  return (
    <div className="ownedCourseTabScreen-container">
      {isLoading ? (
        <div style={{ height: "450px", alignItems: "center" }}>
          <Spinner />
        </div>
      ) : isEmpty && searchParams?.size >= 0 ? (
        <GlobalEmptyContainer
          imgContent={<img src={EmptyContainerImage} alt="Empty container" />}
          content={
            <>
              {searchParams?.size === 0 ? (
                <p className="title">Course Creation Center</p>
              ) : (
                <p className="title"></p>
              )}
              {searchParams?.size === 0 ? (
                <p className="subtitle">
                  Empower Your Organization by Designing Customized Learning
                  Experiences
                </p>
              ) : (
                <p className="subtitle">No Courses Found</p>
              )}
            </>
          }
          actionButton={searchParams?.size === 0 ? buttonProps : null}
        />
      ) : (
        <>
          <div>
            <DraftCourses
              draftcourseList={draftcoursesList}
              setIsWizardOpen={setIsWizardOpen}
            />
          </div>
          <div>
            <PublishedCourses publishedCoursesList={ownedCoursesList} />
          </div>
        </>
      )}
    </div>
  );
}

export default OwnedCourseTabScreen;
