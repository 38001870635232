import { Switch } from "antd";
import { postAuthGoogleSetting } from "../../../../store/actions/adminActions";
import { isEmpty } from "lodash";
import Spinner from "../../../../components/Spinner/Spinner";
import { useEffect, useState } from "react";

const GoogleSettingHeaderSection = ({
  title,
  desc,
  isGoogleAuth,
  setIsGoogleAuth,
  dispatch,
  currentGoogleSetting,
}) => {
  const [isAuthGoogleLoading, setIsAuthGoogleLoading] = useState(false);
  const [isNotAcceptedGoogle, setIsNotAcceptedGoogle] = useState(false);
  const onHandleClick = () => {
    setIsGoogleAuth(!isGoogleAuth);
    setIsNotAcceptedGoogle(false);
    if (
      !isEmpty(currentGoogleSetting.client_id) &&
      !isEmpty(currentGoogleSetting.secret_key)
    ) {
      const newData = {
        ...currentGoogleSetting,
        allow_google_login: !isGoogleAuth ? "YES" : "NO",
      };
      setIsAuthGoogleLoading(true);
      dispatch(
        postAuthGoogleSetting(
          newData,
          setIsNotAcceptedGoogle,
          setIsAuthGoogleLoading,
          () => {}
        )
      );
    }
  };
  useEffect(() => {
    if (isNotAcceptedGoogle) {
      setIsGoogleAuth(true);
    }
  }, [isNotAcceptedGoogle]);
  return (
    <div className="google-header-container">
      <div className={"google-header-section"}>
        <h3 className="google-header-title">{title}</h3>
        <span className="google-header-desc">{desc}</span>
      </div>
      <div className={isAuthGoogleLoading ? "spinner_blank_google_login" : ""}>
        {!isAuthGoogleLoading ? (
          <Switch size="small" checked={isGoogleAuth} onClick={onHandleClick} />
        ) : (
          <>
            <Spinner small={"blank"} />
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleSettingHeaderSection;
