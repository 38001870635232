import { useEffect, useState } from "react";
import AskAITabsDisplay from "./AskAITabsDisplay";
import "./AskAI.scss";
import { useLocation } from "react-router-dom";
const AskAI = () => {
  // used for tab switching
  const [activeTab, setActiveTab] = useState("add");
  const location = useLocation();

  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "jobs" || segment.toLowerCase() === "add"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);

  return (
    <div className="ask-ai-container">
      <div className="ask-ai-title">Ask AI</div>
      <div className="ask-ai-content">
        <AskAITabsDisplay activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </div>
  );
};

export default AskAI;
