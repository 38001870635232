import StyledModal from "../../components/StyledModal/StyledModal";
import { Button, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { enrolledCourse } from "../../store/selectors/coursesSelectors";
import {
  // eslint-disable-next-line no-unused-vars
  clearCourseList,
  createBranchesbyCourse,
  enrollIntoCourse,
  loadEnrolledCourses,
  unEnrollIntoCourse,
} from "../../store/actions/courseAction";
import { isNull } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import constants, { COURSES_LIST_ID } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../components/SearchBar/SearchBar";
import { searchPropsForCoursesList } from "../../constants/SearchBarProps";
import { updateOffset } from "../../store/actions/listParamsAction";

const UserEnrollmentModal = ({
  open,
  setOpen,
  user,
  courseList,
  nextPage,
  prevPage,
  isLoading,
  coursesTotalCount,
  offSetValue,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const enrolledCourses = useSelector(enrolledCourse);
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const [loading, setLoading] = useState({
    spin: false,
    message: "",
  });
  const callBackLoadEnroll = () => {
    setLoading({
      ...loading,
      spin: false,
    });
  };
  function loadUserEnrolledCourses() {
    dispatch(loadEnrolledCourses(user.id, {}, callBackLoadEnroll));
    setLoading({
      ...loading,
      spin: true,
      message: "",
    });
  }
  useEffect(() => {
    loadUserEnrolledCourses();
  }, [user]);
  useEffect(() => {
    getDefaultCourse();
  }, [enrolledCourses, courseList]);

  function getDefaultCourse() {
    let dataSource = [];
    if (!isNull(courseList) && !isNull(enrolledCourses)) {
      courseList?.results?.forEach((course, index) => {
        let ifEnrolled = enrolledCourses.find((enroll) => {
          return enroll.enroll_details[0].course.id === course.id;
        });
        let data = {
          key: index,
          CourseName: course.course_name,
          Author: course.created_by,
          isEnrolled: ifEnrolled !== undefined ? true : false,
          course_id: course.id,
          enroll_id: ifEnrolled?.enroll_details[0].id,
        };
        dataSource.push(data);
      });
      setDataSource(dataSource);
      setFilteredData(dataSource);
      setLoading({ ...loading, spin: false });
    }
  }
  const columns = [
    {
      title: <span style={{ fontWeight: "bold" }}>Course Name</span>,
      dataIndex: "CourseName",
      key: "CourseName",
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Author</span>,
      dataIndex: "Author",
      key: "Author",
      render: (_, elem) => (
        <>{elem.Author !== null ? elem.Author.user_name : "Coriolis Team"}</>
      ),
    },
    {
      title: <span style={{ fontWeight: "bold" }}>Action</span>,
      key: "action",
      align: "left",
      render: (_, elem) => (
        <Button
          size="medium"
          className={`enroll-buttons  ${
            elem.isEnrolled ? "unenroll-color" : "enroll-color"
          } `}
          onClick={() => onOk(elem.course_id, elem.isEnrolled, elem.enroll_id)}
          data-testid={"enroll-button-" + elem.course_id}
        >
          {elem.isEnrolled ? constants.UN_ENROLL : constants.ENROLL}
        </Button>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const enrollCourseCallback = (respPromise, dispatch, getState) => {
    setLoading({
      ...loading,
      spin: true,
      message: "Please wait.. Enrollment in progress",
    });
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          if (resp["data"]?.data?.course?.lab_required === "YES") {
            setLoading({
              ...loading,
              spin: true,
              message: "Please wait.. Creating Branches",
            });
            dispatch(
              createBranchesbyCourse(
                resp["data"]?.data?.course?.id,
                user.emp_id,
                () => {
                  loadUserEnrolledCourses();
                }
              )
            );
          } else {
            loadUserEnrolledCourses();
          }
        }
      })
      .catch(() => {
        setLoading({ ...loading, spin: false });
      });
  };

  // eslint-disable-next-line no-unused-vars
  const unEnrollCourseCallback = (respPromise, dispatch, getState) => {
    setLoading({
      ...loading,
      spin: true,
      message: "Please wait.. Unenrollment in progress",
    });
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          loadUserEnrolledCourses();
        }
      })
      .catch(() => {
        setLoading({ ...loading, spin: false });
      });
  };

  const onOk = (course_id, isEnrolled, enroll_id) => {
    if (!isEnrolled) {
      dispatch(
        enrollIntoCourse(
          {
            user_id: user.id,
            course_id: course_id,
          },
          enrollCourseCallback
        )
      );
    } else {
      dispatch(unEnrollIntoCourse(enroll_id, unEnrollCourseCallback));
    }
  };

  const handleSearch = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: COURSES_LIST_ID,
        offset: 0,
      })
    );
    searchPropsForCoursesList.onSearch(params, _setLoadingStatus, dispatch);
    // getDefaultCourse();
    // setFilteredData(courseList);
  };
  const handleRemove = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: COURSES_LIST_ID,
        offset: 0,
      })
    );
    searchPropsForCoursesList.onClear(params, _setLoadingStatus, dispatch);
    // getDefaultCourse();
    // setFilteredData(courseList);
  };
  const close = () => {
    setIsLoading(true);
    handleRemove("", setIsLoading);
    setOpen(false);
  };
  return (
    <StyledModal
      destroyOnClose
      title={`Course Enrollment For ${user.user_name}`}
      centered
      open={open}
      closable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={close}
          disabled={loading.spin}
          data-testid="close-button"
        >
          Close
        </Button>,
      ]}
      width={"867px"}
    >
      <div
        className={`${
          dataSource.length > 0 ? "enroll-model-table" : "enroll-model-no-table"
        }`}
        style={{ paddingLeft: "24px", paddingRight: "24px" }}
      >
        <SearchBar
          filterValues={searchPropsForCoursesList.filterValues}
          onSearch={(params, _) => {
            // Handle search event
            setIsLoading(true);
            handleSearch(params, setIsLoading);
          }}
          onClear={(params, _) => {
            setIsLoading(true);
            handleRemove(params, setIsLoading);
          }}
          isModal={true}
          isModalClosing={open}
        />

        <Spin
          tip={dataSource.length > 0 ? loading.message : ""}
          spinning={loading.spin || isLoading}
          indicator={antIcon}
        >
          <>
            <Table
              columns={columns}
              dataSource={filteredData}
              pagination={false}
            />
            <div className="userEnrollment-button-section">
              <span></span>
              <div>
                <Button className="pagination-button-prev" onClick={prevPage}>
                  <FontAwesomeIcon
                    size="sm"
                    icon={faChevronLeft}
                    color={offSetValue > 0 ? "#222222" : "#ADACAA"}
                  />
                </Button>
                <Button className="pagination-button-next" onClick={nextPage}>
                  <FontAwesomeIcon
                    size="sm"
                    icon={faChevronRight}
                    color={
                      offSetValue + 10 < coursesTotalCount
                        ? "#222222"
                        : "#ADACAA"
                    }
                  />
                </Button>
              </div>
            </div>
          </>
        </Spin>
      </div>
    </StyledModal>
  );
};

export default UserEnrollmentModal;
