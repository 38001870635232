import "./LoginPage.scss";

const LogoAndInfoSection = () => {
  return (
    <div className="title-container">
      <div className="inside-elements">
        <img className="title-logo" src={require("../../assets/ct-logo.png")} />
        <img
          className="title"
          src={require("../../assets/eigenlearn-logo-light-large.png")}
        />
        {/* <div className="title">GURUKUL</div> */}
        <div className="subtitle">
          An online learning platform by Coriolis Technologies
        </div>
      </div>
    </div>
  );
};

export default LogoAndInfoSection;
