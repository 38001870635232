import {
  CANCEL_NAVIGATION,
  COMPLETE_NAVIGATION,
  START_NAVIGATION,
} from "../actiontypes";

export const startNavigation = (target) => ({
  type: START_NAVIGATION,
  payload: target,
});

export const cancelNavigation = () => ({
  type: CANCEL_NAVIGATION,
});

export const completeNavigation = () => ({
  type: COMPLETE_NAVIGATION,
});
