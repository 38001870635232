import QuestionEmptyContainer from "../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionEmptyContainer";
import { useNavigate } from "react-router-dom";

import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const QuestionBankEmptyContainer = () => {
  const navigate = useNavigate();
  const buttonProps = [
    {
      label: (
        <div className="empty-container-questionbank">
          <FontAwesomeIcon icon={faRobot} className="ask-ai-img" />
          Ask AI
        </div>
      ),
      className: "add-question-button",
      onClick: () => {
        navigate("/ask-ai/add");
      },
      style: {
        backgroundColor: "#1A2B3C",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "21px",
        marginRight: "10px",
        letterSpacing: "0em",
        textAlign: "center",
      },
    },
    {
      label: "Add Question",
      className: "add-questionbank-button",
      onClick: () => {
        navigate("/new-question");
      },
      style: {
        backgroundColor: "#FFFFFF",
        color: "#1A2B3C",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "21px",
        letterSpacing: "0em",
        textAlign: "center",
      },
    },
  ];
  return (
    <div>
      <QuestionEmptyContainer buttonProps={buttonProps} isQuestionBank={true} />
    </div>
  );
};

export default QuestionBankEmptyContainer;
