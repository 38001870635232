import { useDispatch } from "react-redux";
import "./JobCard.scss"; // Import SCSS file
import { useNavigate } from "react-router-dom";
import { SELECTED_JOB, SET_JOB_SELECTED } from "../../../../store/actiontypes";

const StatusTag = ({ status }) => {
  // Map status to corresponding display value and styles
  const statusMap = {
    FAILED: {
      display: "Failed",
      backgroundColor: "#FFEBE6",
      fontColor: "#FF202F",
      borderColor: "#FF202F",
    },
    IN_PROGRESS: {
      display: "Processing",
      backgroundColor: "#FFF3C8",
      fontColor: "#B68E00",
      borderColor: "#B68E00",
    },
    IN_REVIEW: {
      display: "In Review",
      backgroundColor: "#D7F8FF",
      fontColor: "#148BA4",
      borderColor: "#148BA4",
    },
    REVIEWED: {
      display: "Reviewed",
      backgroundColor: "#D8FFD7",
      fontColor: "#3AA738",
      borderColor: "#3AA738",
    },
  };

  const { display, backgroundColor, fontColor, borderColor } =
    statusMap[status];

  return (
    <div
      className="status-tag"
      style={{ backgroundColor, color: fontColor, borderColor }}
    >
      {display}
    </div>
  );
};

// JobCard component to display job information
const JobCard = ({ jobId, name, status, tags }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickhandler = () => {
    dispatch({
      type: SELECTED_JOB,
      payload: { id: jobId, name: name },
    });
    dispatch({
      type: SET_JOB_SELECTED,
      payload: true,
    });
    navigate(`/ask-ai/jobs/${jobId}`);
  };
  const isClickable =
    status !== "IN_PROGRESS" && status !== "FAILED" && status !== "REVIEWED";
  return (
    <div
      className={`job-card ${isClickable ? "" : "disabled"}`}
      onClick={isClickable ? onClickhandler : null}
    >
      {/* Job details container */}
      <div className="job-details">
        {/* Job name */}
        <div className="name">{name}</div>
        {/* Job tags */}
        <div className="tags">
          {tags.map((tag) => (
            <div key={tag.id} className="tag">
              {tag.tag_name}
            </div>
          ))}
        </div>
      </div>
      {/* Job status */}
      <div className="status">
        <StatusTag status={status} />
      </div>
    </div>
  );
};

export default JobCard;
