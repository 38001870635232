import { isEmpty } from "lodash";
import "./BulkInviteContent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { downloadInvitationTemplateSheet } from "../../../store/actions/adminActions";
import Spinner from "../../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { selectedCourseSelector } from "../../../store/selectors/coursesSelectors";
import { LOAD_SELECTED_COURSES } from "../../../store/actiontypes";

const BulkInviteContent = (props) => {
  const {
    handleOnDragOver, // Drag the files
    handleDrop, // Drop the file
    inputFiles, // state to store file locally
    setInputFiles,
    inputRef, // file refernce
    dispatch,
    isLoading,
    setIsLoading,
    handleRemoveFile, // function remove file locally
  } = props;
  // function to show the uploaded file size in MB or KB
  const formatFileSize = (sizeInBytes) => {
    const KB = sizeInBytes / 1024;
    if (KB < 1024) {
      return KB.toFixed(2) + " KB";
    } else {
      const MB = KB / 1024;
      return MB.toFixed(2) + " MB";
    }
  };

  const course = useSelector((state) =>
    selectedCourseSelector(state, LOAD_SELECTED_COURSES)
  );

  // function to download the template excel sheet
  const downloadSheet = () => {
    setIsLoading(true);
    if (!course) {
      dispatch(downloadInvitationTemplateSheet("", () => setIsLoading(false)));
    } else {
      dispatch(
        downloadInvitationTemplateSheet("?category=user-signup", () =>
          setIsLoading(false)
        )
      );
    }
  };

  return isLoading ? (
    <div className="SheetSpinner-Container">
      <Spinner text={"Please wait, Download in Progress..."} />
    </div>
  ) : (
    <>
      <div
        className="BulkInvite-fileUploader"
        onDragOver={handleOnDragOver}
        onDrop={handleDrop}
      >
        <div className="BulkInvite-fileUploader-content">
          <span className="BulkInvite-fileUploader-text1">
            Drag and Drop files here
          </span>
          <span className="BulkInvite-fileUploader-text2">
            Limit 5MB per file
          </span>
          <div className="BulkInvite-DownloadTextContainer">
            <span>Please download the template Excel sheet here: </span>
            <span className="BulkInvite-downloadText" onClick={downloadSheet}>
              Invitation Template.xls
            </span>
          </div>
          <input
            type="File"
            onChange={(event) => {
              setInputFiles(event.target.files);
            }}
            hidden
            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple={false}
            ref={inputRef}
          />
          <span
            className="BulkInvite-browseButton"
            onClick={() => inputRef.current.click()}
          >
            Browse
          </span>
        </div>
      </div>
      <div className="uploadedSheet-container">
        {!isEmpty(inputFiles) && (
          <>
            {Array.from(inputFiles).map((file, index) => (
              <div key={index} className="inputFilesSheet">
                <FontAwesomeIcon icon={faFilePdf} className="sheetIcon" />
                <div className="uploadedSheet-fileContents">
                  <div className="uploadedSheet-fileContent">
                    {" "}
                    <span className="sheet-name">{file.name}</span>
                    <span className="sheet-size">
                      {formatFileSize(file.size)}
                    </span>
                  </div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="sheet-CancelIcon"
                    onClick={handleRemoveFile}
                  />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default BulkInviteContent;
