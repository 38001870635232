import { useDispatch, useSelector } from "react-redux";
import "./LoginPage.scss";
import { useGoogleLogin } from "@react-oauth/google";
import {
  getClientId,
  validateTokenAction,
} from "../../store/actions/authActions";
import {
  groupArraySelector,
  isLoggedInSelector,
  loginFormSelector,
} from "../../store/selectors/authSelectors";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import LdapContainer from "./LdapContainer";
import { ReactComponent as GoogleIcon } from "../../assets/google-icon.svg";
import LogoAndInfoSection from "./LogoAndInfoSection";
import { isTenantMode } from "../../libs/utils";

const LoginContainer = () => {
  const loginSelector = useSelector(loginFormSelector);
  const isTenant = isTenantMode();

  return (
    <div className="login-container">
      <div
        className={`sign-header-${
          loginSelector?.google_creds ? "apply-margin-top" : "form"
        }`}
      >
        <img
          className="login-image"
          src={require("../../assets/learning.png")}
        />

        <div className="login-title"> Sign In</div>
        <div className="login-subtitle">
          {isTenant
            ? "Access your mail to Sign In to EigenLearn"
            : "Sign in using your Organization name to access EigenLearn"}
        </div>
      </div>
      {(loginSelector?.password_login ||
        loginSelector?.ldap_creds ||
        !isTenant) && <LdapContainer />}
      {loginSelector?.google_creds &&
        (loginSelector?.password_login || loginSelector?.ldap_creds) && (
          <div className="ok-field">OR</div>
        )}
      {loginSelector?.google_creds && <GoogleContainer />}
      {!isTenant && (
        <p>
          Don't have an account? <Link to="/signup">Sign up</Link>
        </p>
      )}
    </div>
  );
};
const GoogleContainer = () => {
  const dispatch = useDispatch();
  const loginHandle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("token", codeResponse.code);
      dispatch(validateTokenAction());
    },
    flow: "auth-code",
  });
  return (
    <div className="login-button" onClick={loginHandle}>
      <GoogleIcon title="google-icon" />
      Continue with Google
    </div>
  );
};
function LoginPage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const groupsArr = useSelector(groupArraySelector);
  const isHrAdminGroup = () => {
    return groupsArr.includes("HR") || groupsArr.includes("PORTAL_ADMIN");
  };
  const location = useLocation();

  const isTenant = isTenantMode();
  const redirectToHome = () => {
    if (isLoggedIn) {
      const redirectPath = location.state?.from?.pathname;
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      } else if (isHrAdminGroup()) {
        navigate("/courses", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  };
  //calling API for google client Id
  useEffect(() => {
    if (isTenant) {
      dispatch(getClientId());
    }
  }, [isTenant]);

  useEffect(() => {
    if (accessToken === null) {
      setIsLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    redirectToHome();
    return () => {};
  }, [isLoggedIn]);

  return (
    <div data-testid="section">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="loginPage-container">
          <LogoAndInfoSection />
          <LoginContainer />
        </div>
      )}
    </div>
  );
}

export default LoginPage;
