const adminSelector = (state) => {
  return state.admin;
};

export const AllUserSelector = (state) => {
  return adminSelector(state).users;
};

export const SelectedUserSelector = (state) => {
  return adminSelector(state).selectedUser;
};

export const getSavedSetting = (state) => {
  return adminSelector(state)?.savedSetting;
};

export const getSavedEmailSetting = (state) => {
  return getSavedSetting(state)?.emailSetting;
};

export const getAuthSetting = (state) => {
  return getSavedSetting(state)?.authenticationSetting;
};

export const getLdapAuthSetting = (state) => {
  return getAuthSetting(state)?.ldapAuthSetting;
};

export const llmsSelector = (state) => {
  return getSavedSetting(state)?.aiSettings?.llms;
};

export const llmDetailsSelector = (state) => {
  return getSavedSetting(state)?.aiSettings?.llmDetails;
};
export const getGeneralSettings = (state) => {
  return adminSelector(state)?.savedSetting;
};
export const getGoogleAuthSetting = (state) => {
  return getAuthSetting(state)?.googleAuthSetting;
};

export const getPasswordBasedAuthSetting = (state) => {
  return getAuthSetting(state)?.passwordBasedAuthSetting;
};
export const isSaveButtonDisabledSelector = (state) => {
  return adminSelector(state).isSaveButtonDisabled;
};
