import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./CourseUnenrolledPage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedCourse,
  loadSelectedCourse,
} from "../../../store/actions/courseAction";
import { selectedCourseSelector } from "../../../store/selectors/coursesSelectors";
import Spinner from "../../../components/Spinner/Spinner";
import ContactAdminModal from "../../../features/CourseDetailsFeature/CourseUnenrolled/ContactAdminModal";
import { isPreviewConstant } from "../../../store/actions/wizardActions";
import { groupArraySelector } from "../../../store/selectors/authSelectors";
import constants from "../../../constants";
import CandidateScreen from "../../../features/CourseDetailsFeature/CourseUnenrolled/CandidateScreen/CandidateScreen";
import UnEnrolledTabScreen from "../../../features/CourseDetailsFeature/CourseUnenrolled/TabScreen/UnEnrolledTabScreen";

const CourseDetailsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const course = useSelector(selectedCourseSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const groupsArr = useSelector(groupArraySelector);

  const loadSelectedCourseDetails = (id) => {
    dispatch(loadSelectedCourse(id));
  };
  useEffect(() => {
    loadSelectedCourseDetails(id);
    return () => {
      dispatch(clearSelectedCourse());
      dispatch(isPreviewConstant(false));
    };
  }, []);
  const settingCourseList = () => {
    let moduleslist = [];
    course.forEach((element) => {
      moduleslist.push(element.module);
    });
    let obj = [
      {
        course: course[0].course,
        modules: moduleslist,
      },
    ];
    setCourseList(obj);
  };
  useEffect(() => {
    if (course !== null) {
      settingCourseList();
      setIsLoading(false);
    }
    return () => {};
  }, [course, groupsArr, id]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          className="courseUnenrolledPage-container"
          data-testid="courseUnenrolledPage-container"
        >
          <ContactAdminModal open={isModalOpen} setOpen={setIsModalOpen} />
          {groupsArr.includes(constants.CANDIDATE) && groupsArr.length == 1 ? (
            <CandidateScreen
              courseList={courseList}
              setIsModalOpen={setIsModalOpen}
            />
          ) : (
            <UnEnrolledTabScreen
              courseList={courseList}
              // setIsModalOpen={setIsModalOpen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CourseDetailsPage;
