import PropTypes from "prop-types";
import { Breadcrumb } from "antd";
import "./BreadCrumb.scss";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectedCourseSelector } from "../../store/selectors/coursesSelectors";
import { SelectedUserSelector } from "../../store/selectors/adminSelectors";

/**
 * Breadcrumbs component.
 *
 * The Breadcrumbs component displays a breadcrumb trail for navigation. It allows users to see their current location within a hierarchical structure and navigate to previous pages.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {number[]} props.omit - An array of positions to omit from the breadcrumb trail. Each number represents the position of a breadcrumb item, starting from 0.
 * @returns {JSX.Element} Breadcrumbs component JSX.
 *
 * @example
 *
 * // Example usage of the Breadcrumbs component
 * const omittedPositions = [1, 3]; // Omit the first and third breadcrumb items
 *
 * function App() {
 *   return (
 *     <div>
 *       <Breadcrumbs omit={omittedPositions} />
 *     </div>
 *   );
 * }
 *
 */

const Breadcrumbs = ({ omit = [] }) => {
  // Get the current location from React Router
  const location = useLocation();

  // Get the selected course from Redux
  const course = useSelector(selectedCourseSelector);

  //Get the selected user from Redux
  const user = useSelector(SelectedUserSelector);

  // Split the pathname into snippets and filter out empty values
  let pathSnippets = location.pathname.split("/").filter((i) => i);

  //Excluding tab names in breadcrumbs.
  const lastPath = pathSnippets[pathSnippets.length - 1];
  const excludedPaths = ["candidate", "statistics", "content", "course"];
  if (excludedPaths.includes(lastPath)) {
    pathSnippets.pop();
  }

  // Create an array of breadcrumb items based on pathSnippets
  const breadcrumbItems = pathSnippets?.map((snippet, index) => {
    let url = `/${pathSnippets?.slice(0, index + 1).join("/")}`;
    // Check if the current position (index + 1) is in the omit array
    if (!omit.includes(index + 1)) {
      if (course && snippet === course[0]?.course?.id) {
        snippet = course[0]?.course?.course_name;
      }
      if (user && snippet === user?.id) {
        snippet = user?.user_name;
      }

      if (url === "/admin-settings") {
        snippet = "Admin settings";
        url = "/admin-settings/general";
      }
      return (
        <Breadcrumb.Item
          key={url}
          className={
            index ===
            pathSnippets?.length - 1 - omit.length + (!omit.length ? 0 : 1)
              ? "active"
              : ""
          }
        >
          <Link to={url}>{snippet}</Link>
        </Breadcrumb.Item>
      );
    }

    return null; // Omit this breadcrumb
  });

  // Conditionally display the "Home" breadcrumb based on omit
  const showHome = !omit.includes(0);

  return (
    <Breadcrumb separator={<Arrow />}>
      {showHome && (
        <Breadcrumb.Item className="">
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
      )}
      {breadcrumbItems}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  omit: PropTypes.arrayOf(PropTypes.number), // Optional omit array
};

Breadcrumbs.defaultProps = {
  omit: [], // Default value for omit prop is an empty array
};

export default Breadcrumbs;
