import "./EmailSettingPageContainer.scss";
import { EmailSettingPageUpperDescMessage } from "../../../constants";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { getEmailSetting } from "../../../store/actions/adminActions";
import Breadcrumbs from "../../../components/BreadCrumb/BreadCrumbs";
import Spinner from "../../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { getSavedEmailSetting } from "../../../store/selectors/adminSelectors";
import EmailSettingHeaderSection from "./EmailSettingHeaderSection";
import EmailSettingFormSection from "./EmailSettingFormSection";

const EmailSettingPageContainer = () => {
  const dispatch = useDispatch();
  const savedEmailSetting = useSelector(getSavedEmailSetting);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(getEmailSetting(setIsLoading));
  }, []);
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="email-setting-container">
      <Breadcrumbs omit={[0]} />
      <EmailSettingHeaderSection
        title={"Email Settings"}
        desc={EmailSettingPageUpperDescMessage}
      />
      <EmailSettingFormSection currentEmailSetting={savedEmailSetting} />
    </div>
  );
};

export default EmailSettingPageContainer;
