const jobActionsSelector = (state) => {
  return state?.jobActions;
};

export const jobActionsList = (state) => {
  return jobActionsSelector(state)?.jobActionsList;
};

export const isJobsSelected = (state) => {
  return jobActionsSelector(state)?.isJobsSelected;
};
