import {
  CANCEL_NAVIGATION,
  COMPLETE_NAVIGATION,
  START_NAVIGATION,
} from "../actiontypes";

const initialState = {
  isNavigating: false,
  navigationTarget: null,
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_NAVIGATION:
      return {
        ...state,
        isNavigating: true,
        navigationTarget: action.payload,
      };
    case CANCEL_NAVIGATION:
      return {
        ...state,
        isNavigating: false,
        navigationTarget: null,
      };
    case COMPLETE_NAVIGATION:
      return {
        ...state,
        isNavigating: false,
        navigationTarget: null,
      };
    default:
      return state;
  }
};

export default navigationReducer;
