var constants = {
  LOGIN_PAGE_URL: "login",
  SIGNUP_PAGE_URL_WITH_OTP: "signup/set_password",
  FORGOT_PASSWORD: "forgot_password",
  SET_PASSWORD: "users/:id/set-password",
  RESET_PASSWORD: "users/:id/reset-password",
  SIGN_UP_PAGE_URL: "signup",
  USERS_PAGE_URL: "users",
  PIPELINE_STATUS: {
    passed: "success",
    failed: "failed",
    running: "running",
    approved: "approved",
    notaprroved: "notapproved",
    waitingforapproval: "waitingforapproval",
    COMPLETED: "COMPLETED",
    LOCKED: "LOCKED",
    IN_PROGRESS: "IN_PROGRESS",
    VIEW: "VIEW",
  },
  SUCCESS_TOAST: "success",
  ERROR_TOAST: "error",
  INFO_TOAST: "info",
  WARNING_TOAST: "warning",
  UN_ENROLL: "Unenroll",
  ENROLL: "Enroll",
  ASSIGNMENT_TOOLTIP:
    "Assignment link will be available after the previous module is completed.",
  EMPTY_DATA: "No data to show",
  PORTAL_ADMIN: "PORTAL_ADMIN",
  CREATORS: "CREATORS",
  CANDIDATE: "CANDIDATE",
  HR: "HR",
  ASK_AI_BANNER_MESSAGE:
    "For security reasons, please refrain from uploading documents containing sensitive information.",
};
export const GEN_AI_QUESTION_LIST_ID = "GEN_AI_QUESTION_LIST_ID";
export const REJECTED = "REJECTED";
export const REVIEWED = "REVIEWED";
export const USER_LIST_ID = "USER_LIST_ID";
export const COURSES_LIST_ID = "COURSES_LIST_ID";
export const CANDIDATE_LIST_ID = "CANDIDATE_LIST_ID";
export const AUTHORED_LIST_ID = "AUTHORED_LIST_ID";
export const QUESTION_LIST_ID = "QUESTION_LIST_ID";
export const ENROLL_LIST_ID = "ENROLL_LIST_ID";
export const PUBLISHED_COURSES_ID = "PUBLISHED_COURSES_ID";
export const GRAPH_SUMMARY_MODAL_ID = "GRAPH_SUMMARY_MODAL_ID";
export const PORTAL_ADMIN_USERS_MENU = [
  {
    label: "Enroll",
    key: "Enroll",
  },
  {
    label: "Edit",
    key: "Edit",
  },
  {
    label: "Archive",
    key: "Archive",
  },
];
export const ASK_AI_LIST_ID = "ASK_AI_LIST_ID";

export const PORTAL_ADMIN_ROLES_MENU = [
  {
    label: "PORTAL_ADMIN",
    value: 1,
  },
  {
    label: "CANDIDATE",
    value: 2,
  },
  {
    label: "CREATOR",
    value: 3,
  },
  {
    label: "HR",
    value: 4,
  },
];
export const ASK_AI_TEST_OPTIONS = [
  {
    label: "Multiple Choice Questions",
    value: 1,
  },
  {
    label: "Subjective Questions",
    value: 2,
  },
  {
    label: "Both",
    value: 3,
  },
];
export const USER_STATUS = [
  {
    label: "active",
    key: false,
  },
  {
    label: "inactive",
    key: true,
  },
];
export const COMPLETION_STATUS = [
  {
    label: "false",
    key: false,
  },
  {
    label: "true",
    key: true,
  },
];

export const CANDIDATES_TAB_FILTERS = [
  { filterName: "Last Month", value: "LAST_MONTH" },
  { filterName: "Current Year", value: "CURRENT_YEAR" },
  { filterName: "Last Year", value: "LAST_YEAR" },
];

export const selfUser =
  'Please confirm whether you would like to assign the role of "{groups}" to yourself. After this you will be logged out...';

export const otherUsers =
  'Please confirm whether you would like to assign the role of "{groups}" to "{name}".';
export const passwordRules =
  "Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number.";

export const GEN_AI_ALL_QUESTION_LIST_ID = "GEN_AI_ALL_QUESTION_LIST_ID";
export const SubmittedPdfMessage = (text) => {
  if (text === "Both") {
    return `Hang tight, the AI is working on it. We'll let you know once your
    multiple choice and subjective questions are prepared.`;
  }
  return `Hang tight, the AI is working on it. We'll let you know once your
    ${text} are prepared.`;
};

export default constants;

const publicEmailDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "yandex.com",
  "zoho.com",
  "protonmail.com",
  "gmx.com",
  "inbox.com",
  "fastmail.com",
  "live.com",
  "msn.com",
];

const tempEmailDomains = [
  "10minutemail.com",
  "temp-mail.org",
  "guerrillamail.com",
  "mailinator.com",
  "trashmail.com",
  "yopmail.com",
  "getnada.com",
  "fakemailgenerator.com",
  "tempmailaddress.com",
  "throwawaymail.com",
  "maildrop.cc",
  "mintemail.com",
  "mytemp.email",
  "spoofmail.de",
  "mailcatch.com",
  "temporarymail.com",
  "emailsensei.com",
  "anonymousemail.me",
  "temp-mail.io",
  "10minemail.com",
  "mfunza.com",
  "qodiq.com",
  "maxturns.com",
  "orsbap.com",
  "@hellomailo.net",
  "dockstones.com",
];

export const invalidEmailDomains = [...publicEmailDomains, ...tempEmailDomains];
export const EmailSettingPageUpperDescMessage = `Send useful emails to you and your users from your own instance without
        talking to the internet.`;
export const EmailSettingPageLowerDescMessage =
  "We recommend setting up a username password for your SMTP server";
export const GoogleSettingPageHeaderDesc =
  "Allow members to login or signup using google accounts.";
export const GOOGLE_AUTH_INFO_MESSAGE = {
  CLIENT_ID_MESSAGE: "Your client ID lives in your Google API Console. ",
  CLIENT_SECRET_MESSAGE:
    "Your client secret should also be in your Google API Console. ",
  ORIGIN_URI_MESSAGE:
    "We will auto-generate this. Paste this into your Authorized JavaScript origins field. For this OAuth client",
  CALLBACK_URI_MESSAGE:
    "We will auto-generate this. Paste this into your Authorized Redirect URI field. For this OAuth client",
};

export const GOOGLE_AUTH_INFO_HYPERLINKS = {
  CLIENT_ID_LINK:
    "https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#creatingcred",
  CLIENT_SECRET_LINK:
    "https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#creatingcred",
  ORIGIN_URI_LINK:
    "https://console.cloud.google.com/apis/credentials/oauthclient",
  CALLBACK_URI_LINK:
    "https://console.cloud.google.com/projectselector2/apis/credentials/oauthclient?supportedpurview=project",
};

export const LDAP_AUTH_DESC_MESSAGE = {
  LDAP_PORT: "Used for communication with an LDAP server. ",
  LDAP_SERVER_URI:
    "Used to encapsulate the address and port of a directory server. ",
  LDAP_BASE_DN:
    "Identifies the entry in the directory from which searches initiated by LDAP clients occur. ",
  LDAP_BIND_DN: "Used to authenticate clients to the directory server. ",
};

export const LDAP_AUTH_DESC_LINKS = {
  LDAP_PORT_LINK:
    "https://www.ituonline.com/blogs/ldap-ports/#:~:text=Definition-,LDAP,can%20be%20secured%20using%20StartTLS.",
  LDAP_SERVER_URI_LINK:
    "https://ldap.com/ldap-urls/#:~:text=An%20LDAP%20URL%20is%20a,a%20search%20within%20that%20server.",
  LDAP_BASE_DN_LINK:
    "https://docs.trendmicro.com/all/ent/iwsva/v5.5/en-us/iwsva_5.5_olh/dn.htm#:~:text=The%20base%20distinguished%20name%2C%20or,initiated%20by%20LDAP%20clients%20occur.",
  LDAP_BIND_DN_LINK: "https://ldap.com/the-ldap-bind-operation/",
};
