/* eslint-disable no-unused-vars */
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import { appRoutes } from "./libs/routes";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  groupSelector,
  isLoggedInSelector,
  roleSelector,
} from "./store/selectors/authSelectors";
import { useEffect } from "react";
import { loadUserDetails } from "./store/actions/authActions";
import GlobalEmptyContainer from "./components/GlobalEmptyContainer/GlobalEmptyContainer";
import Spinner from "./components/Spinner/Spinner";

function App() {
  const groups = useSelector(groupSelector);
  const dispatch = useDispatch();
  const loadDetails = () => {
    dispatch(loadUserDetails());
  };
  const isLoggedIn = useSelector(isLoggedInSelector);
  const accessToken = localStorage.getItem("token");
  useEffect(() => {
    if (accessToken !== null) {
      loadDetails();
    }
  }, []);

  return groups === undefined && accessToken !== null && isLoggedIn === null ? (
    <>
      <GlobalEmptyContainer
        content={
          <div className="global-loading-container">
            <Spinner />
          </div>
        }
      />
    </>
  ) : (
    <>
      <RouterProvider router={appRoutes(groups)} />
      <Toaster
        toastOptions={{
          // Define default options
          duration: 3000,
          position: "top-right",
          className: "",
          style: {
            maxWidth: 500,
            wordBreak: "break-all",
            padding: "0px",
          },
        }}
      />
    </>
  );
}

export default App;
