import "./AboutModule.scss";
// import { ReactComponent as Content } from "../../assets/alt.svg";
// import { ReactComponent as Assignment } from "../../assets/assignment.svg";
import {
  convertDurationToMMSSMS,
  formatRelativeTime,
  generateArgumanets,
  pipelineStatusIcon,
} from "../../libs/utils";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Exclamation } from "../../assets/exclamation-circle.svg";
import { ReactComponent as LockStatus } from "../../assets/lockStatus.svg";
import Spinner from "../Spinner/Spinner";
import { Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { isPreviewSelector } from "../../store/selectors/wizardSelectors";
import constants from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCodeBranch,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import DocumentationStep from "./DocumentationStep";
import { updateCourseMarkAsCompleted } from "../../store/actions/courseAction";
import AccordianContent from "./AccordianContent";
import { useState } from "react";

function AboutModule(props) {
  const isPreview = useSelector(isPreviewSelector);
  const dispatch = useDispatch();
  const [accordianOpenContent, setAccordianOpenContent] = useState(false);
  const [accordianOpenTest, setAccordianOpenTest] = useState(false);
  const [expandBtnClicked, setExpandBtnClicked] = useState(false);
  const { modules, isEnrolled, lab_required, enroll_id } = props;
  const [buttonLoader, setButtonLoader] = useState(false);
  let repoUrl = "",
    webUrls = "",
    currentBranch = "";
  if (modules && modules?.gitlab_details) {
    webUrls = modules?.gitlab_details[0]?.pipelines[0]?.web_url;
    repoUrl = webUrls?.replace(/\/-\/pipelines\/\d+$/, ".git");
    currentBranch = modules?.gitlab_details[0]?.pipelines[0]?.ref;
  }
  const ChangeStateAsCompleted = (course_id) => {
    setButtonLoader(true);
    dispatch(
      updateCourseMarkAsCompleted(course_id, enroll_id, setButtonLoader)
    );
  };
  const onAccordianOpenContent = () => {
    setAccordianOpenContent(!accordianOpenContent);
    setExpandBtnClicked(false);
    setAccordianOpenTest(false);
  };
  const onAccordianOpenTest = () => {
    setAccordianOpenTest(!accordianOpenTest);
    setExpandBtnClicked(false);
    setAccordianOpenContent(false);
  };
  const submitButtonAction = () => {
    setAccordianOpenTest(false);
    setAccordianOpenContent(false);
    setExpandBtnClicked(!expandBtnClicked);
  };

  return (
    <>
      {modules?.length !== 0 ? (
        <>
          {isPreview ? null : (
            <>
              {modules?.gitlab_details && (
                <>
                  {" "}
                  {modules?.gitlab_details[0]?.threads_resolved ? null : (
                    <div
                      className="threads-resolve"
                      data-testid="thread-resolved"
                    >
                      <div className="threads-header">
                        <Exclamation />
                        <span className="content">
                          You have unresolved threads on your Merge request.
                          Please resolve those to complete this module.{" "}
                        </span>
                      </div>
                      <a
                        href={modules?.gitlab_details[0].mr_details}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="mr_details_resolve_now"
                      >
                        <Button className="resolve-button">Resolve Now</Button>
                      </a>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="about-module-header" data-testid="about-module">
            <div
              className="about-module-module-name"
              data-testid="about-module-name"
            >
              {generateArgumanets(isPreview, isEnrolled, modules)?.module_name}
            </div>
          </div>
          <div
            className="about-module-subtitle"
            data-testid="about-module-subtitle"
          >
            {
              generateArgumanets(isPreview, isEnrolled, modules)
                ?.module_description
            }
          </div>
          {/* <div className="module-study-material-header">Study Material</div> */}
          <div className="module-study-material">
            <div
              className="module-content"
              onClick={() => onAccordianOpenContent()}
            >
              <div className="module-content-header">
                {/* <Content title="moduleContent" /> */}
                <p>Study Material</p>
              </div>
              <div className="module-content-link">
                <Button
                  className={
                    !accordianOpenContent
                      ? "module-content-expand-button"
                      : "module-content-expand-button-collapsed"
                  }
                >
                  {accordianOpenContent ? "Collapse" : "Expand"}
                </Button>
              </div>
            </div>
            <div>
              {accordianOpenContent && (
                <AccordianContent
                  url={
                    generateArgumanets(isPreview, isEnrolled, modules)
                      ?.module_content
                  }
                />
              )}
            </div>
            {lab_required === "YES" && (
              <div
                className="module-content"
                onClick={() => onAccordianOpenTest()}
              >
                <div className="module-content-header">
                  {/* <Assignment title="moduleAssignment" /> */}
                  <p>Assignment</p>
                </div>
                <div className="module-content-link">
                  {isPreview ||
                  (isEnrolled &&
                    modules?.module_progress !==
                      constants.PIPELINE_STATUS.VIEW) ? (
                    <Button
                      className={
                        !accordianOpenTest
                          ? "module-content-expand-button"
                          : "module-content-expand-button-collapsed"
                      }
                    >
                      {accordianOpenTest ? "Collapse" : "Expand"}
                    </Button>
                  ) : (
                    <Tooltip
                      title={constants.ASSIGNMENT_TOOLTIP}
                      placement="right"
                    >
                      <LockStatus style={{ height: "15px" }} />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
            <div>
              {accordianOpenTest && (
                <AccordianContent
                  url={
                    generateArgumanets(isPreview, isEnrolled, modules)
                      ?.module_assignment
                  }
                />
              )}
            </div>
            <div className="documentation-container">
              {lab_required === "YES" && (
                <DocumentationStep
                  repoUrl={repoUrl}
                  currentBranch={currentBranch}
                  lab_required={lab_required}
                  expandBtnClicked={expandBtnClicked}
                  submitButtonAction={submitButtonAction}
                />
              )}
            </div>
          </div>
          {modules?.gitlab_details || (isPreview && lab_required === "YES") ? (
            <>
              <div className="pipeline-status-header">Pipeline Status</div>
              {isPreview ||
              (isEnrolled &&
                modules?.module_progress !== constants.PIPELINE_STATUS.VIEW) ? (
                <div
                  className="course-status-container"
                  data-testid="pipeline-status"
                >
                  <div className="pipeline-status-container">
                    <div className="latest-pipeline-header">
                      Latest Pipelines
                    </div>
                    <div className="latest-pipeline-status">
                      {isPreview ? (
                        <>
                          {" "}
                          <div className="latest-pipeline-item">
                            <div className="pipeline-item-initial">
                              <div className="pipeline-status pipeline-item">
                                {pipelineStatusIcon("success", true)}
                              </div>
                              <div className="pipeline-item">
                                <a
                                  className="pipeline-item-link"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {"#123456"}
                                </a>
                              </div>
                              <div className="pipeline-item pipeline-item-max">
                                <FontAwesomeIcon
                                  icon={faCodeBranch}
                                  color="#696969"
                                />
                                <div
                                  className="item-ellipsis"
                                  data-testid="pipelineStatusName"
                                >
                                  {"common_module_assignment_cte_123"}
                                </div>
                              </div>
                            </div>
                            <div className="pipeline-item-final">
                              <div className="pipeline-item pipeline-item-small">
                                <FontAwesomeIcon
                                  icon={faStopwatch}
                                  color="#696969"
                                />
                                <div className="item-text">
                                  {convertDurationToMMSSMS(3412)}
                                </div>
                              </div>
                              <div className="pipeline-item pipeline-item-small">
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  color="#696969"
                                />
                                <div className="item-text">
                                  {formatRelativeTime(
                                    "2023-06-16T16:30:42.309Z"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {modules?.gitlab_details[0].pipelines.map((value) => {
                            const withText = true;
                            return (
                              <>
                                <div className="latest-pipeline-item">
                                  <div className="pipeline-item-initial">
                                    <div className="pipeline-status pipeline-item">
                                      {pipelineStatusIcon(
                                        value.status,
                                        withText
                                      )}
                                    </div>
                                    <div className="pipeline-item">
                                      <a
                                        className="pipeline-item-link"
                                        href={value.web_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-testid="pipeline-status-link"
                                      >
                                        {"#" + value.id}
                                      </a>
                                    </div>
                                    <div className="pipeline-item pipeline-item-max">
                                      <FontAwesomeIcon
                                        icon={faCodeBranch}
                                        color="#696969"
                                      />
                                      <div
                                        className="item-ellipsis"
                                        data-testid="pipeline-name"
                                      >
                                        <Tooltip
                                          overlayClassName="custom-tooltip"
                                          title={value.ref}
                                          placement="topLeft"
                                        >
                                          {value.ref}
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pipeline-item-final">
                                    <div className="pipeline-item pipeline-item-small">
                                      <FontAwesomeIcon
                                        icon={faStopwatch}
                                        color="#696969"
                                      />
                                      <div className="item-text">
                                        {value?.duration === null
                                          ? "-- -- --"
                                          : convertDurationToMMSSMS(
                                              value.duration
                                            )}
                                      </div>
                                    </div>
                                    <div className="pipeline-item pipeline-item-small">
                                      <FontAwesomeIcon
                                        icon={faCalendar}
                                        color="#696969"
                                      />
                                      <div className="item-text">
                                        {formatRelativeTime(value?.updated_at)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="submission-status">
                    <div className="submission-status-header">
                      Submission Summary
                    </div>
                    <div className="submission-stats">
                      <div className="submission-stats-item">
                        <div className="submission-stats-header">Total :</div>
                        <div className="submission-stats-number">
                          {isPreview
                            ? 1
                            : modules?.gitlab_details[0].pipeline_summary.total}
                        </div>
                      </div>
                      <div className="submission-stats-item">
                        <div className="submission-stats-header">Passed :</div>
                        <div className="submission-stats-number">
                          {" "}
                          {isPreview
                            ? 1
                            : modules?.gitlab_details[0].pipeline_summary
                                .success}
                        </div>
                      </div>
                      <div className="submission-stats-item">
                        <div className="submission-stats-header">Failed :</div>
                        <div className="submission-stats-number">
                          {isPreview
                            ? 1
                            : modules?.gitlab_details[0].pipeline_summary
                                .failed}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="submission-status">
                  <div
                    className="submission-status-header"
                    style={{
                      paddingLeft: "36px",
                    }}
                  >
                    No Data To Display
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              {lab_required === "NO" &&
              modules?.module_progress !== "COMPLETED" ? (
                <Button
                  className="mark-as-complete-button"
                  onClick={() => {
                    if (!isPreview) {
                      ChangeStateAsCompleted(modules?.module?.id);
                    }
                  }}
                  loading={buttonLoader}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mark-as-complete-button-icon"
                  />
                  Mark as Complete
                </Button>
              ) : (
                <>
                  {lab_required === "NO" && (
                    <span className="mark-as-completed-text">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="mark-as-completed-icon"
                      />
                      Module has been Completed Successfully
                    </span>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default AboutModule;
