import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SummaryStep.scss";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  isCheckedSelector,
  wizardStepsSelector,
} from "../../../../store/selectors/wizardSelectors";
import PreviewModeRepository from "../../../../components/PreviewModeRespository/PreviewModeRepository";
import { Checkbox } from "antd";
import {
  changeCurrentStep,
  isCheckedConstant,
} from "../../../../store/actions/wizardActions";
import { wizardLoaderSelector } from "../../../../store/selectors/wizardSelectors";
import Spinner from "../../../../components/Spinner/Spinner";
import { isEmpty } from "lodash";
function SummaryStep() {
  const step = useSelector(wizardStepsSelector);
  const isLoading = useSelector(wizardLoaderSelector);
  const dispatch = useDispatch();
  const editCurrentStep = (step) => {
    dispatch(changeCurrentStep(step));
  };
  const onChange = (e) => {
    dispatch(isCheckedConstant(e.target.checked));
  };
  const isChecked = useSelector(isCheckedSelector);
  const lab_required = step?.stepOne?.lab_required;

  return isLoading ? (
    <div className="summaryCreationStep-loader">
      <Spinner color={"white"} text={"Creating Course"} />{" "}
    </div>
  ) : (
    <div className="summary_step">
      <h3> Course Summary</h3>
      <p>
        Hurray! You are one step away from publishing your course. Here is your
        summary.
      </p>
      <br></br>
      <div className="header">
        <h4>Basic Details</h4>
        <div
          className="header-right"
          onClick={() => editCurrentStep(0)}
          data-testId="stepOneEditButton"
        >
          <FontAwesomeIcon icon={faPencil} style={{ color: "#108cff" }} />
          &nbsp; Edit
        </div>
      </div>
      <hr></hr>
      <div className="content-container">
        {" "}
        <div className="content">
          <div className="content-title">Course Title</div>
          <div className="content-title">Course Category</div>
          <div className="content-title">Course Description</div>
        </div>
        <div className="content-description">
          <div className="content-description">
            {step?.stepOne?.course_name}
          </div>
          <div className="content-description">{step?.stepOne?.category}</div>
          <div className="content-description">
            {step?.stepOne?.course_description}
          </div>
        </div>
      </div>
      <br></br>
      <div className="header">
        <div className="header_left">
          <h4>Modules</h4>&nbsp;
          <p>({step?.stepTwo?.length} Modules)</p>
        </div>

        <div
          className="header-right"
          onClick={() => editCurrentStep(1)}
          data-testId="stepTwoEditButton"
        >
          <FontAwesomeIcon icon={faPencil} style={{ color: "#108cff" }} />
          &nbsp; Edit
        </div>
      </div>
      <hr />
      <div className="module-container">
        {step?.stepTwo?.map((module) => (
          <div className="module-card" key={module.id}>
            <div className="module-title">{module.module_name}</div>
            <div className="module-description">
              {module.module_description}
            </div>
          </div>
        ))}
      </div>
      <br></br>
      {step?.stepOne?.lab_required === "YES" && (
        <>
          <PreviewModeRepository
            lab_required={lab_required}
            isStep3={true}
            steps={step}
            isStep4={false}
          />
          <br></br>
        </>
      )}
      {!isEmpty(step?.stepFour) && (
        <>
          <PreviewModeRepository
            lab_required={lab_required}
            isStep3={false}
            steps={step}
            isStep4={true}
          />
          <br />
        </>
      )}
      <div>
        <Checkbox
          data-testId="checkbox"
          onChange={onChange}
          checked={isChecked}
        />{" "}
        &nbsp; Accept terms and conditions
      </div>
      <br></br>
    </div>
  );
}

export default SummaryStep;
