import { useGoogleLogin } from "@react-oauth/google";
import { ReactComponent as GoogleIcon } from "../../assets/google-icon.svg";
import "./GoogleLoginButton.scss";
const GoogleLoginButton = ({
  action,
  label = "Continue with Google",
  showOrSection = true,
}) => {
  const loginHandle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem("token", codeResponse.code);
      action();
    },
    flow: "auth-code",
  });

  return (
    <>
      {showOrSection && <div className="or-section">OR</div>}
      <div className="google-button" onClick={loginHandle}>
        <GoogleIcon title="google-icon" />
        {label}
      </div>
    </>
  );
};

export default GoogleLoginButton;
