import { Checkbox, Radio, Space } from "antd";
import { useState } from "react";
import "./McqQuestionSection.scss";
import { Field, Form, Formik } from "formik";
const McqQuestionSection = (props) => {
  const {
    currentQuestionIndex,
    questionData,
    question_type,
    options,
    question,
  } = props;

  const [value, setValue] = useState(0);

  const handleOnChange = (e) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
  };

  return (
    <>
      <div className="previewMode-question-section-header">
        <div>
          Question {currentQuestionIndex + 1} / {questionData?.length}
        </div>
        {question}
      </div>
      <div className="previewMode-question-option-section">
        {question_type === "Single" ? (
          <Radio.Group onChange={handleOnChange} value={value}>
            <Space direction="vertical">
              {options.map((option) => (
                <Radio value={option?.option_id} key={option?.option_id}>
                  {option.option_text}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        ) : question_type === "Multiple" ? (
          <Space direction="vertical">
            {options.map((option) => (
              <Checkbox key={option?.option_id}>{option.option_text}</Checkbox>
            ))}
          </Space>
        ) : (
          <Formik initialValues={{ subjectiveAnswer: "" }}>
            {({ setFieldValue }) => (
              <Form className="form-subjective" data-testid="form-div">
                <Field
                  as="textarea"
                  type="textarea"
                  name={"subjectiveAnswer"}
                  className="subjective-answer-text-area"
                  showCount={true}
                  maxLength={5000}
                  onChange={(event) => {
                    setFieldValue("subjectiveAnswer", event.target.value);
                  }}
                  rows={15}
                  autoSize={{
                    minRows: 15,
                    maxRows: 15,
                  }}
                  placeholder="Enter your answer here."
                />
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default McqQuestionSection;
