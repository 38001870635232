const genAISelector = (state) => {
  return state?.genAi;
};

export const genAiSelectedJob = (state) => {
  return genAISelector(state)?.selectedJob;
};

export const genAiQuestionList = (state) => {
  return genAISelector(state)?.question_list;
};

export const getEditQuestion = (state) => {
  return genAISelector(state)?.editselctedQuestion;
};

export const genAiAllQuestionBankList = (state) => {
  return state.genAi?.all_question_bank_list || [];
};
