import "./PreviewModeRepository.scss";
import { ReactComponent as Links } from "../../assets/link.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  isPreviewSelector,
  wizardStepsSelector,
} from "../../store/selectors/wizardSelectors";
import { useEffect } from "react";
import {
  repositoryDetailsSelector,
  // repositoryErrorSelector,
} from "../../store/selectors/coursesSelectors";
import { getRepositoryDetails } from "../../store/actions/courseAction";

function PreviewModeRepository(props) {
  const { courseId, lab_required } = props;

  const isPreview = useSelector(isPreviewSelector);
  const { isStep3, isStep4 } = props;
  const steps = useSelector(wizardStepsSelector);
  const dispatch = useDispatch();
  const repositoryDetails = useSelector(repositoryDetailsSelector);
  // const repositoryError = useSelector(repositoryErrorSelector);

  useEffect(() => {
    if (isPreview) {
      dispatch(getRepositoryDetails(courseId));
    }
  }, [dispatch, isPreview, courseId]);

  // Ensure repositoryDetails and repositoryDetails.data exist
  const boilerplateLink =
    repositoryDetails?.data?.boilerplate || steps?.stepThree?.boilerplate || "";
  const testRepoLink =
    repositoryDetails?.data?.test_repo || steps?.stepThree?.test_repo || "";
  const solutionsLink =
    repositoryDetails?.data?.solutions || steps?.stepThree?.solutions || "";
  if (lab_required === "NO" && !steps) {
    return null;
  }
  return (
    <div>
      <div className="preview-repo-header">
        {(lab_required === "YES" || isStep3) && (
          <>
            {isStep3 ? (
              <h4>Repositories</h4>
            ) : isStep4 ? (
              <h4>MCQ</h4>
            ) : (
              <h3>Repositories</h3>
            )}

            {!isStep3 && !isStep4 && (
              <p className="preview-header-subtitle">
                (Visible in preview mode only)
              </p>
            )}
          </>
        )}
      </div>
      {isStep3 || isStep4 ? (
        <>
          <hr />
          <br />
        </>
      ) : null}
      {(lab_required === "YES" || isStep3) && (
        <div className="details">
          <div className="repo-details">
            <div>Boilerplate code</div>
            <a href={boilerplateLink} target="_blank" rel="noopener noreferrer">
              <Links />
            </a>
          </div>
          <div className="repo-details">
            <div>Test Repository</div>
            <a href={testRepoLink} target="_blank" rel="noopener noreferrer">
              <Links />
            </a>
          </div>
          <div className="repo-details">
            <div>Solutions</div>
            <a href={solutionsLink} target="_blank" rel="noopener noreferrer">
              <Links />
            </a>
          </div>
        </div>
      )}
      {isStep4 && steps?.stepFour && (
        <div className="details">
          {steps.stepFour.map((elem) => (
            <div className="repo-details" key={elem.id}>
              <div>{elem.test_name}</div>
              <a
                className="repo-details-link-icon"
                href={`#/courses/${steps.stepOne.id}/test/${elem.id}/preview`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Links />
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PreviewModeRepository;
