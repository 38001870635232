/* eslint-disable no-unused-vars */
import "./DocumentationStep.scss";
import { Button } from "antd";
import MarkDowns from "./markDown";

const DocumentationStep = (props) => {
  const {
    repoUrl,
    currentBranch,
    lab_required,
    expandBtnClicked,
    submitButtonAction,
  } = props;

  const gitAddCommand = "git add .";
  const gitCommitCommand = 'git commit -m "your_commit_message_here"';

  return (
    <>
      <div className="documentation-heading">
        <span className="documentation-title">How to Submit</span>

        <Button
          className={
            !expandBtnClicked
              ? "documentation-expand-button"
              : "documentation-expand-button-collapsed"
          }
          data-testid="documentation-expand-button"
          onClick={() => submitButtonAction()}
        >
          {expandBtnClicked ? "Collapse" : "Expand"}
        </Button>
      </div>
      {expandBtnClicked && (
        <div
          className="documentation-expaned"
          data-testid="documentation-expaned"
        >
          <div>
            1. Clone the repository (Skip this step if you have already done so)
            <MarkDowns content={`\`\`\`shell\ngit clone ${repoUrl}\n\`\`\``} />
          </div>
          <div>
            2. Checkout to your branch
            <MarkDowns
              content={`\`\`\`shell\ngit checkout ${currentBranch}\n\`\`\``}
            />
          </div>
          <div>
            3. Rename the sample module folder. For example: rename
            sample_common_module to common_module
          </div>
          <div>
            4. Raise a merge request against the main branch
            <MarkDowns content={`\`\`\`shell\n ${gitAddCommand}\n\`\`\``} />
            <MarkDowns content={`\`\`\`shell\n ${gitCommitCommand}\n\`\`\``} />
            <MarkDowns
              content={`\`\`\`shell\ngit push origin ${currentBranch}\n\`\`\``}
            />
          </div>
          <span>
            NOTE: Modules will be marked as done only when the pipeline is
            passed and MR is approved by a reviewer.
          </span>
        </div>
      )}
    </>
  );
};

export default DocumentationStep;
