import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { useState } from "react";

const CopyTooltip = ({ sectionName, isGoogleAuth }) => {
  const [copied, setCopied] = useState({
    origin_url: false,
    callback_url: false,
  });
  const handleCopyClick = (name) => {
    // Get the text to copy from the span element
    const textToCopy = document.getElementById(name)?.textContent;
    if (textToCopy) {
      // Create a hidden textarea element
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);

      // Select the text in the textarea and copy it
      textarea.select();
      document.execCommand("copy"); // Fallback copy command
      document.body.removeChild(textarea); // Remove the textarea after copying

      // Update the copied state
      setCopied((prevState) => ({
        ...prevState,
        [name]: true,
      }));

      // Reset copied state after 1 second
      setTimeout(() => {
        setCopied((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      }, 1000);
    }
  };

  return copied?.origin_url || copied?.callback_url ? (
    <Tooltip title="Copied" open={true}>
      <FontAwesomeIcon
        style={{ cursor: "pointer", marginRight: "10px" }}
        icon={faPaste}
        color={!isGoogleAuth ? "#B5B5B5" : "#666"}
        data-testid="Copied"
      />
    </Tooltip>
  ) : (
    <Tooltip title="Click to copy" destroyTooltipOnHide={true}>
      <FontAwesomeIcon
        style={{ cursor: "pointer", marginRight: "10px" }}
        onClick={() => handleCopyClick(sectionName)}
        icon={faPaste}
        color={!isGoogleAuth ? "#B5B5B5" : "#666"}
        data-testid="Click to copy"
      />
    </Tooltip>
  );
};

export default CopyTooltip;
