import SearchBar from "../../../../../components/SearchBar/SearchBar";
import { searchPropsForCandidateList } from "../../../../../constants/SearchBarProps";
import { useEffect, useState } from "react";
import {
  clearFilterValues,
  updateLimit,
  updateOffset,
} from "../../../../../store/actions/listParamsAction";
import { useDispatch } from "react-redux";
import { getAllCandidateList } from "../../../../../store/actions/profilePage";
import { useSelector } from "react-redux";
import {
  getListLimit,
  getListOffset,
} from "../../../../../store/selectors/listParamsSelectors";
import { candidateListselector } from "../../../../../store/selectors/profilePage.Selectors";
import {
  CANDIDATE_LIST_ID,
  CANDIDATES_TAB_FILTERS,
} from "../../../../../constants";
import { selectedCourseSelector } from "../../../../../store/selectors/coursesSelectors";
import "./Candidates.scss";
import Pagination from "../../../../../components/Pagination/Pagination";
import Spinner from "../../../../../components/Spinner/Spinner";
import UserUnenrollmentConfirmModal from "../../../../AdminUserList/UserUnenrollmentConfirmModal";
import CandidateTable from "../../../../../components/CandidateTable/CandidateTable";

const Candidates = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [timeline, setTimeline] = useState(null);
  const [topCandidates, setTopCandidates] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userListLoading, setUserListLoading] = useState(false);
  const candidateList = useSelector((state) => candidateListselector(state));
  const searchListParams = useSelector(
    (state) => state.listParams[CANDIDATE_LIST_ID]
  );
  const defaultValue = useSelector((state) =>
    getListLimit(state, CANDIDATE_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, CANDIDATE_LIST_ID)
  );
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: CANDIDATE_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: CANDIDATE_LIST_ID,
        offset: value,
      })
    );
  };
  const loadAllCandidates = () => {
    dispatch(
      getAllCandidateList(id[0]?.course?.id, {
        ...searchListParams,
        limit: defaultValue,
        offset: offSetValue,
        timeline: timeline,
        topCandidates: topCandidates,
      })
    );
  };
  // Effect to handle loading indicator
  useEffect(() => {
    if (candidateList !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setUserListLoading(false);
  }, [candidateList]);
  useEffect(() => {
    if (defaultValue !== undefined && offSetValue !== undefined) {
      loadAllCandidates();
    }
  }, [defaultValue, offSetValue, isModalOpen, searchListParams]);
  useEffect(() => {
    dispatch(
      updateLimit({
        id: CANDIDATE_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  //clean up function to remove the search values present in search bar except limit and offset
  useEffect(() => {
    return () => {
      dispatch(
        clearFilterValues({
          id: CANDIDATE_LIST_ID,
        })
      );
    };
  }, []);
  const id = useSelector((state) => selectedCourseSelector(state));
  const totalCount = candidateList?.count;
  const handleSearch = (params, _setLoadingStatus) => {
    // Perform search based on the active tab
    params.limit = defaultValue;
    params.offset = 0;
    dispatch(
      updateOffset({
        id: CANDIDATE_LIST_ID,
        limit: defaultValue,
        offset: 0,
      })
    );
    searchPropsForCandidateList.onSearch(params, id[0]?.course?.id, dispatch);
  };
  const handleRemove = (_setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: CANDIDATE_LIST_ID,
        limit: defaultValue,
        offset: 0,
      })
    );
    searchPropsForCandidateList.onClear(
      { limit: defaultValue, offset: 0 },
      id[0]?.course?.id,
      dispatch
    );
  };
  const [data, setData] = useState();
  const unEnroll = (enroll_id, user_name, course__name) => {
    // eslint-disable-next-line no-undef
    setData({
      enroll_id: enroll_id,
      user_name: user_name,
      course_name: course__name,
    });
    setIsModalOpen(true);
  };
  // eslint-disable-next-line no-unused-vars
  const unEnrollCourseCallback = (respPromise, dispatch, getState) => {
    setIsModelLoading(true);
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          loadAllCandidates();
          setIsModalOpen(false);
        }
        setIsModelLoading(false);
      })
      .catch(() => {
        setIsModelLoading(false);
      });
  };

  const timelineButtons = CANDIDATES_TAB_FILTERS;

  const handleTimelineFilter = (value) => {
    // If the same filter is clicked again, unselect it
    const newTimeline = timeline === value ? null : value;
    setTimeline(newTimeline);
    setUserListLoading(true);
  };

  const handleTopCandidateFilter = () => {
    const newTopCandidates =
      topCandidates === "TOP_CANDIDATES" ? null : "TOP_CANDIDATES";
    setTopCandidates(newTopCandidates);
    setUserListLoading(true);
  };

  useEffect(() => {
    loadAllCandidates();
  }, [timeline, topCandidates]);

  return !isLoading ? (
    <>
      <div className="profilePage-main" data-testId="profilePage-main">
        <UserUnenrollmentConfirmModal
          open={isModalOpen}
          onCancel={() => {
            loadAllCandidates();
            setIsModalOpen(false);
          }}
          data={data}
          loading={isModelLoading}
          setLoading={setIsModelLoading}
          onsuccesscallback={unEnrollCourseCallback}
        />
        <div className="profilePage-container">
          <SearchBar
            filterValues={searchPropsForCandidateList.filterValues}
            onSearch={(params, _) => {
              // Handle search event
              handleSearch(params);
            }}
            onClear={(params, _) => {
              handleRemove(params);
            }}
          />
          <div className="filter-container">
            <button
              className={`filter ${topCandidates ? "selected" : ""}`}
              onClick={handleTopCandidateFilter}
              data-testid="top-candidates-button"
            >
              Top Candidates
            </button>
            {timelineButtons.map((filter, index) => (
              <button
                className={`filter ${
                  timeline === filter.value ? "selected" : ""
                }`}
                onClick={() => handleTimelineFilter(filter.value, index)}
                key={index}
              >
                {filter.filterName}
              </button>
            ))}
          </div>
        </div>
        <CandidateTable
          topCandidates={topCandidates}
          data={candidateList.results}
          unEnrollClickHandler={unEnroll}
          userListLoading={userListLoading}
          setUserListLoading={setUserListLoading}
        />
      </div>
      <Pagination
        defaultValue={defaultValue}
        totalCount={totalCount}
        onClickHandlerDefaultValue={changeDefaultValue}
        offSetValue={offSetValue}
        onClickHandlerOffSetValue={changeOffsetValue}
        userList={candidateList}
      />
    </>
  ) : (
    <Spinner />
  );
};

export default Candidates;
