import { Rate } from "antd";
import CourseIcons from "../CourseIcons/CourseIcons";
import "./CourseInfo.scss";
const CourseInfo = ({ course, createdBy }) => {
  return (
    <>
      <div className="course-info-about-infoContainer">
        <div
          className="about-courseDescription"
          data-testid="about-courseDescription"
        >
          <div className="about-description">
            <div className="middle">
              <p className="desc">{course.course_description}</p>
            </div>

            <div className="description-footer">
              {`By ${
                createdBy === null || createdBy === undefined
                  ? "Coriolis Team"
                  : createdBy
              }`}
              {course.rating === 0 || course.rating === null ? (
                <></>
              ) : (
                <div className="descriptionFooter-rating">
                  {course.rating}
                  <Rate
                    value={course.rating}
                    disabled
                    className="descriptionFooter-stars"
                  />
                  <label className="rate-vote">
                    {course.no_of_ratings + " Ratings"}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
        <CourseIcons course={course} />
      </div>
    </>
  );
};
export default CourseInfo;
