import * as Yup from "yup";

export const EmailSettingUtils = Yup.object({
  smtp_host: Yup.string().required("host cannot be empty"),
  smtp_port: Yup.string().required("port cannot be empty"),
  sender_email: Yup.string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .required("sender email cannot be empty"),
  email_host_user: Yup.string().required("username cannot be empty"),
  email_host_password: Yup.string().required("passowrd cannot be empty"),
});

export const GoogleSettingUtils = Yup.object({
  client_id: Yup.string().required("Client Id cannot be empty"),
  secret_key: Yup.string().required("Client Secret cannot be empty"),
  origin_url: Yup.string().required("Origin Url cannot be empty"),
  callback_url: Yup.string().required("Callback Uri cannot be empty"),
});

export const LdapSettingUtils = Yup.object({
  ldap_port: Yup.string().required("LDAP port cannot be empty"),
  ldap_server_uri: Yup.string().required("LDAP server uri cannot be empty"),
  ldap_bind_dn: Yup.string().required("LDAP bind dn cannot be empty"),
  ldap_base_dn: Yup.string().required("LDAP base dn cannot be empty"),
});

export const AISettingUtils = Yup.object({
  aiEngine: Yup.string().required("Please select an AI engine"),
  apiKey: Yup.string().required("API Key is required"),
});

export const GeneralSettingUtils = Yup.object({
  instance_email: Yup.string()
    .email("Invalid email address")
    .required("Instance email cannot be empty"),
  instance_name: Yup.string().required("Instance name cannot be empty"),
});
export const UNSAVED_CHANGES_MESSAGE =
  "Unsaved changes will be discarded. Do you want to continue?";
