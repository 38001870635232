import { useEffect, useState } from "react";
import "./ModuleContent.scss";
import {
  getCoursePipelineStatus,
  loadMyCourses,
} from "../../../../store/actions/courseAction";
import { loadUserDetails } from "../../../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ModulesDisplay from "../../../../components/ModuleDisplay/ModulesDisplay";
import {
  currentModule,
  enrolledCourse,
  myCoursesSelector,
  selectedCourseSelector,
} from "../../../../store/selectors/coursesSelectors";
import { userSelector } from "../../../../store/selectors/authSelectors";
import AboutModule from "../../../../components/AboutModule/AboutModule";
import Spinner from "../../../../components/Spinner/Spinner";
import { CURRENT_MODULE, LOAD_MY_COURSES } from "../../../../store/actiontypes";
import { isPreviewSelector } from "../../../../store/selectors/wizardSelectors";
import TestsDisplay from "./TestsDisplay";
import TestContent from "../../../../components/TestContent/TestContent";

function ModuleContent(props) {
  const { lab_required } = props;
  const [isEnrolled, setEnrolled] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [moduleArray, setModuleArray] = useState([]);
  const [quizArray, setQuizArray] = useState([]);
  const [moduleProp, setModuleProp] = useState(null);

  const currentModules = useSelector(currentModule);

  const course = useSelector(selectedCourseSelector);
  const enrolledCourses = useSelector(enrolledCourse);
  const myCourses = useSelector(myCoursesSelector);
  const user = useSelector(userSelector);
  const isPreview = useSelector(isPreviewSelector);
  const { id } = useParams();
  const dispatch = useDispatch();

  const checkIfEnrolled = (id) => {
    if (user === null) dispatch(loadUserDetails());

    if (user && myCourses === null && enrolledCourses?.length > 0) {
      enrolledCourses.forEach((ele) => {
        if (ele?.enroll_details[0]?.course?.id === id) {
          dispatch(loadMyCourses(ele?.enroll_details[0]?.id));
          setEnrolled(true);
        }
      });
    }

    if (myCourses !== null) {
      myCourses.forEach((element) => {
        if (element.enroll_details.course.id === id) {
          setEnrolled(true);
        }
      });
    }
  };

  const settingCourseList = () => {
    let moduleslist = [];
    course.forEach((element) => {
      moduleslist.push(element.module);
    });
    let obj = [
      {
        course: course[0].course,
        modules: moduleslist,
      },
    ];
    setCourseList(obj);
  };

  useEffect(() => {
    if (course !== null) {
      settingCourseList();
      checkIfEnrolled(course[0].course.id);
    }
    if (!isPreview) {
      dispatch(getCoursePipelineStatus(id));
    }
    return () => {
      dispatch({ type: LOAD_MY_COURSES, payload: null });
      dispatch({ type: CURRENT_MODULE, payload: null });
    };
  }, [course, id]);

  useEffect(() => {
    if (courseList.length > 0) {
      let newModuleArray =
        isEnrolled && myCourses !== null
          ? myCourses[0].module_details
          : courseList[0]?.modules;
      setModuleArray(newModuleArray);

      let newModuleProp =
        isPreview && currentModules === null
          ? courseList[0]?.modules[0]
          : currentModules;
      setModuleProp(newModuleProp);

      let newQuizArray =
        isEnrolled && myCourses !== null
          ? myCourses[0].mcq_details
          : courseList[0]?.modules[0]?.mcqs || [];

      setQuizArray(newQuizArray);
    }
  }, [myCourses, courseList, currentModules]);

  useEffect(() => {
    if (isEnrolled) {
      getDefaultModule();
    }
  }, [myCourses]);

  useEffect(() => {
    if (!isEnrolled && isPreview && courseList.length > 0) {
      dispatch({
        type: CURRENT_MODULE,
        payload: courseList[0].modules[0],
      });
    }
  }, [courseList]);

  function getDefaultModule() {
    if (myCourses !== null) {
      let existingModule = [];
      if (myCourses[0]?.completed) {
        existingModule = [myCourses[0].module_details[0]];
      } else {
        existingModule = myCourses[0].module_details.filter((module) => {
          return module.module_progress === "IN_PROGRESS";
        });
        //if ALL modules are completed then Display the TEST for the course if present
        if (existingModule.length === 0) {
          //add test details as current test in progress to display
          existingModule = myCourses[0].mcq_details.filter((module) => {
            return module.mcq_progress === "IN_PROGRESS";
          });
        }
      }
      dispatch({
        type: CURRENT_MODULE,
        payload: existingModule[0],
      });
    }
  }

  return (
    <div className="courseContent-container">
      <div className="about-module">
        {courseList.length > 0 &&
        (currentModules !== null || isPreview) &&
        (myCourses !== null || isPreview) &&
        moduleProp &&
        moduleArray ? (
          moduleProp?.mcq ? (
            <TestContent
              mcqdetails={moduleProp}
              enrolledId={myCourses ? myCourses[0]?.enroll_details?.id : null}
            />
          ) : (
            <AboutModule
              modules={moduleProp}
              isEnrolled={isEnrolled}
              lab_required={lab_required}
              enroll_id={myCourses ? myCourses[0]?.enroll_details?.id : null}
            />
          )
        ) : (
          <Spinner />
        )}
      </div>
      <div className="module-section" data-testid="module-section">
        <div className="module-section-header">Sections</div>
        {(courseList.length > 0 || (myCourses !== null && !isPreview)) &&
        currentModules &&
        moduleArray ? (
          <>
            <div className="module-section-list">
              <div>
                <ModulesDisplay
                  modules={moduleArray}
                  course={courseList[0]?.course}
                  isEnrolled={isEnrolled}
                  isPreview={isPreview}
                />
              </div>
            </div>
            {quizArray && quizArray.length > 0 && (isEnrolled || isPreview) ? (
              <div className="quiz-section-list">
                <TestsDisplay
                  quiz={quizArray}
                  isEnrolled={isEnrolled}
                  isPreview={isPreview}
                />
              </div>
            ) : null}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

export default ModuleContent;
