/* eslint-disable react/prop-types */
import DashboardCourseCard from "../../components/DashboardCourseCard/DashboardCourseCard";
// import StatisticCard from "../../components/StatisticCard/StatisticCard";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../store/selectors/authSelectors";
import { enrolledCourse } from "../../store/selectors/coursesSelectors";
import { useEffect, useState } from "react";
import { loadEnrolledCourses } from "../../store/actions/courseAction";
import Spinner from "../../components/Spinner/Spinner";
import "./UserDashboardPage.scss";
import { Carousel } from "antd";
import { ReactComponent as LeftArrow } from "../../assets/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/RightArrow.svg";
import EnrolledCoursesEmptyContainer from "../../components/EnrolledCoursesEmptyContainer/EnrolledCoursesEmptyContainer";
// const Statistics = () => {
//   return (
//     <div className="statistics-container">
//       <StatisticCard value="3" title="Courses Enrolled" />
//       <StatisticCard value="1" title="Courses Completed" />
//       <StatisticCard value="10 Hrs 50 Min" title="Time Spent" />
//     </div>
//   );
// };

const Courses = ({ data }) => {
  return (
    <Carousel
      arrows
      prevArrow={<LeftArrow />}
      nextArrow={<RightArrow />}
      dots={false}
      infinite={false}
      vertical={false}
    >
      <div className="dashboardCourses-container">
        {data.map((course, index) => {
          return (
            <>
              <DashboardCourseCard
                uniqueId={index}
                key={index}
                completed={course.completed}
                course={course}
              />
            </>
          );
        })}
      </div>
    </Carousel>
  );
};

function UserDashboardPage() {
  const user = useSelector(userSelector);
  const enrolledCourses = useSelector(enrolledCourse);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  function loadCourses() {
    dispatch(loadEnrolledCourses(user?.id, {}, () => setIsLoading(false)));
  }
  useEffect(() => {
    loadCourses();
  }, []);
  // useEffect(() => {
  //   if (enrolledCourses !== null) {
  //     setIsLoading(false);
  //   }
  // }, [enrolledCourses]);
  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div
        data-testid="userDashboard-container"
        className="userDashboard-container"
      >
        <div data-testid="userDashboard-title" className="userDashboard-title">
          {`Welcome ${user.user_name}`}!
        </div>
        <div className="userDashboard-subtitle">
          Here's a view of your current progress
        </div>
        {/* <Statistics /> */}
        <div className="userDashboardCourses-title">Courses in Progress</div>
        {enrolledCourses !== undefined && enrolledCourses?.length > 0 ? (
          <Courses data={enrolledCourses} />
        ) : (
          <EnrolledCoursesEmptyContainer />
        )}
      </div>
    </>
  );
}

export default UserDashboardPage;
