import { Field, Form, Formik } from "formik";
import {
  checkTenantExistence,
  validateTokenAction,
} from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import { Button } from "antd";
import { useState } from "react";
import { isTenantMode } from "../../libs/utils";
import * as Yup from "yup";

const LdapContainer = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const isTenant = !isTenantMode();

  const handleSubmit = (values, { setFieldError }) => {
    const handleError = () => setSubmitting(false);
    const handleSuccess = (data) => {
      setSubmitting(false);
      if (data?.exist) {
        const { protocol, host } = window.location;
        const redirectUrl = `${protocol}//${values.organization_name}.${host}/#/login`;

        window.location.href = redirectUrl;
      } else if (isTenant && !data?.exist) {
        setFieldError(
          "organization_name",
          "The organization name entered is not registered. Please Sign up!"
        );
      }
    };

    isTenant
      ? dispatch(checkTenantExistence(values, handleSuccess, handleError))
      : dispatch(validateTokenAction(values, handleError));
    setSubmitting(true);
  };

  const tenantValidationSchema = Yup.object({
    organization_name: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Invalid name format. Only alphabets, numeric, and underscores are allowed"
      )
      .required("Organization name is required"),
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const loginValidationSchema = Yup.object({
    username: Yup.string()
      .test(
        "is-valid-email",
        "Please use a valid email address",
        (value) => !value || isValidEmail(value)
      )
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Formik
      initialValues={
        isTenant ? { organization_name: "" } : { username: "", password: "" }
      }
      validationSchema={
        isTenant ? tenantValidationSchema : loginValidationSchema
      }
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, setFieldTouched, isValid, dirty }) => (
        <Form className="auth-form">
          {isTenant ? (
            <>
              <Field
                label="Organization Name"
                type="text"
                name="organization_name"
                component={StyledInputField}
                placeholder="Enter Organization Name"
                isRequired
              />
            </>
          ) : (
            <>
              <Field
                label="Email Id"
                type="email"
                name="username"
                data-testid="email"
                component={StyledInputField}
                placeholder="Enter Your Email Id"
                onChange={(e) => {
                  setFieldTouched("username", true, true);
                  setFieldValue("username", e.target.value);
                }}
              />
              <div className="password-container">
                <Field
                  label="Password"
                  type="password"
                  name="password"
                  data-testid="password"
                  component={StyledInputField}
                  placeholder="Enter Your Password"
                  onChange={(e) => {
                    setFieldTouched("password", true, true);
                    setFieldValue("password", e.target.value);
                  }}
                />
                <a href="/#/forgot_password" className="forgot-password">
                  Forgot password
                </a>
              </div>
            </>
          )}
          <Button
            type="primary"
            size="large"
            block
            loading={isSubmitting}
            htmlType="submit"
            disabled={!isValid || !dirty}
            className="sign-in-button"
            data-testid="sign-in-button"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LdapContainer;
