import { useDispatch } from "react-redux";
import StyledModal from "../../../components/StyledModal/StyledModal";
import { useRef, useState } from "react";
import BulkInviteContent from "./BulkInviteContent";
import "./BulkInviteModal.scss";
import {
  enrollIntoSingleCourse,
  inviteMultipleUsersFromSheet,
} from "../../../store/actions/adminActions";
import { useSelector } from "react-redux";
// import { userSelector } from "../../../store/selectors/authSelectors";
import { LOAD_SELECTED_COURSES } from "../../../store/actiontypes";
import { selectedCourseSelector } from "../../../store/selectors/coursesSelectors";

const BulkInviteModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [inputFiles, setInputFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // const user = useSelector(userSelector);
  const handleOnDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setInputFiles(event.dataTransfer.files);
  };

  const course = useSelector((state) =>
    selectedCourseSelector(state, LOAD_SELECTED_COURSES)
  );

  const onSuccessCallBack = () => {
    setIsUploading(false);
    setOpen(!open);
    handleRemoveFile();
  };
  const handleSubmit = () => {
    setIsUploading(true);
    // API function to upload Excel sheet
    dispatch(inviteMultipleUsersFromSheet(inputFiles[0], onSuccessCallBack));
  };
  const handleSubmitInCourses = () => {
    setIsUploading(true);
    dispatch(
      enrollIntoSingleCourse(
        inputFiles[0],
        course[0]?.course?.id,
        onSuccessCallBack
      )
    );
  };

  const handleRemoveFile = () => {
    /* Setting inputRef value "" because it doesn't
        update the value when you reselect the same file again in input filed */
    inputRef.current.value = "";
    setInputFiles(null);
  };
  return (
    <StyledModal
      className="BulkInvite-modal"
      title={"Invite Users By Uploading Excel Sheet"}
      centered
      open={open}
      onOk={course ? handleSubmitInCourses : handleSubmit}
      onCancel={() => {
        handleRemoveFile();
        setOpen(false);
      }}
      width={950}
      okText={"Upload Sheet"}
      okButtonProps={{
        disabled: isLoading || !inputFiles,
        loading: isUploading,
      }}
      // cancelButtonProps={{ disabled: isLoading }}
      closable={false}
    >
      <BulkInviteContent
        handleOnDragOver={handleOnDragOver}
        handleDrop={handleDrop}
        inputFiles={inputFiles}
        setInputFiles={setInputFiles}
        inputRef={inputRef}
        dispatch={dispatch}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleRemoveFile={handleRemoveFile}
      />
    </StyledModal>
  );
};

export default BulkInviteModal;
