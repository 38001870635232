import { Breadcrumb, Button, Checkbox, List } from "antd";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../../../assets/arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  currentEditingTestDetails,
  currentMCQQuestionBankQuestionList,
} from "../../../../store/selectors/wizardSelectors";
const courseId = location.pathname.split("/")[2];
import "./QuestionBankManager.scss";
import {
  addQuestionToMCQ,
  getMCQQuestionBankList,
} from "../../../../store/actions/wizardActions";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import { useEffect, useState } from "react";

import {
  getListLimit,
  getListOffset,
} from "../../../../store/selectors/listParamsSelectors";
import {
  updateLimit,
  updateOffset,
  updateSearchValues,
} from "../../../../store/actions/listParamsAction";
import { QUESTION_BANK_LIST_ID } from "../../../../store/actiontypes";
import Spinner from "../../../../components/Spinner/Spinner";
import { QuestionListItemHeader } from "./QuestionListItemHeader";
import { QuestionListItem } from "./QuestionListItem";
import Pagination from "../../../../components/Pagination/Pagination";
import { searchPropsForQuestionManager } from "../../../../constants/SearchBarProps";
const CustomEmptyContent = () => (
  <div className="questionList-container-empty">No results found</div>
);
const QuestionBankManager = () => {
  const currentEditingTestList = useSelector(currentEditingTestDetails);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredQuestionId, setHoveredQuestionId] = useState(null);
  useEffect(() => {
    dispatch(getMCQQuestionBankList(currentEditingTestList?.id));
  }, []);
  const searchListParams = useSelector(
    (state) => state.listParams[QUESTION_BANK_LIST_ID]
  );

  const questions = useSelector(currentMCQQuestionBankQuestionList);
  useEffect(() => {
    if (questions !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [questions]);
  const [checkedArray, setCheckedArray] = useState([]);

  const totalCount = questions?.count;
  const defaultValue = useSelector((state) =>
    getListLimit(state, QUESTION_BANK_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, QUESTION_BANK_LIST_ID)
  );
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: QUESTION_BANK_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
    dispatch(
      getMCQQuestionBankList(currentEditingTestList?.id, {
        ...searchListParams,
        limit: value[0].label,
        offset: offSetValue,
      })
    );
  };
  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: QUESTION_BANK_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      getMCQQuestionBankList(currentEditingTestList?.id, {
        ...searchListParams,
        limit: defaultValue,
        offset: value,
      })
    );
  };
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: QUESTION_BANK_LIST_ID,
          offset: 0,
        })
      );
      if (params.search) {
        dispatch(
          getMCQQuestionBankList(currentEditingTestList?.id, {
            search: params.search,
            limit: defaultValue,
            offset: offSetValue,
          })
        );
      } else {
        dispatch(
          getMCQQuestionBankList(currentEditingTestList?.id, {
            ...params,
            limit: defaultValue,
            offset: offSetValue,
          })
        );
      }
    }
    searchPropsForQuestionManager.onSearch(
      params,
      currentEditingTestList?.id,
      dispatch
    );
  };

  const handleRemove = (_setLoadingStatus) => {
    // Perform search based on the active tab
    dispatch(
      updateOffset({
        id: QUESTION_BANK_LIST_ID,
        limit: defaultValue,
        offset: 0,
      })
    );
    searchPropsForQuestionManager.onClear(
      { limit: defaultValue, offset: 0 },
      currentEditingTestList?.id,
      dispatch
    );
  };
  // const loadAllQuestions = (params) => {
  //   setIsLoading(true);
  //   const filters = {};
  //   for (const [key, value] of Object.entries(searchListParams)) {
  //     filters[key] = value;
  //   }
  //   if (Object.keys(filters).length === 0) {
  //     dispatch(
  //       getMCQQuestionBankList(currentEditingTestList?.id, {
  //         ...params,
  //         limit: defaultValue,
  //         offset: offSetValue,
  //       })
  //     );
  //   } else {
  //     handleSearch(filters);
  //   }
  // };
  useEffect(() => {
    dispatch(
      updateLimit({
        id: QUESTION_BANK_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
    dispatch(
      updateSearchValues({
        id: QUESTION_BANK_LIST_ID,
        params: {},
      })
    );
  }, []);

  // useEffect(() => {
  //   if (defaultValue !== undefined && offSetValue !== undefined) {
  //     loadAllQuestions({});
  //   }
  // }, []);

  const onChange = (e) => {
    e.target.checked
      ? setCheckedArray([...checkedArray, e.target.value])
      : setCheckedArray(checkedArray.filter((item) => item !== e.target.value));
  };
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    setCheckAll(checkedArray.length === questions?.total_count);
  }, [checkedArray]);
  const onCheckAllChange = (e) => {
    setCheckedArray(
      e.target.checked ? questions?.results.map((item) => item.question_id) : []
    );
    setCheckAll(e.target.checked);
  };
  const AddAllToQuestionBank = (checkedArray) => {
    dispatch(addQuestionToMCQ(currentEditingTestList?.id, checkedArray));
  };
  return !isLoading ? (
    <>
      <div className="question-manager-container" style={{ width: "100%" }}>
        {" "}
        <div className="question-bank-manager-header">
          <Breadcrumb separator={<Arrow />}>
            <BreadcrumbItem>
              <Link
                to={`/courses/${courseId}/test/${currentEditingTestList?.id}/manager`}
              >
                Question Manager
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Question bank</BreadcrumbItem>
          </Breadcrumb>
          <Link
            to={`/courses/${courseId}/test/${currentEditingTestList?.id}/manager`}
          >
            <Button className="back-test">Back to Test</Button>
          </Link>
        </div>
        <div className="qb-searchbar">
          <SearchBar
            filterValues={searchPropsForQuestionManager.filterValues}
            onSearch={(params, _) => {
              handleSearch(params);
            }}
            onClear={(params, _) => {
              handleRemove(params);
            }}
          />
        </div>
        <div className="questionlist-checkbox">
          <Checkbox
            className="question-list-checkbox"
            onChange={onCheckAllChange}
            isChecked={checkAll}
          />
          <span className="question-list-checkbox-text">
            {checkedArray.length === 0
              ? `Questions (${questions?.results?.length}) `
              : `${checkedArray.length} Selected`}
          </span>
          {checkedArray.length === 0 ? null : (
            <>
              {" "}
              <span className="question-list-checkbox-separator">|</span>
              <span
                className="question-list-delete-button"
                onClick={() => AddAllToQuestionBank(checkedArray)}
              >
                <span className="question-list-delete-button-text">
                  Add to Test
                </span>
              </span>
            </>
          )}
        </div>
        <List
          itemLayout="horizontal"
          dataSource={questions?.results}
          className="questionList-items"
          renderItem={(item, index) => (
            <List.Item
              onMouseEnter={() => setHoveredQuestionId(item.question_id)}
              onMouseLeave={() => setHoveredQuestionId(null)}
            >
              <List.Item.Meta
                avatar={
                  <Checkbox
                    value={item.question_id}
                    onChange={(e) => onChange(e)}
                    checked={checkedArray.includes(item.question_id)}
                  />
                }
                title={
                  !location.pathname.includes("question-bank") ? (
                    <QuestionListItemHeader
                      mcqId={currentEditingTestList}
                      header={item}
                      index={index}
                      isHovered={hoveredQuestionId === item.question_id}
                    />
                  ) : null
                }
                description={
                  <QuestionListItem
                    isQuestionReviewList={true}
                    index={index}
                    question={item}
                    options={item.options}
                    disabled={true}
                    givingTest={false}
                  />
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: <CustomEmptyContent /> }}
        />
      </div>
      <div className="questionList-pagination">
        <Pagination
          totalCount={totalCount}
          userList={questions}
          defaultValue={defaultValue}
          offSetValue={offSetValue}
          onClickHandlerDefaultValue={changeDefaultValue}
          onClickHandlerOffSetValue={changeOffsetValue}
        />
      </div>
    </>
  ) : (
    <div className="questionReviewList">
      <Spinner />
    </div>
  );
};
export default QuestionBankManager;
