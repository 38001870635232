import Breadcrumbs from "../../../../components/BreadCrumb/BreadCrumbs";
import { GoogleSettingPageHeaderDesc } from "../../../../constants";
import "./AuthenticationGoogleContainer.scss";
import { useEffect, useState } from "react";
import GoogleSettingHeaderSection from "./GoogleSettingHeaderSection";
import GoogleSettingFormSection from "./GoogleSettingFormSection";
import { useDispatch } from "react-redux";
import { getAuthGoogleSetting } from "../../../../store/actions/adminActions";
import Spinner from "../../../../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import { getGoogleAuthSetting } from "../../../../store/selectors/adminSelectors";

const AuthenticationGoogleContainer = () => {
  const [isGoogleAuth, setIsGoogleAuth] = useState(false); //Saving state of Google switch button
  const [isLoading, setIsLoading] = useState(true);
  const currentGoogleSetting = useSelector(getGoogleAuthSetting);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAuthGoogleSetting(setIsLoading, setIsGoogleAuth));
  }, []);
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="google-setting-container">
      <Breadcrumbs omit={[0]} />
      <GoogleSettingHeaderSection
        title={"Google"}
        desc={GoogleSettingPageHeaderDesc}
        isGoogleAuth={isGoogleAuth}
        setIsGoogleAuth={setIsGoogleAuth}
        dispatch={dispatch}
        currentGoogleSetting={currentGoogleSetting}
      />
      <GoogleSettingFormSection
        currentGoogleSetting={currentGoogleSetting}
        isGoogleAuth={isGoogleAuth}
      />
    </div>
  );
};

export default AuthenticationGoogleContainer;
