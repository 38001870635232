import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function CodeCopyBtn({ children }) {
  const [copyOk, setCopyOk] = useState(false);
  const icon = copyOk ? faCheckCircle : faCopy;
  const handleClick = () => {
    setCopyOk(true);
    const textarea = document.createElement("textarea");
    textarea.innerText = children[0];
    document.body.appendChild(textarea);

    // Select the text in the textarea and copy it
    textarea.select();
    document.execCommand("copy"); // Fallback copy command
    document.body.removeChild(textarea); // Remove the textarea after copying

    setTimeout(() => {
      setCopyOk(false);
    }, 2000);
  };
  return (
    <div
      className="code-copy-btn"
      data-testid="code-copy-btn"
      onClick={handleClick}
      style={{ cursor: "pointer", paddingRight: "15px" }}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}
