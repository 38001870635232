import StyledModal from "../../../../components/StyledModal/StyledModal";
import { questionReviewOfJob } from "../../../../store/actions/genAiActions";
import { useDispatch } from "react-redux";
import "../../../../../src/features/CourseListFeature/WizardCourseSteps/McqStep/QuestionDeleteConfirmModel.scss";

function QuestionsReviewConfirmModal({
  open,
  onCancel,
  mcqId,
  setLoading,
  setIsJobSelected,
  onCallBack,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setLoading(true);
    dispatch(
      questionReviewOfJob(mcqId, setIsJobSelected, setLoading, onCallBack)
    );
  };
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={"Mark it as Done"}
      visible={open}
      onCancel={onCancel}
      onOk={handleOk}
      closable={true}
      okText="Yes"
      cancelText="No"
    >
      <div className="question-delete-modal">
        All In Review Questions will be Deleted.
        <br />
        Are You Sure You Want to Proceed.
      </div>
    </StyledModal>
  );
}

export default QuestionsReviewConfirmModal;
