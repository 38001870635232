import "./CoursesTabLayout.scss";
import AllCourseTabScreen from "./TabScreen/AllCourseTabScreen/AllCourseTabScreen";
import ApprovalCourseTabScreen from "./TabScreen/ApprovalCourseTabScreen/ApprovalCourseTabScreen";
import { Tabs } from "antd";
import { BookOutlined, FormOutlined, LikeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import OwnedCourseTabScreen from "./TabScreen/OwnedCourseTabScreen/OwnedCourseTabScreen";
import { groupArraySelector } from "../../../store/selectors/authSelectors";
import { useSelector } from "react-redux";
import constants from "../../../constants";

const { TabPane } = Tabs;

const CoursesTabLayout = ({
  activeTab,
  setActiveTab,
  setSearchKey,
  setIsWizardOpen,
  draftRefresher,
}) => {
  const groupsArr = useSelector(groupArraySelector);
  const tabConfigurations = [
    {
      key: "all",
      icon: <BookOutlined />,
      label: "All Courses",
      content: <AllCourseTabScreen />,
      shouldRender: true,
    },
    {
      key: "owned",
      icon: <FormOutlined />,
      label: "Authored Courses",
      content: (
        <OwnedCourseTabScreen
          setIsWizardOpen={setIsWizardOpen}
          draftRefresher={draftRefresher}
        />
      ),
      shouldRender: true,
    },
    {
      key: "approval",
      icon: <LikeOutlined />,
      label: "Pending Approvals",
      content: <ApprovalCourseTabScreen setIsWizardOpen={setIsWizardOpen} />,
      shouldRender:
        groupsArr.includes(constants.PORTAL_ADMIN) ||
        groupsArr.includes(constants.HR), // Conditionally render the tab based on role
    },
  ];
  const navigate = useNavigate();
  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`/courses/${key}`);
    setSearchKey(Date.now());
  };

  return (
    <div className="coursesTabLayout-container">
      <Tabs
        defaultActiveKey="all"
        activeKey={activeTab}
        className="tabs-container"
        onChange={handleTabChange}
      >
        {tabConfigurations.map((tab) =>
          tab.shouldRender ? (
            <TabPane
              key={tab.key}
              tab={
                <span>
                  {tab.icon}
                  {tab.label}
                </span>
              }
              className={activeTab === tab.key ? "active-tab" : "inactive-tab"}
              data-testid={`tab-${tab.key}`}
              forceRender={true}
            >
              {activeTab === tab.key && tab.content}
            </TabPane>
          ) : null
        )}
      </Tabs>
    </div>
  );
};

export default CoursesTabLayout;
