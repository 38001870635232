import {
  GET_ALL_QUESTION_BANK_QUESTION_LIST,
  PDF_SUBMITTED,
  QUESTION_BANK_QUESTION_LIST,
  REMOVE_SELECTED_JOB,
  SELECTED_JOB,
} from "../actiontypes";

const initialState = {
  tasks: [], // Initialize tasks as an empty array
  question_list: [], // Initialize question_list as an empty array
  selectedJob: {},
  editselctedQuestion: {},
  all_question_bank_list: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PDF_SUBMITTED:
      return { ...state, tasks: [...state.tasks, payload] };
    case QUESTION_BANK_QUESTION_LIST:
      return { ...state, question_list: payload };
    case SELECTED_JOB:
      return { ...state, selectedJob: payload };
    case REMOVE_SELECTED_JOB:
      return { ...state, selectedJob: payload };
    case "EDIT_QUESTION_QB":
      return { ...state, editselctedQuestion: payload };
    case GET_ALL_QUESTION_BANK_QUESTION_LIST:
      return {
        ...state,
        all_question_bank_list: payload,
      };
    default:
      return state;
  }
}
