/* eslint-disable no-unused-vars */
import "./AskAiTabScreen.scss";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import FilterComponent from "../../../../components/FilterComponent/FilterComponent";
import Pagination from "../../../../components/Pagination/Pagination";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  clearFilterValues,
  updateLimit,
  updateOffset,
  updateSearchValues,
} from "../../../../store/actions/listParamsAction";
import { ASK_AI_LIST_ID } from "../../../../constants";
import JobsContainer from "./JobsContainer";
import Spinner from "../../../../components/Spinner/Spinner";
// import { getAllJobsList } from "../../../../store/actions/jobActions";
import { useSelector } from "react-redux";
import {
  getListLimit,
  getListOffset,
} from "../../../../store/selectors/listParamsSelectors";
// import {
//   isJobsSelected,
//   jobActionsList,
// } from "../../../../store/selectors/jobActions";
import {
  JOB_DEFAULT_PAGE_PARAMS,
  JOB_FILTER_VALUES,
} from "../../../../constants/AskAITabs";
import { userSelector } from "../../../../store/selectors/authSelectors";
import QuestionsReviewList from "../JobsCoomponent/QuestionsReviewList";
import {
  isJobsSelected,
  jobActionsList,
} from "../../../../store/selectors/jobActions";
import { getAllJobsList } from "../../../../store/actions/jobActions";
import { searchPropsForJobsTab } from "../../../../constants/SearchBarProps";

function AskAiTabScreen() {
  const dispatch = useDispatch();

  const { defaultLimit, defaultOffset } = JOB_DEFAULT_PAGE_PARAMS;
  const jobsList = useSelector(jobActionsList) || { results: [], count: 0 };
  const user = useSelector(userSelector);
  const isJobSelected = useSelector(isJobsSelected);
  const { results, count } = jobsList;
  let limitValue = useSelector((state) => getListLimit(state, ASK_AI_LIST_ID));
  let offSetValue = useSelector((state) =>
    getListOffset(state, ASK_AI_LIST_ID)
  );
  const searchListParams = useSelector(
    (state) => state.listParams[ASK_AI_LIST_ID]
  );
  const changeDefaultValue = (value) => {
    dispatch(
      updateLimit({
        id: ASK_AI_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };

  const changeOffsetValue = (value) => {
    dispatch(
      updateOffset({
        id: ASK_AI_LIST_ID,
        offset: value,
      })
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true);

  const stopLoading = () => {
    setIsLoading(false);
  };

  // handle onSearch
  const handleSearch = (params) => {
    fetchAllJobsList(params);
  };

  const onFilterChange = (value) => {
    const params = value?.length > 0 ? { status: value.join(",") } : {};
    fetchAllJobsList(params);
  };

  const fetchAllJobsList = (params = {}) => {
    dispatch(
      updateSearchValues({
        id: ASK_AI_LIST_ID,
        ...params,
      })
    );
    dispatch(
      getAllJobsList(
        user.id,
        {
          ...params,
          limit: limitValue ? limitValue : defaultLimit,
          offset: offSetValue ? offSetValue : defaultOffset,
        },
        stopLoading
      )
    );
  };

  useEffect(() => {
    if (offSetValue !== undefined && limitValue !== undefined)
      fetchAllJobsList({ ...searchListParams });
  }, [offSetValue, limitValue]);

  useEffect(() => {
    // Initialize Pagination in redux with default values
    dispatch(
      updateLimit({
        id: ASK_AI_LIST_ID,
        limit: defaultLimit,
        offset: defaultOffset,
      })
    );
    return () => {
      dispatch(
        clearFilterValues({
          id: ASK_AI_LIST_ID,
        })
      );
    };
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="askAi-loading-container">
          <Spinner />
        </div>
      ) : !isJobSelected ? (
        <>
          <div className="AskAi-container">
            {/* SearchBar without filters */}
            <div className="job-container-wrapper">
              <SearchBar
                filterValues={searchPropsForJobsTab.filterValues}
                onSearch={handleSearch}
                onClear={handleSearch}
              />
            </div>
            {/* Filter Tags */}
            <div className="job-container-wrapper">
              <FilterComponent
                filters={JOB_FILTER_VALUES}
                onChange={onFilterChange}
              />
            </div>
            {/* Jobs Listing */}
            {results.length === 0 ? (
              <div className="job-empty-container">
                <div className="job-card">No records to show!</div>
              </div>
            ) : (
              <JobsContainer jobs={results} />
            )}
            {/* Pagination */}
          </div>
          <Pagination
            defaultValue={limitValue}
            totalCount={count}
            onClickHandlerDefaultValue={changeDefaultValue}
            offSetValue={offSetValue}
            onClickHandlerOffSetValue={changeOffsetValue}
            userList={jobsList}
          />
        </>
      ) : (
        <QuestionsReviewList />
      )}
    </>
  );
}

export default AskAiTabScreen;
