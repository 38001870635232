export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GOOGLE_CLIENT_ID = "GOOGLE_CLIENT_ID";
export const USER_MAIL = "USER_MAIL";
export const LOAD_MY_COURSES = "LOAD_MY_COURSES";
export const LOAD_COURSES = "LOAD_COURSES";
export const LOAD_COURSE_CATEGORIES = "LOAD_COURSE_CATEGORIES";
export const LOAD_SELECTED_COURSES = "LOAD_SELECTED_COURSES";
export const LOAD_AUTHORED_COURSES = "LOAD_AUTHORED_COURSES";
export const CLEAR_AUTHORED_COURSES = "CLEAR_AUTHORED_COURSES";
export const CLEAR_SELECTED_COURSES = "CLEAR_SELECTED_COURSES";
export const CLEAR_COURSES = "CLEAR_COURSES";
export const CLEAR_COURSES_LIST = "CLEAR_COURSES_LIST";
export const GET_PIPELINE_STATUS = "GET_PIPELINE_STATUS";
export const ENROLLED_COURSE = "ENROLLED COURSE";
export const CLEAR_ENROLLED_COURSE = "CLEAR_ ENROLLED_COURSE";
export const UNENROLL_COURSE = "UNENROLL_COURSE";
export const CREATE_BRANCHES_BY_COURSE = "CREATE_BRANCHES_BY_COURSE";
export const ARCHIVE_MULTIPLE_USERS = "ARCHIVE_MULTIPLE_USERS";
export const FETCH_LLM_DETAILS_SUCCESS = "FETCH_LLM_DETAILS_SUCCESS";
export const FETCH_LLM_DETAILS_FAILURE = "FETCH_LLM_DETAILS_FAILURE";
export const FETCH_LLMS_SUCCESS = "FETCH_LLMS_SUCCESS";
export const FETCH_LLMS_FAILURE = "FETCH_LLMS_FAILURE";
export const POST_LLM_DETAILS_SUCCESS = "POST_LLM_DETAILS_SUCCESS";
export const POST_LLM_DETAILS_FAILURE = "POST_LLM_DETAILS_FAILURE";
export const START_NAVIGATION = "START_NAVIGATION";
export const CANCEL_NAVIGATION = "CANCEL_NAVIGATION";
export const COMPLETE_NAVIGATION = "COMPLETE_NAVIGATION";
export const GET_REPOSITORY_DETAILS_SUCCESS = "GET_REPOSITORY_DETAILS_SUCCESS";
export const GET_REPOSITORY_DETAILS_FAILURE = "GET_REPOSITORY_DETAILS_FAILURE";
export const ALL_USERS = "ALL USERS";
export const CANDIDATE_LIST = "CANDIDATE_LIST";
export const ENROLL_USER_INTO_COURSE = "ENROLL_USER_INTO_COURSE";
export const SET_PAGINATION_LIMIT = "limit";
export const SET_PAGINATION_OFFSET = "offset";
export const SET_SEARCH_VALUES = "UPDATE_SEARCH_VALUES";
export const REMOVE_SEARCH_VALUES = "REMOVE_SEARCH_VALUES";
export const REMOVE_ROLE_VALUES = "REMOVE_ROLE_VALUES";
export const REMOVE_ISARCHIVE_VALUES = "REMOVE_ISARCHIVE_VALUES";

export const ADD_RATING = "ADD_RATING";
export const MY_RATING = "MY_RATING";
export const ALL_RATINGS = "ALL_RATINGS";
export const REMOVE_RATINGS = "REMOVE_RATINGS";
export const SET_SAVE_BUTTON_DISABLED = "SET_SAVE_BUTTON_DISABLED";
export const SHOW_BANNER_INFO = "SHOW_BANNER_INFO";
export const SHOW_BANNER_ERROR = "SHOW_BANNER_ERROR";
export const SHOW_BANNER_WARNING = "SHOW_BANNER_WARNING";
export const HIDE_BANNER = "HIDE_BANNER";
export const REMOVE_SELECTED_JOB = "REMOVE_SELECTED_JOB";
export const SELECTED_JOB = "SELECTED_JOB";

export const CURRENT_MODULE = "CURRENT_MODULE";
export const LOAD_IN_REVIEW_COURSES = "LOAD_IN_REVIEW_COURSES";
export const CLEAR_IN_REVIEW_COURSES = "CLEAR_IN_REVIEW_COURSES";
export const CHANGE_CURRENT_STEP = "CHANGE_CURRENT_STEP";
export const WIZARD_NEXT = "WIZARD_NEXT";
export const WIZARD_PREV = "WIZARD_PREV";
export const WIZRAD_STEP_ONE = "WIZRAD_STEP_ONE";
export const WIZRAD_STEP_TWO = "WIZRAD_STEP_TWO";
export const WIZRAD_STEP_TWO_ADD = "WIZRAD_STEP_TWO_ADD";
export const WIZARD_STEP_TWO_EDIT = "WIZARD_STEP_TWO_EDIT";
export const WIZARD_STEP_TWO_DELETE = "WIZARD_STEP_TWO_DELETE";
export const WIZARD_STEP_MCQ_DELETE = "WIZARD_STEP_MCQ_DELETE";
export const WIZARD_STEP_TWO_REARRANGE = "WIZARD_STEP_TWO_REARRANGE";
export const WIZARD_STEP_FOUR = "WIZARD_STEP_FOUR";
export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_MCQ = "CREATE_MCQ";
export const DELETE_MCQ = "DELETE_MCQ";
export const GET_MCQ = "GET_MCQ";
export const REORDER_MCQ = "REORDER_MCQ";
export const CURRENT_EDITING_TEST_DETAILS = "CURRENT_EDITING_TEST_DETAILS";
export const WIZARD_LOADER_TRUE = "WIZARD_LOADER_TRUE";
export const WIZARD_LOADER_FALSE = "WIZARD_LOADER_FALSE";
export const WIZARD_CLEAR = "WIZARD_CLEAR";
export const WIZARD_CURRENT_STEP = "WIZARD_CURRENT_STEP";
export const ISPREVIEW = "ISPREVIEW";
export const CREATE_REPO = "CREATE_REPO";
export const LOAD_DRAFT_COURSES = "LOAD_DRAFT_COURSES";
export const WIZARD_STEP_THREE = "WIZARD_STEP_THREE";
export const CLEAR_DRAFT_COURSES = "CLEAR_DRAFT_COURSES";
export const ACCEPT_IS_CHECKED = "ACCEPTED_IS_CHECKED";
export const LOAD_OWNED_COURSES = "LOAD_OWNED_COURSES";
export const CLEAR_OWNED_COURSES = "CLEAR_OWNED_COURSES";
export const CREATE_REPO_LOADING = "CREATE_REPO_LOADING";
export const RESPOSITORY_STEP_ERROR_BANNER = "RESPOSITORY_STEP_ERROR_BANNER";
export const ISCANCELLED = "ISCANCELLED";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const SELECTED_USER = "SELECTED_USER";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
export const LOAD_CANDIDATES_ENROLLED = "LOAD_CANDIDATES_ENROLLED";
export const LOAD_AVG_COMPLETION_TIME = "LOAD_AVG_COMPLETION_TIME";
export const CLEAR_FILTER_VALUES = "CLEAR_FILTER_VALUES";
export const SET_JOB_SELECTED = "SET_JOB_SELECTED";
export const GET_ALL_QUESTION_BANK_QUESTION_LIST =
  "GET_ALL_QUESTION_BANK_QUESTION_LIST";
export const QUESTION_BANK_QUESTION_LIST = "QUESTION_BANK_QUESTION_LIST";
export const EDIT_QUESTION_QB = "EDIT_QUESTION_QB";
export const SET_JOB_ACTION = "SET_JOB_ACTION";
export const GEN_AI_ALL_QUESTION_LIST_ID = "GEN_AI_ALL_QUESTION_LIST_ID";
export const ASK_AI_LIST_ID = "ASK_AI_LIST_ID";
export const GET_GENERAL_SETTINGS = "GET_GENERAL_SETTINGS";
export const POST_GENERAL_SETTINGS = "POST_GENERAL_SETTINGS";

export const SET_COURSE_SUMMARY_VALUES = "SET_COURSE_SUMMARY_VALUES";
export const SET_BELL_CURVE_VALUES = "SET_BELL_CURVE_VALUES";
export const CLEAR_GRAPH_VALUES = "CLEAR_GRAPH_VALUES";
export const DELETE_QUESTIONS = "DELETE_QUESTIONS";
export const GET_QUESTION = "GET_QUESTION";
export const START_TEST = "START_TEST";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const CURRENT_MCQ_INDEX = "CURRENT_MCQ_INDEX";
export const GET_QUESTION_BANK_QUESTION_LIST = "QUESTION_BANK_QUESTION_LIST";
export const PDF_SUBMITTED = "PDF_SUBMITTED";
export const ADD_TO_QUESTIONBANK = "ADD_TO_QUESTIONBANK";
export const REMOVE_QUESTION_FROM_MCQ = "REMOVE_QUESTION_FROM_MCQ";
export const QUESTION_BANK_LIST_ID = "QUESTION_BANK_LIST_ID";
export const GET_MCQ_QUESTION_BANK_QUESTION_LIST =
  "GET_MCQ_QUESTION_BANK_QUESTION_LIST";
export const SAVED_EMAIL_SETTING = "SAVED_EMAIL_SETTING";
export const SAVED_AUTH_GOOGLE_SETTING = "SAVED_AUTH_GOOGLE_SETTING";
export const SAVED_AUTH_LDAP_SETTING = "SAVED_AUTH_LDAP_SETTING";
export const SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING =
  "SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING";
