import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import "../LoginPage/LdapContainer.scss";
import { Button } from "antd";
import { useEffect, useState } from "react";
import {
  validateForgotPasswordemail,
  validateOtpForgotPassword,
} from "../../store/actions/authActions";
import StyledOTPFeild from "../SignupPage/StyledOTPFeild";
import { ForgotPasswordSchema } from "./ForgotPasswordUtils";

const ForgotPassWordContainer = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showOtpFeild, setShowOtpFeild] = useState(false);
  const [isTimerActive, setTimerActive] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(15);

  const handleError = () => setSubmitting(false);

  useEffect(() => {
    if (isTimerActive && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      setTimerActive(false);
      setCountdown(15);
    }
  }, [isTimerActive, countdown]);

  return (
    <Formik
      initialValues={{
        email: "",
        otp: "",
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values) => {
        if (!showOtpFeild && values.email) {
          dispatch(
            validateForgotPasswordemail(
              values,
              handleError,
              setSubmitting,
              setShowOtpFeild
            )
          );
        } else {
          dispatch(
            validateOtpForgotPassword(values, handleError, setSubmitting)
          );
        }
        setSubmitting(true);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
        resetForm,
      }) => (
        <Form className="auth-form">
          <Field
            disabled={showOtpFeild}
            label="Email"
            type="text"
            name="email"
            component={StyledInputField}
            placeholder="Enter Your Email Id"
            onChange={(e) => {
              setFieldTouched("email", true, true);
              setFieldValue("email", e.target.value);
            }}
          />
          {showOtpFeild && (
            <div className="otp-container">
              <Field
                label={showOtpFeild && "OTP"}
                type="otp"
                name="otp"
                component={StyledOTPFeild}
              />
              <div className="otp-footer">
                <div className="otp-detail">
                  Paste the code sent to your email
                </div>

                {!isTimerActive ? (
                  <Button
                    className="resend-otp-button"
                    loading={isResending}
                    onClick={() => {
                      setFieldValue("otp", "");
                      setIsResending(true);
                      dispatch(
                        validateForgotPasswordemail(
                          values,
                          handleError,
                          setIsResending,
                          setTimerActive
                        )
                      );
                    }}
                    disabled={isTimerActive}
                  >
                    Resend
                  </Button>
                ) : (
                  <span className="otp-detail">{`Resend OTP in ${countdown} seconds`}</span>
                )}
              </div>
            </div>
          )}
          <div className="button-container">
            {showOtpFeild && (
              <Button
                size="large"
                block
                htmlType="button"
                className="reset-signupform-button"
                onClick={() => {
                  resetForm();
                  setShowOtpFeild(false);
                }}
              >
                Reset
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              block
              loading={isSubmitting}
              htmlType="submit"
              disabled={
                !isValid || !dirty || (showOtpFeild && values.otp.length !== 6)
              }
              className="sign-in-button"
            >
              {showOtpFeild ? "Verify OTP" : "Get OTP"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassWordContainer;
