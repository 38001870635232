import { generateAccordianStyle, pipelineStatusIcon } from "../../libs/utils";
import { useDispatch, useSelector } from "react-redux";
import { CURRENT_MODULE } from "../../store/actiontypes";
import { currentModule } from "../../store/selectors/coursesSelectors";
import { ReactComponent as Preview } from "../../assets/previeweye.svg";

const ModuleSection = (props) => {
  const { modules, isEnrolled, isPreview } = props;
  const dispatch = useDispatch();
  const currentModules = useSelector(currentModule);
  const onclickhandler = (ele) => {
    dispatch({
      type: CURRENT_MODULE,
      payload: ele,
    });
  };

  return (
    <>
      {modules.map((element, index) => {
        return (
          <div
            key={index}
            className={
              isEnrolled || isPreview
                ? generateAccordianStyle(
                    element,
                    currentModules,
                    isPreview,
                    isEnrolled
                  )
                : "accordian-unenrolled-style"
            }
            data-testid={`module-section-${index}`}
            onClick={() => onclickhandler(element)}
          >
            <p
              className={
                isEnrolled || isPreview
                  ? "enrolled-title"
                  : "header" +
                    " " +
                    (isEnrolled ? "enrolled-color" : "not-enrolled-color")
              }
            >
              {isEnrolled ? (
                <div>{element.module?.module_name}</div>
              ) : (
                <div data-testid="module-title">{element.module_name}</div>
              )}
              {isPreview ? (
                <div data-testid="preview">
                  <Preview />
                </div>
              ) : isEnrolled ? (
                <div data-testid="pipeline">
                  {pipelineStatusIcon(element.module_progress)}
                </div>
              ) : null}
            </p>
            <p
              className={
                isEnrolled || isPreview
                  ? "enrolled-subtitle"
                  : "subtitle" +
                    " " +
                    (isEnrolled
                      ? "enrolled-color"
                      : "not-enrolled-color-subtitle")
              }
              data-testid="module-description"
            >
              {isEnrolled ? (
                <>{element.module?.module_description}</>
              ) : (
                <>{element.module_description}</>
              )}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default ModuleSection;
