const EmailSettingHeaderSection = ({ title, desc }) => {
  return (
    <div
      className={`email-header-section${
        title === "Authentication" ? "-lower" : ""
      }`}
    >
      <h3 className="email-header-title">{title}</h3>
      <span className="email-header-desc">{desc}</span>
    </div>
  );
};

export default EmailSettingHeaderSection;
