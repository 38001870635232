import OTPInput from "react-otp-input";
import "./StyledOTPFeild.scss";
// eslint-disable-next-line no-unused-vars
const StyledOTPFeild = ({ field, form, type, ...props }) => {
  const { label } = props;
  const { values, setFieldValue } = form;
  return (
    <div className="OtpFeild">
      <div className="Otplabel">{label}</div>
      <OTPInput
        value={values.otp}
        onChange={(OTP) => {
          setFieldValue("otp", OTP);
        }}
        renderSeparator={<span className="Otpseperator"></span>}
        inputStyle={{
          width: "2.7rem",
          height: "3.7rem",
          fontSize: "18px",
          borderRadius: "4px",
          border: "1.5px solid #000",
        }}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        {...props}
      />
    </div>
  );
};

export default StyledOTPFeild;
