import { useDispatch } from "react-redux";
import { passwordSchema } from "./SetPasswordUtils";
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { Form } from "formik";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import { Button } from "antd";
import { setTheUserPassword } from "../../store/actions/authActions";
import {
  groupArraySelector,
  isLoggedInSelector,
  userEmailSelector,
} from "../../store/selectors/authSelectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InfoCircleFilled } from "@ant-design/icons";
import { passwordRules } from "../../constants";

const SetPassword = ({ resetPassword, userDataSignUp }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const handleError = () => {
    setSubmitting(false);
  };
  const userMail = useSelector(userEmailSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const groupsArr = useSelector(groupArraySelector);

  const isUserInHrOrAdminGroup = () => {
    return groupsArr.includes("HR") || groupsArr.includes("PORTAL_ADMIN");
  };
  const redirectToHome = () => {
    if (isLoggedIn && isUserInHrOrAdminGroup()) {
      navigate("/courses", { replace: true });
    } else if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    redirectToHome();
    return () => {};
  }, [isLoggedIn]);
  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={passwordSchema}
      onSubmit={(values) => {
        const { password } = values;
        const userData = {
          password: password,
          email:
            userDataSignUp && userDataSignUp.email
              ? userDataSignUp.email
              : userMail,
          id: id,
        };

        dispatch(
          setTheUserPassword(
            userData,
            resetPassword,
            handleError,
            setSubmitting,
            navigate
          )
        );
        setSubmitting(true);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
        resetForm,
        dirty,
      }) => (
        <Form className="auth-form">
          <Field
            label="Password"
            type="password"
            name="password"
            component={StyledInputField}
            placeholder="Enter Password"
            onChange={(e) => {
              setFieldTouched("password", true, true);
              setFieldValue("password", e.target.value);
            }}
          />
          <Field
            label="Confirm Password"
            type="password"
            name="confirmPassword"
            component={StyledInputField}
            placeholder="Confirm Password"
            onChange={(e) => {
              setFieldTouched("confirmPassword", true, true);
              setFieldValue("confirmPassword", e.target.value);
            }}
          />
          <div className="password-error-info-container">
            <InfoCircleFilled className="password-error-info-icon" />
            <span className="password-error-info">{passwordRules}</span>
          </div>
          <div className="button-container">
            <Button
              size="large"
              block
              htmlType="button"
              className="reset-signupform-button"
              onClick={() => {
                resetForm();
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="large"
              block="true"
              loading={isSubmitting}
              htmlType="submit"
              disabled={
                !isValid ||
                !dirty ||
                !values.password ||
                !values.confirmPassword ||
                values.password !== values.confirmPassword
              }
              className="sign-in-button"
            >
              {resetPassword ? "Set Password" : "Sign Up"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetPassword;
