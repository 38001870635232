import EnrolledTabScreenTableList from "./EnrollTabScreenTableList";
import "./EnrolledTabScreen.scss";
function EnrolledTabScreenTable({
  data,
  unEnrollClickhandler,
  showGraphModal,
}) {
  return (
    <>
      <div className="enrolledTable-container">
        <div className="table-title-container">
          <div className="table-title">Course Name</div>
          <div className="table-title">Status</div>
          <div className="table-title">Best Score</div> {/* New Column */}
          <div className="table-title">Attempts Taken</div> {/* New Column */}
          <div className="table-title">Enrolled On</div>
          <div className="table-title">Time Taken</div>
          <div className="table-title">Actions</div>
        </div>
        <EnrolledTabScreenTableList
          data={data}
          unEnrollClickhandler={unEnrollClickhandler}
          showGraphModal={showGraphModal}
        />
      </div>
    </>
  );
}
export default EnrolledTabScreenTable;
