import { replace } from "lodash";
import constants from "../constants";
import { ReactComponent as Approved } from "../assets/Approved.svg";
import { ReactComponent as NotApproved } from "../assets/notApproved.svg";
import { ReactComponent as FailedStatus } from "../assets/failedstatus.svg";
import { ReactComponent as PassedStatus } from "../assets/passedstatus.svg";
import { ReactComponent as ProgressStatus } from "../assets/progressstatus.svg";
import { ReactComponent as WaitingForApproval } from "../assets/waitingForApproval.svg";
import { ReactComponent as ModuleCompleted } from "../assets/check-circle.svg";
import { ReactComponent as LockStatus } from "../assets/lockStatus.svg";
import { ReactComponent as InProgressIcon } from "../assets/in_progress.svg";
import { ReactComponent as Preview } from "../assets/previeweye.svg";
import { ReactComponent as PassedWithTextStatus } from "../assets/Passed_Text.svg";
import { ReactComponent as FailedWithTextStatus } from "../assets/Failed_Text.svg";
import { ReactComponent as RunningWithTextStatus } from "../assets/Running_Text.svg";
import moment from "moment";

export const getBranchName = (employeeId, module_name) => {
  const modifiedModuleName = replace(module_name, /\s+/g, "_").toLowerCase();
  return `${modifiedModuleName}_assignment_${employeeId}`;
};
export const pipelineStatusIcon = (status, text = false) => {
  switch (status) {
    case constants.PIPELINE_STATUS.passed:
      return text ? <PassedWithTextStatus /> : <PassedStatus />;
    case constants.PIPELINE_STATUS.failed:
      return text ? <FailedWithTextStatus /> : <FailedStatus />;
    case constants.PIPELINE_STATUS.running:
      return text ? <RunningWithTextStatus /> : <ProgressStatus />;
    case constants.PIPELINE_STATUS.approved:
      return <Approved title="Approved" />;
    case constants.PIPELINE_STATUS.notaprroved:
      return <NotApproved title="NotApproved" />;
    case constants.PIPELINE_STATUS.waitingforapproval:
      return <WaitingForApproval title="WaitingForApproval" />;
    case constants.PIPELINE_STATUS.COMPLETED:
      return <ModuleCompleted title="ModuleCompleted" />;
    case constants.PIPELINE_STATUS.LOCKED:
      return <LockStatus title="LockStatus" />;
    case constants.PIPELINE_STATUS.IN_PROGRESS:
      return <InProgressIcon title="InProgressIcon" />;
    case constants.PIPELINE_STATUS.VIEW:
      return <Preview title="Preview" />;
    default:
      return <ProgressStatus />;
  }
};

export const generateAccordianStyle = (
  element,
  currentModules,
  isPreview,
  isEnrolled
) => {
  switch (true) {
    case !isPreview && element.module_progress === "LOCKED":
      return "accordian-style-lock";
    case currentModules?.module?.id === element?.module?.id && !isPreview:
      return "accordian-style-in-progress";
    case isEnrolled &&
      isPreview &&
      currentModules?.module?.id !== element?.module?.id:
      return "accordian-style";
    case isEnrolled &&
      isPreview &&
      currentModules?.module?.id === element?.module?.id:
      return "accordian-style-in-progress";
    case isPreview && currentModules?.id === element?.id && !isEnrolled:
      return "accordian-style-in-progress";
    default:
      return "accordian-style";
  }
};

export const generateAccordianStyleForTest = (
  element,
  currentModules,
  isPreview,
  isEnrolled
) => {
  switch (true) {
    case !isPreview && element?.mcq_progress === "LOCKED":
      return "accordian-style-lock";
    case currentModules?.mcq?.id === element?.mcq?.id && !isPreview:
      return "accordian-style-in-progress";
    case isEnrolled &&
      isPreview &&
      currentModules?.mcq?.id !== element?.mcq?.id:
      return "accordian-style";
    case isEnrolled &&
      isPreview &&
      currentModules?.mcq?.id === element?.mcq?.id:
      return "accordian-style-in-progress";
    case isPreview && currentModules?.id === element?.id && !isEnrolled:
      return "accordian-style-in-progress";
    default:
      return "accordian-style";
  }
};

export const generateArgumanets = (isPreview, isEnrolled, data) => {
  switch (true) {
    case isPreview && isEnrolled:
      return data.module;
    case !isPreview && isEnrolled:
      return data.module;
    case !isEnrolled && isPreview:
      return data;

    default:
      return data;
  }
};

export const convertDurationToMMSSMS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const milliseconds = (seconds - Math.floor(seconds)) * 1000;

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")}:${milliseconds.toString().padStart(2, "0")}`;
};

export const formatRelativeTime = (updatedAt) => {
  const formatted = moment(updatedAt).fromNow();
  return formatted;
};

export const mapOperatorValuesToText = (operator) => {
  switch (operator) {
    case "=":
      return "is";
    case "!=":
      return "is not";
    case "<":
      return "is less";
    case "<=":
      return "is less & equal";
    case ">":
      return "is more";
    case ">=":
      return "is more & equal";
    default:
      return null;
  }
};

export function formatDate(inputDateStr) {
  const inputMoment = moment(inputDateStr);
  const formattedDate = inputMoment.format("MMM D, YYYY, h:mm:ss A");
  return formattedDate;
}

export function transformCourseSummaryData(data) {
  const labels = [];
  const estimated_time = [];
  const completed_time = [];
  const avg_time = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if (item.completed_at !== null) {
      completed_time.push(item.completed_in);
    }

    labels.push(item.module_name);
    estimated_time.push(item.estimated_time);
    avg_time.push(item.avg_completion_time);
  }

  return {
    labels: labels,
    estimated_time: estimated_time,
    completed_time: completed_time,
    avg_time: avg_time,
  };
}
export function filterByLastMonth(data) {
  const oneMonthAgo = moment().subtract(1, "month");

  const lastMonthData = data.filter(
    (item) => new Date(item.created_at) >= oneMonthAgo
  );

  return lastMonthData;
}

export const isTenantMode = () => {
  const hostname = window.location.hostname;
  const domainUrl = process.env.REACT_APP_DOMAIN_URL;
  return hostname !== domainUrl;
};
