import * as Yup from "yup";

export const ForgotPasswordSchema = Yup.object({
  email: Yup.string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid Email Address"
    )
    .required("Required"),
  otp: Yup.string().when("showOtpFeild", {
    is: true,
    then: Yup.string()
      .length(6, "OTP must be 6 digits")
      .required("OTP is required"),
  }),
});
