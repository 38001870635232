import { useDispatch } from "react-redux";
import ResultsContainer from "./ResultsContainer";
import "./TestContent.scss";
import { Link } from "react-router-dom";
import { isNull } from "lodash";

import { Button } from "antd";
import { useEffect, useState } from "react";
import { getCandidateTestResult } from "../../store/actions/courseAction";
import DisplayInfoMessage from "../DisplayInfoMessage/DisplayInfoMessage";
import { useSelector } from "react-redux";
import { isPreviewSelector } from "../../store/selectors/wizardSelectors";
import { selectedCourseSelector } from "../../store/selectors/coursesSelectors";
const TestContent = ({ mcqdetails, enrolledId }) => {
  const dispatch = useDispatch();
  const [testResultData, setTestResultData] = useState(null);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const isPreview = useSelector(isPreviewSelector);
  const course = useSelector(selectedCourseSelector);
  useEffect(() => {
    if (mcqdetails?.mcq?.id) {
      setIsResultLoading(true);
      dispatch(
        getCandidateTestResult(
          mcqdetails.mcq.id,
          setTestResultData,
          setIsResultLoading
        )
      );
    }
  }, [mcqdetails]);
  const checkStartButton = () => {
    return (
      !testResultData ||
      (testResultData && isNull(testResultData?.max_attempts)) ||
      (testResultData &&
        !isNull(testResultData?.max_attempts) &&
        testResultData?.user_total_attempts < testResultData?.max_attempts)
    );
  };
  const showAttemptError = () => {
    return (
      testResultData &&
      !isNull(testResultData?.max_attempts) &&
      testResultData?.user_total_attempts >= testResultData?.max_attempts
    );
  };
  return (
    <div className="test-container">
      {showAttemptError() ? (
        <DisplayInfoMessage
          msg="Max Number of Test Attempts Reached."
          type="warning"
          className="info-max-msg"
        />
      ) : null}
      <div className="about-test-header">
        <div className="about-module-module-name">
          {mcqdetails?.mcq?.test_name}
        </div>
        <div className="number-of-questions">
          {mcqdetails?.total_questions}{" "}
          <span className="number-of-questions-span">Questions</span>
        </div>
      </div>
      <div className="about-module-subtitle">
        {mcqdetails?.mcq?.test_description}
      </div>
      <div className="test-startbutton-container">
        {checkStartButton() ? (
          <Link
            to={
              isPreview
                ? `/courses/${course[0].course.id}/test/${mcqdetails.mcq.id}/preview`
                : `/tests/${mcqdetails?.mcq?.id}/start/${enrolledId}`
            }
            target="_blank"
          >
            <Button className="test-start-button">
              {testResultData?.user_total_attempts > 0 ? "Restart" : "Start"}
            </Button>
          </Link>
        ) : null}
      </div>
      <div className="test-results-container">
        <ResultsContainer
          testResult={testResultData}
          isResultLoading={isResultLoading}
        />
      </div>
    </div>
  );
};

export default TestContent;
