import { useDispatch } from "react-redux";
import "../LoginPage/LoginPage.scss";
import SetPassword from "./SetPassword";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import { validateOtp } from "../../store/actions/authActions";
import LogoAndInfoSection from "../LoginPage/LogoAndInfoSection";

const SetPasswordContainer = ({ userData }) => {
  const resetPassword = window.location.href.includes("/reset-password");

  return (
    <div className="login-container">
      <div
        className={`sign-header  ""
        }`}
      >
        <img
          className="login-image"
          src={require("../../assets/learning.png")}
        />

        <div className="login-titles">Set Password</div>
        <div className="login-subtitle">
          {resetPassword
            ? "Create a new password here"
            : "Create Password to Sign In to EigenLearn"}
        </div>
      </div>
      <>
        <SetPassword resetPassword={resetPassword} userDataSignUp={userData} />
      </>
    </div>
  );
};

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const isOtp = window.location.href.includes("&otp=");
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const handleError = () => setSubmitting(false);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const name = queryParams.get("name");
  const otp = queryParams.get("otp");
  useEffect(() => {
    if (isOtp) {
      dispatch(
        validateOtp(
          { name: name, email: email, otp: otp },
          handleError,
          setSubmitting,
          navigate
        )
      );
    }
  }, [isOtp]);

  return (
    <>
      {isOtp && !isSubmitting ? (
        <div className="passwordPage-spinner">
          <Spinner text={"Please wait, Verification in progress"} />
        </div>
      ) : (
        <div data-testid="section" className="section">
          <div className="loginPage-container">
            <LogoAndInfoSection />
            <SetPasswordContainer userData={[name, email, otp]} />
          </div>
        </div>
      )}
    </>
  );
};

export default SetPasswordPage;
