import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { GoogleSettingUtils } from "../../AdminSettingsUtils";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import CopyTooltip from "../../../../components/CopyTooltip/CopyTooltip";
import { Button } from "antd";
import {
  GOOGLE_AUTH_INFO_HYPERLINKS,
  GOOGLE_AUTH_INFO_MESSAGE,
} from "../../../../constants";
import { useEffect } from "react";

import { postAuthGoogleSetting } from "../../../../store/actions/adminActions";
import withPrompt from "../../../../hooks/withPrompt";
import { setSaveButtonDisabled } from "../../../../store/actions/adminActions";
import { isSaveButtonDisabledSelector } from "../../../../store/selectors/adminSelectors";
import { useSelector } from "react-redux";

const GoogleSettingFormSection = ({ currentGoogleSetting, isGoogleAuth }) => {
  const [saveButtonLoader, setSaveButtonLoader] = useState(false); // loader for save button
  const dispatch = useDispatch();
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);

  const INITIAL_FORM_VALUES = {
    client_id: currentGoogleSetting?.client_id || "",
    secret_key: currentGoogleSetting?.secret_key || "",
    origin_url: currentGoogleSetting?.origin_url || "",
    callback_url: currentGoogleSetting?.callback_url || "",
  };
  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  useEffect(() => {
    setInitialValues(INITIAL_FORM_VALUES);
    setFormValues(INITIAL_FORM_VALUES);
  }, [currentGoogleSetting]);

  useEffect(() => {
    const isChanged =
      formValues.client_id !== initialValues.client_id ||
      formValues.secret_key !== initialValues.secret_key ||
      formValues.origin_url !== initialValues.origin_url ||
      formValues.callback_url !== initialValues.callback_url;

    dispatch(setSaveButtonDisabled(!isChanged));
  }, [formValues, initialValues, dispatch]);

  const handleFormChange = (values) => {
    setFormValues(values);
  };
  const onSubmit = (values) => {
    setSaveButtonLoader(true);
    delete values.allow_google_login;
    const newData = {
      allow_google_login: isGoogleAuth ? "YES" : "NO",
      ...values,
    };
    dispatch(postAuthGoogleSetting(newData, () => {}, setSaveButtonLoader));
  };

  return (
    <Formik
      fluid
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={GoogleSettingUtils}
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      isInitialValid={false}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="form"
          data-testid="form-div"
          onChange={() => handleFormChange(values)}
        >
          <div className="googlesetting-form-container">
            <div>
              <h4
                className={`google-configuration-heading${
                  !isGoogleAuth ? "-disabled" : ""
                }`}
              >
                Configuration
              </h4>
              <div className="google-upper-setting-feilds">
                <div className="google-feild-block">
                  <Field
                    disabled={!isGoogleAuth}
                    label={
                      <span style={{ color: !isGoogleAuth ? "#B5B5B5" : "" }}>
                        Client ID
                      </span>
                    }
                    id="client_id"
                    name="client_id"
                    type="text"
                    className="admin-setting-option"
                    onChange={(event) => {
                      setFieldValue("client_id", event.target.value);
                      handleFormChange({
                        ...values,
                        client_id: event.target.value,
                      });
                    }}
                    component={StyledInputField}
                    placeholder="Enter client ID"
                    isToggle={isGoogleAuth}
                  />
                  <span
                    className={`google-field-desc${
                      !isGoogleAuth ? "-disabled" : ""
                    }`}
                  >
                    {GOOGLE_AUTH_INFO_MESSAGE?.CLIENT_ID_MESSAGE}
                    <a
                      href={GOOGLE_AUTH_INFO_HYPERLINKS?.CLIENT_ID_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                </div>
                <div className="google-clientsecret-block">
                  <Field
                    disabled={!isGoogleAuth}
                    label={
                      <span style={{ color: !isGoogleAuth ? "#B5B5B5" : "" }}>
                        Client secret
                      </span>
                    }
                    type="password"
                    name="secret_key"
                    component={StyledInputField}
                    placeholder="Enter client secret"
                    className="admin-setting-option"
                    onChange={(event) => {
                      setFieldValue("secret_key", event.target.value);
                      handleFormChange({
                        ...values,
                        secret_key: event.target.value,
                      });
                    }}
                    isToggle={isGoogleAuth}
                  />
                  <span
                    className={`google-clientsecret-desc${
                      !isGoogleAuth ? "-disabled" : ""
                    }`}
                  >
                    {GOOGLE_AUTH_INFO_MESSAGE?.CLIENT_SECRET_MESSAGE}
                    <a
                      href={GOOGLE_AUTH_INFO_HYPERLINKS?.CLIENT_SECRET_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn more
                    </a>
                  </span>
                </div>
              </div>
              <h4
                className={`google-serviseprovider-heading${
                  !isGoogleAuth ? "-disabled" : ""
                }`}
              >
                Service provider details
              </h4>
              <div className="google-lower-setting-feilds">
                <div className="google-feild-block">
                  <div className="callBackandOriginUri-container">
                    <label
                      className={`callBackandOriginUri-label${
                        !isGoogleAuth ? "-disabled" : ""
                      }`}
                    >
                      Origin URL
                    </label>
                    <div
                      className={`tooltipandspan-conatiner${
                        !isGoogleAuth ? "-disabled" : ""
                      }`}
                    >
                      <span
                        id="origin_url"
                        name="origin_url"
                        className="originurl-text"
                      >
                        {currentGoogleSetting?.origin_url}
                      </span>
                      <CopyTooltip
                        sectionName={"origin_url"}
                        isGoogleAuth={isGoogleAuth}
                      />
                    </div>
                  </div>
                  <span
                    className={`google-field-desc${
                      !isGoogleAuth ? "-disabled" : ""
                    }`}
                  >
                    {GOOGLE_AUTH_INFO_MESSAGE?.ORIGIN_URI_MESSAGE}{" "}
                    <a
                      href={GOOGLE_AUTH_INFO_HYPERLINKS?.ORIGIN_URI_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here.
                    </a>
                  </span>
                </div>
                <div className="google-feild-block">
                  <div className="callBackandOriginUri-container">
                    <label
                      className={`callBackandOriginUri-label${
                        !isGoogleAuth ? "-disabled" : ""
                      }`}
                    >
                      Callback URI
                    </label>
                    <div
                      className={`tooltipandspan-conatiner${
                        !isGoogleAuth ? "-disabled" : ""
                      }`}
                    >
                      <span
                        id="callback_url"
                        name="callback_url"
                        className="callback_url-text"
                      >
                        {currentGoogleSetting?.callback_url}
                      </span>
                      <CopyTooltip
                        sectionName={"callback_url"}
                        isGoogleAuth={isGoogleAuth}
                      />
                    </div>
                  </div>
                  <span
                    className={`google-field-desc${
                      !isGoogleAuth ? "-disabled" : ""
                    }`}
                  >
                    {GOOGLE_AUTH_INFO_MESSAGE?.CALLBACK_URI_MESSAGE}{" "}
                    <a
                      href={GOOGLE_AUTH_INFO_HYPERLINKS?.CALLBACK_URI_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here.
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="google-settings-button">
              <Button
                className="google-settings-savebutton"
                loading={saveButtonLoader}
                disabled={isSaveButtonDisabled}
                onClick={() => onSubmit(values)}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default withPrompt(GoogleSettingFormSection);
