import { useState } from "react";
import "./FilterComponent.scss"; // Import the SCSS file for styling

const FilterComponent = ({ filters, onChange }) => {
  const [selectedFilters, setSelectedFilters] = useState([]); // State to keep track of selected filters

  // Function to handle filter button clicks
  const handleFilterClick = (id) => {
    const index = selectedFilters.indexOf(id);
    let updatedFilters;
    if (index === -1) {
      updatedFilters = [...selectedFilters, id];
      setSelectedFilters(updatedFilters); // If filter is not selected, add it to the list
    } else {
      updatedFilters = [...selectedFilters];
      updatedFilters.splice(index, 1); // If filter is selected, remove it from the list
      setSelectedFilters(updatedFilters);
    }
    onChange(updatedFilters); // Call the onChange callback with the currently selected filters
  };

  return (
    <div className="filter-container">
      {" "}
      {/* Container for filter buttons */}
      {filters.map((filter) => (
        <button
          key={filter.id}
          className={`filter-button ${
            selectedFilters.includes(filter.id) ? "selected" : ""
          }`} // Apply 'selected' class if filter is selected
          onClick={() => handleFilterClick(filter.id)} // Attach click handler
        >
          {filter.value} {/* Display filter value */}
        </button>
      ))}
    </div>
  );
};

export default FilterComponent;
