export const questionFormOptions = [
  {
    value: "Single",
    label: "Single choice",
  },
  {
    value: "Multiple",
    label: "Multiple choice",
  },
  {
    value: "Subjective",
    label: "Subjective",
  },
];

export const quillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
  ],

  clipboard: {
    matchVisual: false,
  },
};

export const quillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const adminSettingConstants = {
  PASSWORD_TEXT: "Allow users to sign in with passwords.",
  GOOGLE_TEXT: "Allow members to login or signup using google accounts.",
  LDAP_TEXT: "Allow members to login or signup using LDAP accounts.",

  GENERAL_SETTINGS:
    " Change the name of your instance, instance admin e-mail address and customize your branding.",
  CUSTOMIZE_BRANDING:
    "Upload your logo to replace the default branding and make the platform your own. Recommended size: [512px x 512px], Maximum file size: [2 MB], Supported formats: PNG, JPG.",
};
