import StyledModal from "../../../../components/StyledModal/StyledModal";
import "./AskAIEmptyContainer.scss";
import { ReactComponent as GradientAiRobotIcon } from "../../../../assets/Graident Ai Robot 1.svg";
import { SubmittedPdfMessage } from "../../../../constants";
const SubmitPdfConfirmModal = ({ open, handleClose, text }) => {
  const handleOk = () => {
    handleClose();
  };
  return (
    <StyledModal
      className={"submit-pdf-modal"}
      centred
      title={"Hurray!"}
      visible={open}
      cancelButtonProps={{
        style: { display: "none" },
      }}
      onOk={handleOk}
      closable={false}
    >
      <div
        style={{
          marginTop: "54px",
          marginBottom: "60px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <div className="submit-pdf-content">
          <GradientAiRobotIcon className="gradientAIRobot" />
          <span className="submit-pdf-text">{SubmittedPdfMessage(text)}</span>
        </div>
      </div>
    </StyledModal>
  );
};

export default SubmitPdfConfirmModal;
