import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Tag } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { REVIEWED } from "../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { EDIT_QUESTION_QB } from "../../../../store/actiontypes";
import { useEffect, useState } from "react";
import {
  addQuestionToMCQ,
  removeQuestionMCQ,
} from "../../../../store/actions/wizardActions";
import QuestionBankButton from "./QuestionBankButton";

export const QuestionListItemHeader = ({
  mcqId = "",
  header,
  onDelete,
  isQuestionReviewList = false,
  fromQuestionBank = false,
  AddToQuestionBank,
  isHovered,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const items = [
    isQuestionReviewList && {
      label: (
        <span className="question-icon-div">
          <FontAwesomeIcon icon={faBookOpen} className="question-book-icons" />
          Add to Question Bank
        </span>
      ),
      key: "Add_to_Question_Bank",
    },
    {
      label: (
        <span className="question-icon-div">
          <EditOutlined className="question-icons" />
          Edit
        </span>
      ),
      key: "Edit",
      disabled: header.isOwnerOfQuestion !== null && !header.isOwnerOfQuestion,
    },
    {
      label: (
        <span className="question-icon-div">
          <DeleteOutlined className="question-icons" />
          Delete
        </span>
      ),
      key: "Delete",
      disabled: header.isOwnerOfQuestion !== null && !header.isOwnerOfQuestion,
    },
  ];
  const [buttonType, setButtonType] = useState("");
  useEffect(() => {
    if (!header.addedInMcq) {
      setButtonType("Add To Test");
    } else if (header.addedInMcq && !isHovered) {
      setButtonType("Added");
    } else if (header.addedInMcq && isHovered) {
      setButtonType("Remove");
    }
  }, [header.addedInMcq, isHovered]);
  const onEditHandleClick = (mcqDetail) => {
    if (!isQuestionReviewList && !fromQuestionBank) {
      navigate(
        `/courses/${location.pathname.split("/")[2]}/test/${
          mcqId.id
        }/manager?id=${header.question_id}`
      );
    } else {
      dispatch({ type: EDIT_QUESTION_QB, payload: mcqDetail });
      navigate("/edit-question");
    }
  };
  const handleQuestionBankButtonClick = (e) => {
    e.stopPropagation();
    if (buttonType === "Add To Test") {
      dispatch(addQuestionToMCQ(mcqId.id, [header.question_id]));
    } else if (buttonType === "Remove") {
      dispatch(removeQuestionMCQ(mcqId.id, [header.question_id]));
    }
  };
  const menuProps = {
    items,
    onClick: (e) => {
      if (e.key === "Delete") onDelete([header.question_id]);
      if (e.key === "Edit") onEditHandleClick(header);
      if (e.key === "Add_to_Question_Bank") {
        AddToQuestionBank([header.question_id], REVIEWED);
      }
    },
  };
  return (
    <div className="questionListitem-header">
      <div>
        <span className="question-number">Q {header.order_no}</span>
      </div>
      <div>
        {header.status === "REVIEWED" &&
          isQuestionReviewList &&
          !location.pathname.includes("question-bank") && (
            <Tag className="questionList-tag-reviewed">Reviewed</Tag>
          )}
        {header.addedInMcq && location.search.includes("questionbank=true") ? (
          <QuestionBankButton
            buttonType={isHovered ? "Remove" : "Added"}
            onClick={handleQuestionBankButtonClick}
          />
        ) : isHovered && location.search.includes("questionbank=true") ? (
          <QuestionBankButton
            buttonType={buttonType}
            onClick={handleQuestionBankButtonClick}
          />
        ) : null}
        <span className="question-type-text">Type</span>
        <span className="question-type">{header.question_type} Choice</span>
        <span className="question-list-item-seperator">|</span>
        <span className="question-type-text">Points</span>
        <span className="question-type">{header.points}</span>
        {location.search.includes("questionbank=true") ? null : (
          <span>
            {" "}
            <Dropdown
              placement="bottomRight"
              menu={menuProps}
              trigger={["click"]}
              overlayStyle={
                isQuestionReviewList ? { width: 207 } : { width: 168 }
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <MoreOutlined
                style={{
                  fontSize: "20px",
                  color: "#696969",
                  transform: "rotate(90deg)",
                  paddingLeft: "10px",
                }}
              />
            </Dropdown>
          </span>
        )}
      </div>
    </div>
  );
};
