import AskAIEmptyContainer from "../AskAIEmptyContainer/AskAIEmptyContainer";

const AddAI = () => {
  return (
    <div>
      <AskAIEmptyContainer />
    </div>
  );
};

export default AddAI;
