import { useDispatch } from "react-redux";
import StyledModal from "../../components/StyledModal/StyledModal";
import { archiveMultipleUser } from "../../store/actions/adminActions";
import { useSelector } from "react-redux";
import { USER_LIST_ID } from "../../constants";
import {
  getListLimit,
  getListOffset,
} from "../../store/selectors/listParamsSelectors";

const MultiselectArchiveModal = ({ open, setOpen, userIds, setUserIds }) => {
  const defaultValue = useSelector((state) =>
    getListLimit(state, USER_LIST_ID)
  );
  const searchListParams = useSelector(
    (state) => state.listParams[USER_LIST_ID]
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, USER_LIST_ID)
  );
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const onsuccesscallback = () => {
      setUserIds([]);
      setOpen(false);
    };

    dispatch(
      archiveMultipleUser(
        { users_id: userIds },
        defaultValue,
        offSetValue,
        searchListParams,
        onsuccesscallback
      )
    );
  };

  return (
    <StyledModal
      title={"Please Confirm"}
      centered
      open={open}
      onOk={() => handleSubmit()}
      onCancel={() => setOpen(false)}
      width={"100"}
      closable={false}
    >
      <div style={{ height: "2px", padding: "15px 24px 20px 0px" }}>
        Are you sure you want to archive the selected users ?
      </div>
    </StyledModal>
  );
};

export default MultiselectArchiveModal;
