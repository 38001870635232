import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmailSetting,
  sendTestEmail,
  setSaveButtonDisabled,
} from "../../../store/actions/adminActions";
import { Field, Formik } from "formik";
import { EmailSettingUtils } from "../AdminSettingsUtils";
import { Button, Form } from "antd";
import { StyledInputField } from "../../../components/StyledFields/StyledInputField";
import arrowDown from "../../../assets/arrow-down.svg";
import EmailSettingHeaderSection from "./EmailSettingHeaderSection";
import { EmailSettingPageLowerDescMessage } from "../../../constants";
import { isSaveButtonDisabledSelector } from "../../../store/selectors/adminSelectors";
import withPrompt from "../../../hooks/withPrompt";

const EmailSettingFormSection = ({ currentEmailSetting }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false); // loader for save button
  const [sendTestMailLoader, setSendTestMailLoader] = useState(false); // loader for send test mail button
  const emailSecurityOptions = [
    { label: "SSL", value: "SSL" },
    { label: "TLS", value: "TLS" },
  ];
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);

  // If email setting is present then it will shown else the empty values are assigned here
  const INITIAL_FORM_VALUES = {
    smtp_host: currentEmailSetting?.smtp_host || "",
    smtp_port: currentEmailSetting?.smtp_port || "",
    sender_email: currentEmailSetting?.sender_email || "",
    email_security: currentEmailSetting?.email_security || "SSL",
    email_host_user: currentEmailSetting?.email_host_user || "",
    email_host_password: currentEmailSetting?.email_host_password || "",
  };
  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  useEffect(() => {
    setInitialValues(INITIAL_FORM_VALUES);
    setFormValues(INITIAL_FORM_VALUES);
  }, [currentEmailSetting]);

  useEffect(() => {
    const isChanged = !Object.keys(formValues).every(
      (key) => formValues[key] === initialValues[key]
    );
    dispatch(setSaveButtonDisabled(!isChanged));
  }, [formValues, initialValues, dispatch]);

  const handleFormChange = (values) => {
    setFormValues(values);
  };

  const onSubmit = (values) => {
    setSaveButtonLoader(true);
    setInitialValues(values);
    setFormValues(values);
    dispatch(addEmailSetting(values, setSaveButtonLoader));
  };

  const onsendTestEmail = (values) => {
    setSendTestMailLoader(true);
    dispatch(sendTestEmail(values, setSendTestMailLoader));
  };

  const isSendTestButtonValid = (values) => {
    return !Object.values(values).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  };

  return (
    <Formik
      fluid
      innerRef={ref}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={EmailSettingUtils}
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      isInitialValid={false}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form
          className="emailsetting-form"
          data-testid="form-div"
          onChange={() => handleFormChange(values)}
        >
          <div className="emailSetting-form-comtainer">
            <div>
              <div className="email-upper-setting-feilds">
                <Field
                  label="Host"
                  id="smtp_host"
                  name="smtp_host"
                  type="text"
                  className="admin-setting-option"
                  onChange={(event) => {
                    setFieldValue("smtp_host", event.target.value);
                    handleFormChange({
                      ...values,
                      smtp_host: event.target.value,
                    });
                  }}
                  component={StyledInputField}
                  placeholder="smtp.gmail.com"
                />
                <Field
                  label="Port"
                  id="smtp_port"
                  name="smtp_port"
                  type="text"
                  className="admin-setting-option"
                  onChange={(event) => {
                    setFieldValue("smtp_port", event.target.value);
                    handleFormChange({
                      ...values,
                      smtp_port: event.target.value,
                    });
                  }}
                  component={StyledInputField}
                  placeholder="465"
                />
              </div>
              <div className="email-upper-setting-feilds-row-second">
                <Field
                  label="Sender email address"
                  type="email"
                  name="sender_email"
                  component={StyledInputField}
                  placeholder="Enter Your Email Id"
                  className="admin-setting-option"
                  onChange={(event) => {
                    setFieldValue("sender_email", event.target.value);
                    handleFormChange({
                      ...values,
                      sender_email: event.target.value,
                    });
                  }}
                />
                <Field
                  label="Email Security"
                  id="email_security"
                  name="email_security"
                  type="select"
                  className="admin-setting-option"
                  component={StyledInputField}
                  onChange={(value) =>
                    handleChange({ target: { name: "email_security", value } })
                  }
                  placeholder={emailSecurityOptions[0].value}
                  options={emailSecurityOptions}
                  suffixIcon={<img src={arrowDown} alt="arrow-down" />}
                />
              </div>
              <EmailSettingHeaderSection
                title={"Authentication"}
                desc={EmailSettingPageLowerDescMessage}
              />
              <div className="email-lower-setting-feilds">
                <Field
                  label="Username"
                  type="email"
                  name="email_host_user"
                  component={StyledInputField}
                  placeholder="Enter Your User Name"
                  className="admin-setting-option"
                  onChange={(event) => {
                    setFieldValue("email_host_user", event.target.value);
                    handleFormChange({
                      ...values,
                      email_host_user: event.target.value,
                    });
                  }}
                />
                <Field
                  label="Password"
                  type="password"
                  name="email_host_password"
                  component={StyledInputField}
                  placeholder="Enter Your Password"
                  className="admin-setting-option"
                  onChange={(event) => {
                    setFieldValue("email_host_password", event.target.value);
                    handleFormChange({
                      ...values,
                      email_host_password: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="email-settings-button">
              <Button
                className="email-settings-savebutton"
                disabled={isSaveButtonDisabled}
                loading={saveButtonLoader}
                onClick={() => onSubmit(values)}
              >
                Save
              </Button>
              <Button
                loading={sendTestMailLoader}
                disabled={isSendTestButtonValid(values)}
                className="email-sendttestmailbutton"
                onClick={() => onsendTestEmail(values)}
              >
                Send test email
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

// export default EmailSettingFormSection;
export default withPrompt(EmailSettingFormSection);
