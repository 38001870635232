/* eslint-disable no-unused-vars */
import {
  GOOGLE_CLIENT_ID,
  ISCANCELLED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_MAIL,
} from "../actiontypes/index";

const initialState = {
  isLoggedIn: null,
  user: null,
  isCancel: false,
  clientId: null,
  userMail: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, user: { ...payload } };
    case LOGIN_FAIL:
      return { ...state, user: null };
    case LOGOUT:
      return { ...state, isLoggedIn: false, user: null };
    case ISCANCELLED:
      return { ...state, isCancel: payload };
    case GOOGLE_CLIENT_ID:
      return { ...state, clientId: payload };
    case USER_MAIL:
      return { ...state, userMail: payload };
    default:
      return state;
  }
}
