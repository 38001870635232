import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = () => {
  const location = useLocation();
  return localStorage.getItem("token") === null ? (
    <Navigate to="/login" state={{ from: location }} />
  ) : (
    <Outlet />
  );
};

export default PrivateRoute;
