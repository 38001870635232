import StyledModal from "../../components/StyledModal/StyledModal";
import { useDispatch } from "react-redux";
import { Field, Formik } from "formik";
import { Button, Form } from "antd";
import { useCallback, useState } from "react";
import { updateEmployeeId } from "../../store/actions/adminActions";
import "./AddEmpDetails.scss";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import { updateUserDetailsForm } from "../../constants/validationSchemas";

const AddEmpDetails = (user) => {
  const userDetails = user.user;
  const [isLoading, setIsLoading] = useState(false);
  const [open, setIsOpen] = useState(true);

  const [isFormValid, setIsFormValid] = useState(false);
  const [userBody, setUserBody] = useState({});

  const dispatch = useDispatch();

  const onFormikRefSet = useCallback((ref) => {
    setTimeout(() => {
      if (ref) {
        setIsFormValid(!ref.isValid);
        setUserBody(ref.values);
      }
    }, 0);
  });

  const callbackSuccessUpdate = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const data = {
      ...values,
      gitlab_workspace: `gurukul_workspace_${values.gitlab_username}`,
    };
    dispatch(
      updateEmployeeId(
        userDetails.id,
        data,
        callbackSuccessUpdate,
        setIsLoading
      )
    );
  };

  const INITIAL_FORM_VALUES = {
    emp_id: userDetails.emp_id === "CTE-00000" ? "" : userDetails.emp_id,
    gitlab_username: "",
  };

  return (
    <StyledModal
      title={<div className="employee-details">Student Details</div>}
      centered
      open={open}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => handleSubmit(userBody)}
          loading={isLoading}
          disabled={isFormValid}
          data-testid="submit"
        >
          Submit
        </Button>,
      ]}
      closable={false}
    >
      <div className="modal-container-user" data-testid="add_employee">
        <label className="label-add">Please enter your details.</label>
        <Formik
          fluid
          validateOnChange
          validateOnBlur
          validateOnMount
          innerRef={onFormikRefSet}
          validationSchema={updateUserDetailsForm}
          initialValues={INITIAL_FORM_VALUES}
          enableReinitialize
          isInitialValid={false}
        >
          {({ setFieldTouched, handleChange }) => (
            <Form className="form-style">
              <Field
                label="ID"
                name="emp_id"
                type="text"
                onChange={(event) => {
                  setFieldTouched("emp_id", true, true);
                  handleChange(event);
                }}
                disabled={userDetails.emp_id !== "CTE-00000"}
                component={StyledInputField}
                data-testid="emp_id"
                className="emp_id"
              />
              <Field
                label="Gitlab Username"
                name="gitlab_username"
                type="text"
                onChange={(event) => {
                  setFieldTouched("gitlab_username", true, true);
                  handleChange(event);
                }}
                disabled={false}
                component={StyledInputField}
                data-testid="gitlab_username"
              />
            </Form>
          )}
        </Formik>
      </div>
    </StyledModal>
  );
};

export default AddEmpDetails;
