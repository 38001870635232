import CandidateList from "./CandidateList";
import "./CandidateTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSearchValues } from "../../store/actions/listParamsAction";
import { CANDIDATE_LIST_ID } from "../../constants";
import Spinner from "../Spinner/Spinner";
function CandidateTable({
  topCandidates,
  data,
  unEnrollClickHandler,
  userListLoading,
  setUserListLoading,
}) {
  const [arrowIcons, setArrowIcons] = useState({
    user__user_name: true,
    created_at: true,
    time_taken: true,
    best_score: true,
    no_of_attempts: true,
  });
  const dispatch = useDispatch();
  const [clickedIcon, setClickedIcon] = useState("");
  useEffect(() => {
    setUserListLoading(false);
  }, [data]);
  const orderTheList = (orderingField, isAscending) => {
    dispatch(
      updateSearchValues({
        id: CANDIDATE_LIST_ID,
        ordering: isAscending ? orderingField : `-${orderingField}`,
      })
    );
    setUserListLoading(true);
    setArrowIcons({
      user__user_name: orderingField === "user__user_name" ? isAscending : true,
      created_at: orderingField === "created_at" ? isAscending : true,
      time_taken: orderingField === "time_taken" ? isAscending : true,
      best_score: orderingField === "best_score" ? isAscending : true,
      no_of_attempts: orderingField === "no_of_attempts" ? isAscending : true,
    });
  };

  const renderArrowIcon = (field) => {
    return (
      <FontAwesomeIcon
        icon={arrowIcons[field] ? faArrowUp : faArrowDown}
        onClick={() => {
          setClickedIcon(field);
          orderTheList(field, !arrowIcons[field]);
        }}
        className={clickedIcon === field ? "arrowIcon" : "nonColoredIcon"}
      />
    );
  };
  return (
    <>
      <div className="statistics-table-container">
        <div className="statistics-table-title-container">
          <div className="statistics-table-title">
            Candidates Name {""}
            {!topCandidates && renderArrowIcon("user__user_name")}
          </div>
          <div className="statistics-table-title">Status</div>
          <div className="statistics-table-title">
            Best Score {""} {/* New Best Score heading */}
            {!topCandidates && renderArrowIcon("best_score")}
          </div>
          <div className="statistics-table-title">
            Attempts Taken {""} {/* Number of Attempts */}
            {!topCandidates && renderArrowIcon("no_of_attempts")}
          </div>
          <div className="statistics-table-title">
            Enrolled On {""}
            {!topCandidates && renderArrowIcon("created_at")}
          </div>
          <div className="statistics-table-title">
            Time Taken {""}
            {!topCandidates && renderArrowIcon("time_taken")}
          </div>
          <div className="statistics-table-title">Actions</div>
        </div>
        {userListLoading ? (
          <div className="candidateTab-table-spinner">
            <Spinner />
          </div>
        ) : (
          <CandidateList
            data={data}
            unEnrollClickhandler={unEnrollClickHandler}
          />
        )}
      </div>
    </>
  );
}
export default CandidateTable;
