/* eslint-disable no-unused-vars */
import {
  ALL_USERS,
  CLEAR_SELECTED_USER,
  FETCH_LLM_DETAILS_FAILURE,
  FETCH_LLM_DETAILS_SUCCESS,
  FETCH_LLMS_FAILURE,
  FETCH_LLMS_SUCCESS,
  GET_GENERAL_SETTINGS,
  POST_GENERAL_SETTINGS,
  POST_LLM_DETAILS_FAILURE,
  POST_LLM_DETAILS_SUCCESS,
  SAVED_AUTH_GOOGLE_SETTING,
  SAVED_AUTH_LDAP_SETTING,
  SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING,
  SAVED_EMAIL_SETTING,
  SELECTED_USER,
  SET_SAVE_BUTTON_DISABLED,
} from "../actiontypes/index";
const initialState = {
  isSaveButtonDisabled: true,

  users: null,
  selectedUser: null,
  savedSetting: {
    generalSetting: {},
    emailSetting: {},
    authenticationSetting: {
      googleAuthSetting: {},
      ldapAuthSetting: {},
    },
    aiSettings: {
      llms: [],
      llmDetails: null,
      error: null,
    },
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ALL_USERS:
      return { ...state, users: payload };
    case SELECTED_USER:
      return { ...state, selectedUser: payload };
    case CLEAR_SELECTED_USER:
      return { ...state, selectedUser: null };
    case SAVED_EMAIL_SETTING:
      return {
        ...state,
        savedSetting: { ...state.savedSetting, emailSetting: payload },
      };
    case SAVED_AUTH_LDAP_SETTING:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          authenticationSetting: {
            ...state.savedSetting.authenticationSetting,
            ldapAuthSetting: payload,
          },
        },
      };
    case FETCH_LLMS_SUCCESS:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            llms: payload,
          },
        },
      };
    case FETCH_LLMS_FAILURE:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            error: payload,
          },
        },
      };
    case FETCH_LLM_DETAILS_SUCCESS:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            llmDetails: payload,
          },
        },
      };
    case FETCH_LLM_DETAILS_FAILURE:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            error: payload,
          },
        },
      };
    case POST_LLM_DETAILS_SUCCESS:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            llmDetails: payload,
          },
        },
      };
    case POST_LLM_DETAILS_FAILURE:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          aiSettings: {
            ...state.savedSetting.aiSettings,
            error: payload,
          },
        },
      };
    case GET_GENERAL_SETTINGS:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          instance_logo: payload?.instance_logo,
          instance_email: payload?.instance_email,
          instance_name: payload?.instance_name,
        },
      };

    case POST_GENERAL_SETTINGS:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          general: payload,
          email: payload,
          name: payload,
        },
      };
    case SAVED_AUTH_GOOGLE_SETTING:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          authenticationSetting: {
            ...state.savedSetting.authenticationSetting,
            googleAuthSetting: payload,
          },
        },
      };
    case SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING:
      return {
        ...state,
        savedSetting: {
          ...state.savedSetting,
          authenticationSetting: {
            ...state.savedSetting.authenticationSetting,
            passwordBasedAuthSetting: payload,
          },
        },
      };
    case SET_SAVE_BUTTON_DISABLED:
      return {
        ...state,
        isSaveButtonDisabled: action.payload,
      };

    default:
      return state;
  }
}
