import "./AISettingPage.scss";
import { Button } from "antd";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledInputField } from "../../../components/StyledFields/StyledInputField";
import arrowDown from "../../../assets/arrow-down.svg";
import {
  fetchLLMDetails,
  fetchLLMs,
  postLLMDetails,
} from "../../../store/actions/adminActions";
import {
  isSaveButtonDisabledSelector,
  llmDetailsSelector,
  llmsSelector,
} from "../../../store/selectors/adminSelectors";
import { AISettingUtils } from "../AdminSettingsUtils";

import Breadcrumbs from "../../../components/BreadCrumb/BreadCrumbs";
import withPrompt from "../../../hooks/withPrompt";

import { setSaveButtonDisabled } from "../../../store/actions/adminActions";

const AISettingHeaderSection = ({ title, desc, title2, desc2 }) => (
  <>
    <div className="ai-header-section-one">
      <h3 className="ai-header-title">{title}</h3>
      <span className="ai-header-desc">{desc}</span>
    </div>
    <div className="ai-header-section-two">
      <h3 className="ai-header-title2">{title2}</h3>
      <span className="ai-header-desc2">{desc2}</span>
    </div>
  </>
);

const AIFormSection = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);

  const llms = useSelector(llmsSelector);
  const llmDetails = useSelector(llmDetailsSelector);

  const INITIAL_FORM_VALUES = {
    aiEngine: llmDetails?.name || "Choose an AI engine",
    apiKey: llmDetails?.token_key || llmDetails?.token || "",
  };

  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  const aiEngineOptions =
    llms?.map((llm) => ({
      name: llm.llm,
      value: llm.llm,
    })) || [];

  useEffect(() => {
    dispatch(fetchLLMs());
    dispatch(fetchLLMDetails());
  }, [dispatch]);

  useEffect(() => {
    setInitialValues(INITIAL_FORM_VALUES);
    setFormValues(INITIAL_FORM_VALUES);
  }, [llmDetails]);

  useEffect(() => {
    const isChanged =
      formValues.aiEngine !== initialValues.aiEngine ||
      formValues.apiKey !== initialValues.apiKey;

    dispatch(setSaveButtonDisabled(!isChanged));
  }, [formValues, initialValues]);

  const handleFormChange = (values) => {
    setFormValues(values);
  };

  return (
    <Formik
      fluid
      innerRef={ref}
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={AISettingUtils}
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      isInitialValid={false}
      onSubmit={(values) => {
        setSaveButtonLoader(true);
        dispatch(
          postLLMDetails(values.aiEngine, values.apiKey, setSaveButtonLoader)
        ).then(() => {
          dispatch(setSaveButtonDisabled(true));
          setInitialValues(values);
        });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form
          className="form"
          data-testid="form-div"
          onChange={() => handleFormChange(values)}
        >
          <div className="ai-upper-setting-fields">
            <Field
              label="AI Engine"
              id="ai-engine"
              name="aiEngine"
              type="select"
              className="admin-setting-option"
              component={StyledInputField}
              placeholder="Choose an AI engine"
              options={aiEngineOptions}
              onChange={(event) => {
                setFieldValue("aiEngine", event);
                handleFormChange({ ...values, aiEngine: event });
              }}
              suffixIcon={
                <img
                  src={arrowDown}
                  alt="arrow-down"
                  className="suffix-icon-arrow-down"
                />
              }
            />
            <Field
              label="API Key"
              type="password"
              id="api-key"
              name="apiKey"
              component={StyledInputField}
              placeholder="Enter API Key"
              onChange={(event) => {
                setFieldValue("apiKey", event.target.value);
                handleFormChange({ ...values, apiKey: event.target.value });
              }}
              className="Api-key"
            />
          </div>

          <div className="ai-settings-button">
            <Button
              className="ai-settings-savebutton"
              loading={saveButtonLoader}
              htmlType="submit"
              disabled={isSaveButtonDisabled}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const AISettingPage = () => {
  return (
    <div className="ai-setting-container">
      <Breadcrumbs omit={[0]} />
      <AISettingHeaderSection
        title={"AI features for your tenant."}
        desc={"Configure your AI API credentials"}
        title2={"AI engine"}
        desc2={
          <>
            Choose an AI engine.{" "}
            <a
              href="https://platform.openai.com/docs/models"
              target="_blank"
              rel="noopener noreferrer"
              className="ai-link"
            >
              Learn more
            </a>
          </>
        }
      />
      <AIFormSection />
    </div>
  );
};
export default withPrompt(AISettingPage);
