/* eslint-disable no-unused-vars */
import GlobalEmptyContainer from "../../../../components/GlobalEmptyContainer/GlobalEmptyContainer";
import "./AskAIEmptyContainer.scss";
import { ReactComponent as AiRobotIcon } from "../../../../assets/Ask-ai-robot.svg";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { isEmpty } from "lodash";
import SubmitPdfConfirmModal from "./SubmitPdfConfirmModal";
import { useDispatch } from "react-redux";
import {
  generateQuestionsFromPdf,
  searchTag,
} from "../../../../store/actions/genAiActions";
import { useSelector } from "react-redux";
import { userSelector } from "../../../../store/selectors/authSelectors";
// eslint-disable-next-line
import { WithContext as ReactTags } from "react-tag-input";
import { ReactComponent as Exclamation } from "../../../../assets/exclamation-circle.svg";
import constants, {
  ASK_AI_TEST_OPTIONS as options,
} from "../../../../constants";
import MultiSelectDropDown from "../../../../components/MultiSelectDropDown/MultiSelectDropDown";

const keyCodes = {
  commas: 188,
  enter: 13,
};
const delimiters = [keyCodes.commas, keyCodes.enter];

const TagInputField = ({ tags, setTags }) => {
  const dispatch = useDispatch();
  const [suggestionsTags, setSuggestionsTags] = useState([]);
  useEffect(() => {
    dispatch(searchTag(setSuggestionsTags));
  }, []);
  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    setTags(newTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = [...tags];
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  return (
    <div id="tags">
      <ReactTags
        placeholder=""
        tags={tags}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        inputFieldPosition="inline"
        autocomplete
        autofocus={false}
        suggestions={suggestionsTags}
      />
    </div>
  );
};

const AskAIEmptyContainer = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [inputFiles, setInputFiles] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const userId = useSelector(userSelector);
  const [value, setValue] = useState({
    label: "Multiple Choice Questions",
    value: 1,
  });
  const selectProps = {
    mode: "single",
    style: {
      width: "100%",
    },
    value,
    options,
    onChange: (newValue, selectedOptions) => {
      setValue(selectedOptions);
    },
    placeholder: "",
    maxTagCount: 1,
    // onDropdownVisibleChange: ,
  };

  const openSubmitConfirmModal = () => {
    const questionType =
      value.label === "Subjective Questions"
        ? "SUBJECTIVE"
        : value.label === "Multiple Choice Questions"
        ? "MULTIPLE"
        : "ALL";
    const file = inputFiles[0];
    const newTags = tags.map((tag) => tag.text);
    dispatch(
      generateQuestionsFromPdf(
        file,
        userId?.id,
        newTags,
        questionType,
        setIsModalOpen
      )
    );
  };
  const licenceType = process.env.REACT_APP_LICENCE_TYPE;
  const closeSubmitPdfConfirmModal = () => {
    setIsModalOpen(false);
    /* Setting inputRef value "" because it doesn't
    update the value when you reselect the same file again in input filed */
    setTags([]);
    inputRef.current.value = "";
    setInputFiles(null);
  };

  const handleOnDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setInputFiles(event.dataTransfer.files);
  };
  const handleRemoveFile = () => {
    /* Setting inputRef value "" because it doesn't
    update the value when you reselect the same file again in input filed */
    inputRef.current.value = "";
    setInputFiles(null);
  };

  return (
    <>
      {licenceType?.toUpperCase() === "FREE" && (
        <div className="Ask-Ai-thread">
          <div className="Ask-Ai-thread-header">
            <Exclamation />
            <span className="content">{constants.ASK_AI_BANNER_MESSAGE}</span>
          </div>
        </div>
      )}
      <div className="ask-ai-emptyContainer">
        <SubmitPdfConfirmModal
          open={isModalOpen}
          handleClose={closeSubmitPdfConfirmModal}
          text={value.label}
          data={{}}
        />
        <div className="ask-ai-messageAndImg">
          <div className="ask-ai-EmptyContainer">
            <GlobalEmptyContainer
              imgContent={
                <AiRobotIcon
                  style={{
                    width: "330px",
                    height: "210px",
                  }}
                  className="QME-img"
                />
              }
              content={
                <div className="ask-ai-empty-content">
                  <span className="ask-ai-empty-para">
                    Transform your PDFs into questions! Upload to generate MCQs
                    or subjective questions with AI.
                  </span>
                </div>
              }
            />
            <div className="uploadPdfTags">
              <label htmlFor="tags" className="question-title">
                Tags
              </label>
              <p id="tags-para" className="question-description">
                Enter tags separated by comma.
              </p>
              <div className="input-fields-ask-ai">
                <TagInputField tags={tags} setTags={setTags} />
                <p id="tags-para" className="question-description">
                  Select Question type
                </p>
                <MultiSelectDropDown selectProps={selectProps} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="ask-ai-fileUploader"
          onDragOver={handleOnDragOver}
          onDrop={handleDrop}
        >
          <div className="ask-ai-fileUploader-content">
            <span className="ask-ai-fileUploader-text1">
              Drag and Drop files here
            </span>
            <span className="ask-ai-fileUploader-text2">
              Limit 3GB per file
            </span>
            <input
              type="File"
              onChange={(event) => {
                setInputFiles(event.target.files);
              }}
              hidden
              accept="application/pdf"
              multiple={false}
              ref={inputRef}
            />
            <span
              className="ask-ai-browseButton"
              onClick={() => inputRef.current.click()}
            >
              Browse
            </span>
          </div>
        </div>
        <div className="ask-ai-FilesandButton-container">
          {!isEmpty(inputFiles) && (
            <>
              {Array.from(inputFiles).map((file, index) => (
                <div key={index} className="inputFilesAskAI">
                  <FontAwesomeIcon icon={faFilePdf} className="pdf-fileIcon" />
                  <div className="ask-ai-fileContents">
                    <div className="ask-ai-fileContent">
                      {" "}
                      <span className="file-name">{file.name}</span>
                      <span className="file-size">
                        {formatFileSize(file.size)}
                      </span>
                    </div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="pdf-CancelIcon"
                      onClick={handleRemoveFile}
                    />
                  </div>
                </div>
              ))}
            </>
          )}
          <div className="ask-ai-continueButton-container">
            <Button
              className="ask-ai-continueButton"
              disabled={!inputFiles}
              onClick={openSubmitConfirmModal}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const formatFileSize = (sizeInBytes) => {
  const KB = sizeInBytes / 1024;
  if (KB < 1024) {
    return KB.toFixed(2) + " KB";
  } else {
    const MB = KB / 1024;
    return MB.toFixed(2) + " MB";
  }
};

export default AskAIEmptyContainer;
