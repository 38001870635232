import { useState } from "react";
import { Button } from "antd";
import UnEnrolledTabDisplay from "./UnEnrolledTabDisplay";
import Breadcrumbs from "../../../../components/BreadCrumb/BreadCrumbs";
import UnEnrolledTabHeaders from "./UnEnrolledTabHeaders";
import "./UnEnrolledTabScreen.scss";
import { useEffect } from "react";
import BulkInviteModal from "../../../AdminUserList/BulkInviteModal/BulkInviteModal";
const UnEnrolledTabScreen = ({ courseList }) => {
  const [activeTab, setActiveTab] = useState("content");
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  useEffect(() => {
    // Set the active tab based on the current URL
    const pathSegments = (location.pathname + location.hash).split("/");
    const tabSegmentIndex = pathSegments.findIndex(
      (segment) =>
        segment.toLowerCase() === "statistics" ||
        segment.toLowerCase() === "content" ||
        segment.toLowerCase() === "candidate"
    );
    if (tabSegmentIndex !== -1) {
      const tabValue = pathSegments[tabSegmentIndex].toLowerCase();
      setActiveTab(tabValue);
    }
  }, []);
  return (
    <>
      {courseList.map((values) => {
        return (
          <div className="courseUnenrolledPage-header" key={values.course.id}>
            <BulkInviteModal open={isInviteOpen} setOpen={setIsInviteOpen} />
            <div className="header-left">
              <Breadcrumbs />
              <div className="course-title">{values.course.course_name}</div>
              <UnEnrolledTabHeaders
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="header-right">
              <Button
                onClick={() => setIsInviteOpen(true)}
                type="primary"
                size="large"
                block="true"
                style={{
                  background: "transparent",
                  color: "#313131",
                  border: "1.5px solid #1a2b3c",
                  fontWeight: "600",
                }}
                data-testid="invite-button"
              >
                Invite
              </Button>
            </div>
          </div>
        );
      })}
      <UnEnrolledTabDisplay activeTab={activeTab} />
    </>
  );
};

export default UnEnrolledTabScreen;
