/* eslint-disable no-unused-vars */
import { Button, Dropdown } from "antd";
import "./QuestionListHeader.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { currentEditingTestDetails } from "../../../../store/selectors/wizardSelectors";
import Vector from "../../../../assets/Vector.svg";
import { useDispatch } from "react-redux";
import {
  REMOVE_SELECTED_JOB,
  SET_JOB_SELECTED,
} from "../../../../store/actiontypes";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const QuestionListHeader = ({
  onStateChange,
  isQuestionReviewList = false,
  fileName,
  onDone,
}) => {
  const navigate = useNavigate();
  const mcqListId = useSelector(currentEditingTestDetails);
  const courseId = location.hash.split("/")[2];
  const [isReorderSectionOpen, setIsReorderSectionOpen] = useState(false);
  const dispatch = useDispatch();
  const handleChangeQuestionOrder = () => {
    setIsReorderSectionOpen(true);
    onStateChange(true);
  };
  const items = [
    {
      label: (
        <div className="gen-ai-icon-div">
          <Link
            to={`/courses/${courseId}/test/${mcqListId?.id}/manager?questionbank=true`}
            className="links-ai"
          >
            Choose From Question Bank
          </Link>
        </div>
      ),
      key: "1",
    },
  ];
  const handleAddQuestion = () => {
    navigate(`/courses/${courseId}/test/${mcqListId?.id}/manager?id=new`);
  };
  return (
    <div className="questionList-header">
      {isQuestionReviewList ? (
        <div className="questionList-header-subtitle">{fileName}</div>
      ) : (
        <div className="questionList-header-title">Questions manager</div>
      )}
      <div className="questionList-header-functions">
        <div
          onClick={
            !isQuestionReviewList
              ? handleChangeQuestionOrder
              : () => {
                  dispatch({
                    type: REMOVE_SELECTED_JOB,
                    payload: {},
                  });
                  navigate("/ask-ai/jobs");
                  dispatch({
                    type: SET_JOB_SELECTED,
                    payload: false,
                  });
                }
          }
          className="change-order"
        >
          {isQuestionReviewList ? (
            <>Cancel</>
          ) : (
            <>
              <img src={Vector} />
              Change question order
            </>
          )}
        </div>
        <div>
          {!isQuestionReviewList || location.pathname.includes("/manager") ? (
            <div className="add-button">
              <div className="add-button">
                <Dropdown.Button
                  icon={<DownOutlined />}
                  trigger={["click"]}
                  menu={{
                    items,
                  }}
                  onClick={handleAddQuestion}
                >
                  <FontAwesomeIcon icon={faPlus} color="#FFFFFF" />
                  Add Question
                </Dropdown.Button>
              </div>
            </div>
          ) : (
            <Button
              className="add-Done-button"
              onClick={() => {
                onDone(true);
              }}
            >
              Done
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default QuestionListHeader;
