import JobCard from "./JobCard";
import "./JobsContainer.scss";

function JobsContainer({ jobs }) {
  return (
    <div className="jobs-container">
      {jobs.map((job) => {
        const { id, file_name, status, tags, ...jobParams } = job;
        return (
          <JobCard
            key={id}
            jobId={id}
            name={file_name}
            status={status}
            tags={tags.length === 0 ? [] : tags}
            {...jobParams}
          />
        );
      })}
    </div>
  );
}

export default JobsContainer;
