import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  startCandidateTest,
  submitCandidateTest,
} from "../../store/actions/courseAction";
import { useSelector } from "react-redux";
import {
  getCurrentMcqIndex,
  getTestQuestions,
  testDetails,
} from "../../store/selectors/coursesSelectors";
import { QuestionListItem } from "../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItem";
import { CURRENT_MCQ_INDEX, SUBMIT_ANSWER } from "../../store/actiontypes";
// import { ReactComponent as GroupSvg } from "../../../src/assets/Group684.svg";
import { Button } from "antd";
import "./CandidateTestContainer.scss";
import TestTimer from "./TestTimer";
import { usePageVisibility } from "../../hooks/usePageVisibility";
import BrowserChangeModal from "./BrowserChangeModal";
import SubmitTestModal from "./SubmitTestModal";
import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import StartConfirmTest from "./StartConfirmTest";
import { ARE_YOU_SURE, AUTO_SUBMIT, TIMES_UP } from "../../constants/McqTest";

const CandidateTestContainer = () => {
  const dispatch = useDispatch();
  const enrollId = window.location.hash.split("/")[4];
  const [testStartTime, setTestStartTime] = useState(null);
  const [isStartModalOpen, setIsStartModalOpen] = useState(true);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState({
    autoSubmit: false,
    confirmation: false,
  });
  const [submitLoader, setsubmitLoader] = useState(false);
  const testId = window.location.hash.split("/")[2];
  const [countTabChange, setIsCountTabChange] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [makeItRed, setMakeItRed] = useState(false);
  const [isAllAtteptsModalOpen, setIsAllAtteptsModalOpen] = useState(false);
  const isVisible = usePageVisibility();
  const raiseWarning = () => {
    setMakeItRed(true);
  };
  useEffect(() => {
    if (!isVisible) {
      setIsCountTabChange(countTabChange + 1);
    }
    if (countTabChange < 3 && isVisible && countTabChange > 0) {
      setIsModalOpen(true);
    }
    if (isVisible && countTabChange === 3) {
      setIsModalOpen(false);
      setIsAllAtteptsModalOpen(true);
    }
  }, [isVisible]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(0);
  const menuDropDownHandler = (selectedValue) => {
    const getQuestionNumber = selectedValue[0]?.value;
    dispatch({ type: CURRENT_MCQ_INDEX, payload: selectedValue[0]?.value });
    setSelectedDropdownValue(getQuestionNumber);
  };
  useEffect(() => {
    dispatch(startCandidateTest(testId, enrollId));
  }, []);
  // functions to handle pop screen
  const closeStartConfirmModal = () => {
    const now = new Date();
    setTestStartTime(now);
    setIsStartModalOpen(false);
  };
  const questions = useSelector(getTestQuestions);
  const current_mcq_index = useSelector(getCurrentMcqIndex);
  const testDetail = useSelector(testDetails);
  const [timerKey, setTimerKey] = useState(0);
  useEffect(() => {
    //based on timerKey Timer is getting reset
    //so for total_time_limit_of_test key should not change
    // for time_limit_for_each_question timer will reset for new question
    if (!testDetail?.total_time_limit_of_test)
      setTimerKey((prevKey) => prevKey + 1);
  }, [current_mcq_index]);

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val)) // Adding leading zeros
      .join(":");
  };

  const onTimeComplete = () => {
    if (
      testDetail?.time_limit_for_each_question &&
      current_mcq_index < questions.length - 1 &&
      !isAllAtteptsModalOpen
    ) {
      dispatch({ type: CURRENT_MCQ_INDEX, payload: current_mcq_index + 1 });
    } else {
      if (
        !isSubmitModalOpen.autoSubmit &&
        !isSubmitModalOpen.confirmation &&
        !isAllAtteptsModalOpen
      ) {
        // here we are opening the submit modal to show time is up message and test will auto submit in 5 seconds
        setIsSubmitModalOpen({ autoSubmit: true, confirmation: false });
      } else if (
        !isSubmitModalOpen.autoSubmit ||
        isAllAtteptsModalOpen ||
        !isSubmitModalOpen.confirmation
      ) {
        // Here we ca submitting test and then the window will close
        const now = new Date();
        let attemptDurationSeconds = Math.floor((now - testStartTime) / 1000);
        if (
          attemptDurationSeconds > testDetail?.total_time_limit_of_test &&
          testDetail?.total_time_limit_of_test
        ) {
          attemptDurationSeconds = testDetail?.total_time_limit_of_test;
        } else if (
          testDetail?.time_limit_for_each_question &&
          isSubmitModalOpen.autoSubmit
        ) {
          attemptDurationSeconds = attemptDurationSeconds - 5;
        }
        const formattedAttemptDuration = formatDuration(attemptDurationSeconds);
        dispatch(
          submitCandidateTest(
            testId,
            enrollId,
            questions,
            formattedAttemptDuration
          )
        );
        setsubmitLoader(true);
        setIsAllAtteptsModalOpen(false);
      }
    }
  };
  const handleChange = (event) => {
    let questionIndex = current_mcq_index;
    dispatch({
      type: SUBMIT_ANSWER,
      payload: {
        questionIndex: questionIndex,
        userAnswer: event.target.value,
      },
    });
  };
  const callbackSubmit = () => {
    setsubmitLoader(false);
  };
  const onSubmit = () => {
    if (current_mcq_index < questions.length - 1) {
      dispatch({ type: CURRENT_MCQ_INDEX, payload: current_mcq_index + 1 });
    } else if (
      current_mcq_index === questions.length - 1 &&
      !isSubmitModalOpen.confirmation
    ) {
      // Here the we are setting state confirmation as true to open submit modal and confirm whether user wans to submit test
      setIsSubmitModalOpen({ autoSubmit: false, confirmation: true });
    } else {
      const now = new Date();
      const attemptDurationSeconds = Math.floor((now - testStartTime) / 1000); // Calculate attempt duration in seconds
      const formattedAttemptDuration = formatDuration(attemptDurationSeconds);
      // Logic for submitting the test
      dispatch(
        submitCandidateTest(
          testId,
          enrollId,
          questions,
          formattedAttemptDuration,
          callbackSubmit
        )
      );
      setsubmitLoader(true);
    }
  };
  const option =
    questions?.map((question, index) => ({
      label: `Question ${index + 1}`,
      value: index,
    })) || [];
  const onPrev = () => {
    if (current_mcq_index > 0) {
      dispatch({ type: CURRENT_MCQ_INDEX, payload: current_mcq_index - 1 });
    }
  };
  const getDefaultValue = () => {
    let selected = option.filter(
      (o) => o.label === `Question ${current_mcq_index + 1}`
    )[0];
    return selected;
  };
  return (
    questions &&
    testDetail && (
      <div className="testPage-Container">
        <StartConfirmTest
          open={isStartModalOpen}
          handleClose={closeStartConfirmModal}
          data={testDetail}
        />
        <div className="testPage-Header-content">
          <div className="testPage-Header-left">
            {/* <GroupSvg />  */}
            <img
              style={{ width: "37px" }}
              src={require("../../assets/eigenlearn-logo-light-small.png")}
            />
            <span className="testPage-testName">{testDetail?.test_name}</span>
          </div>
          <div className="testPage-Header-right">
            <span className="testPage-testTime">
              {testDetail?.time_limit_for_each_question
                ? "Time to answer:"
                : "Time left:"}
            </span>
            <span
              className={`testPage-testTimer ${
                makeItRed ? "testTimer-red" : "testTimer-black"
              }`}
            >
              <TestTimer
                key={timerKey}
                autoStart={isStartModalOpen}
                testTime={
                  testDetail?.total_time_limit_of_test ||
                  testDetail?.time_limit_for_each_question
                }
                onTimeComplete={onTimeComplete}
                raiseWarning={raiseWarning}
              />
            </span>
          </div>
        </div>
        <SubmitTestModal
          open={
            isAllAtteptsModalOpen ||
            isSubmitModalOpen.confirmation ||
            isSubmitModalOpen.autoSubmit
          }
          setOpen={
            isAllAtteptsModalOpen
              ? setIsAllAtteptsModalOpen
              : setIsSubmitModalOpen
          }
          countTabChange={countTabChange}
          onTimeComplete={onTimeComplete}
          key={timerKey}
          message={
            isAllAtteptsModalOpen
              ? AUTO_SUBMIT
              : isSubmitModalOpen.confirmation
              ? ARE_YOU_SURE
              : TIMES_UP
          }
        />
        <BrowserChangeModal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          countTabChange={countTabChange}
        />
        <div className="test-giving-section">
          <div className="question-button-container">
            <div
              key={current_mcq_index}
              className="testPage-question_container"
            >
              <div className="testPage-question-number">
                Question {current_mcq_index + 1} / {questions?.length}
              </div>
              <QuestionListItem
                index={current_mcq_index}
                question={questions[current_mcq_index]?.question}
                options={questions[current_mcq_index]?.options}
                userSelectedAnswer={questions[current_mcq_index]?.user_answer}
                disabled={false}
                handleChange={handleChange}
                givingTest={true}
              />
            </div>
            <div className="testPage-buttons-container">
              {testDetail?.allow_question_back_n_forth ? (
                <MenuDropDown
                  options={option}
                  defaultSelected={() => getDefaultValue()}
                  dropdownPosition="auto"
                  onClickHandle={(selectedValue) =>
                    menuDropDownHandler(selectedValue)
                  }
                  selected={selectedDropdownValue}
                />
              ) : (
                <div></div>
              )}
              <div className="left-side-buttons">
                {!testDetail?.allow_question_back_n_forth ? (
                  <Button
                    className="testPage-test-submitButton"
                    onClick={onSubmit}
                    loading={submitLoader}
                  >
                    Submit Answer
                  </Button>
                ) : (
                  <>
                    {current_mcq_index !== 0 && (
                      <Button
                        className="testPage-test-prevButton"
                        onClick={onPrev}
                      >
                        Previous
                      </Button>
                    )}{" "}
                    <Button
                      className="testPage-test-submitButton"
                      onClick={onSubmit}
                      loading={submitLoader}
                    >
                      {current_mcq_index === questions.length - 1
                        ? "Submit"
                        : "Next"}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CandidateTestContainer;
