import Spinner from "../Spinner/Spinner";
import "./AboutModule.scss";

const AccordianContent = ({ url }) => {
  return (
    <>
      {!url ? (
        <Spinner />
      ) : (
        <div className="Accordian-Content-container">
          <iframe
            src={url}
            width="100%"
            height="900px"
            style={{ border: "none" }}
            title="Google Doc"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default AccordianContent;
