import { ReactComponent as Userscog } from "../assets/Userscog.svg";
import { ReactComponent as Slidersh } from "../assets/Slidersh.svg";
import DetailsMcqForm from "../features/CourseListFeature/WizardCourseSteps/McqStep/DetailsMcqForm";
import QuestionManagerMcq from "../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionManagerMcq";

export const TesttabConfigurations = [
  {
    key: "details",
    icon: <Userscog />,
    label: "Details",
    content: <DetailsMcqForm />, //Mcq test creation form
    shouldRender: true,
  },
  {
    key: "manager",
    icon: <Slidersh />,
    label: "Question Manager",
    content: <QuestionManagerMcq />,
    shouldRender: true,
  },
];

export const AUTO_SUBMIT =
  "Attention: You have exceeded tab switches limit. Test will auto-submit in";

export const ARE_YOU_SURE = "Are you sure you want to submit this test?";

export const TIMES_UP = "Your Time is Up! Test will be auto submit in";
