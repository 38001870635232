import { useDispatch } from "react-redux";
import { deleteQuestions } from "../../../../store/actions/wizardActions";
import StyledModal from "../../../../components/StyledModal/StyledModal";
import Spinner from "../../../../components/Spinner/Spinner";
import "./QuestionDeleteConfirmModel.scss";
import {
  deleteQuestionsInQuestionBank,
  updateQuestionsInQuestionBank,
} from "../../../../store/actions/genAiActions";
function QuestionDeleteConfirmModal({
  open,
  onCancel,
  data,
  onsuccesscallback,
  loading,
  setLoading,
  isQuestionReviewList = false,
  mcqId = "",
  fromQuestionBankList = false,
  fromDeleteAll = false,
}) {
  const dispatch = useDispatch();
  const handleOk = () => {
    setLoading(true);
    isQuestionReviewList
      ? dispatch(
          updateQuestionsInQuestionBank(
            mcqId,
            { questions: data },
            onsuccesscallback
          )
        )
      : fromQuestionBankList
      ? dispatch(
          deleteQuestionsInQuestionBank({ questions: data }, onsuccesscallback)
        )
      : dispatch(deleteQuestions(data, onsuccesscallback));
  };
  return (
    <StyledModal
      className={"draft-delete-modal"}
      centred
      title={fromDeleteAll ? "Bulk Delete" : "Delete Question"}
      visible={open}
      onCancel={() => {
        setLoading(false);
        onCancel();
      }}
      onOk={handleOk}
      closable={true}
    >
      <div className="question-delete-modal">
        {loading ? (
          <Spinner text={"Please wait.. deletion in progress..."} />
        ) : (
          <>
            {fromDeleteAll && (
              <span>
                Only questions owned by you will be deleted.
                <br />
              </span>
            )}
            Are you sure you want to delete?
          </>
        )}
      </div>
    </StyledModal>
  );
}

export default QuestionDeleteConfirmModal;
