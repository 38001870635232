/* eslint-disable quotes */
/* eslint-disable no-unused-vars*/
import { actionFactory } from "../../libs/actionFactory";
import { SET_JOB_ACTION } from "../actiontypes";

const setJobActionsList = (data) => ({
  type: SET_JOB_ACTION,
  payload: data,
});

export const getAllJobsList = (user_id, params = {}, callback) => {
  return actionFactory({
    api: `get/api/gen-ai/${user_id}/list/`,
    actionBody: {
      params: { ...params },
    },
    actionBase: SET_JOB_ACTION,
    // failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch(setJobActionsList(resp["data"]?.data));
          if (callback) {
            callback();
          }
        }
      });
    },
  });
};
