import { useEffect } from "react";
import { unstable_useBlocker, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelNavigation,
  completeNavigation,
  startNavigation,
} from "../store/actions/navigationActions";

import StyledModal from "../components/StyledModal/StyledModal";
import { isSaveButtonDisabledSelector } from "../store/selectors/adminSelectors";
export function usePrompt(
  when = {
    message: "You have unsaved changes. Do you want to leave without saving?",
  }
) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);
  const blocker = unstable_useBlocker(!isSaveButtonDisabled);
  const { isNavigating, navigationTarget } = useSelector(
    (state) => state.navigation
  );

  useEffect(() => {
    if (!isSaveButtonDisabled) {
      window.onbeforeunload = () => "Are you sure you want to leave?";
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isSaveButtonDisabled]);

  useEffect(() => {
    if (blocker && blocker.state === "blocked") {
      dispatch(startNavigation(blocker.location.pathname));
    }
  }, [blocker, dispatch]);

  useEffect(() => {
    if (isNavigating && navigationTarget) {
      const element = document.createElement("div");
      element.setAttribute("id", "prompt-dialog-container");

      const closePrompt = (proceed) => {
        if (element) {
          ReactDOM.unmountComponentAtNode(element);
        }
        if (proceed) {
          blocker.proceed();
          dispatch(completeNavigation());
          navigate(navigationTarget);
        } else {
          blocker.reset();
          dispatch(cancelNavigation());
        }
        document.body.removeChild(element);
      };

      document.body.appendChild(element);

      ReactDOM.render(
        <StyledModal
          title="Warning!"
          centered
          open={true}
          onOk={() => closePrompt(true)}
          onCancel={() => closePrompt(false)}
          maskClosable={false}
          closable={false}
          okText="Continue"
        >
          <p className="unsaved-changes">{when.message}</p>
        </StyledModal>,
        element
      );
    }
  }, [
    isNavigating,
    navigationTarget,
    blocker,
    when.message,
    dispatch,
    navigate,
  ]);
}
