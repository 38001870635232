/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import UserList from "./UserList";
import UserRoleConfirmModal from "./UserRoleConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateSearchValues } from "../../store/actions/listParamsAction";
import { USER_LIST_ID } from "../../constants";
import Spinner from "../../components/Spinner/Spinner";
import { Checkbox } from "antd";
import MenuDropDown from "../../components/MenuDropDown/MenuDropDown";
import MultiselectArchiveModal from "./MultiselectArchiveModal";

/* eslint-disable react/prop-types */
const UserTable = ({
  data,
  editClickHandler,
  archiveClickHandler,
  enrollClickHandler,
}) => {
  const [clickedIcon, setClickedIcon] = useState("");
  const [arrowIcons, setArrowIcons] = useState({
    user_name: true,
    emp_id: true,
  });
  const dispatch = useDispatch();
  const [userListLoading, setUserListLoading] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({ name: "", role: "", id: "" });
  const [indeterminate, setIndeterminate] = useState(false);
  useEffect(() => {
    setUserListLoading(false);
  }, [data]);

  useEffect(() => {
    if (userIds?.length === data?.count) {
      setIndeterminate(false);
    } else {
      setIndeterminate(
        data?.results?.some((item) => userIds?.includes(item?.id))
      );
    }
    setAllSelected(userIds?.length === data?.count);
  }, [userIds, data]);

  const handleAllSelectChange = (e) => {
    const { checked } = e.target;
    let newData = [];
    if (checked) {
      const userIdArray = data?.results
        .map((user) => user.id)
        .filter((id) => !userIds.includes(id));
      newData = [...userIds, ...userIdArray];
      setIndeterminate(true);
    } else {
      newData = userIds.filter(
        (userId) => !data.results.some((user) => user.id === userId)
      );
      setIndeterminate(false);
    }
    setAllSelected(data?.count === newData?.length);
    setUserIds(newData);
  };

  const orderTheList = (orderingField, isAscending) => {
    dispatch(
      updateSearchValues({
        id: USER_LIST_ID,
        ordering: isAscending ? orderingField : `-${orderingField}`,
      })
    );
    setUserListLoading(true);
    setArrowIcons({
      user_name: orderingField === "user_name" ? isAscending : true,
      emp_id: orderingField === "emp_id" ? isAscending : true,
    });
  };

  const renderArrowIcon = (field) => {
    return (
      <FontAwesomeIcon
        icon={arrowIcons[field] ? faArrowUp : faArrowDown}
        onClick={() => {
          setClickedIcon(field);
          orderTheList(field, !arrowIcons[field]);
        }}
        className={clickedIcon === field ? "arrowIcon" : "nonColoredIcon"}
      />
    );
  };
  const [openUserRoleConfirmationModal, setOpenUserRoleConfirmationModal] =
    useState(false);

  const roleSelectHandle = (role, username, id, group, menu_group) => {
    setUser((prev) => {
      return {
        ...prev,
        name: username,
        role: role[0],
        id: id,
        groups: group,
        prevGrp: menu_group,
      };
    });
    setOpenUserRoleConfirmationModal(true);
  };

  const option = [
    {
      label: "Archive",
      value: userIds,
    },
  ];

  const getDefaultValue = () => {
    let selected = { label: "Actions" };
    return selected;
  };

  const onClickHandler = (selectedValue) => {
    if (selectedValue[0].label === "Archive") {
      setOpen(true);
    }
  };

  return (
    <>
      <UserRoleConfirmModal
        open={openUserRoleConfirmationModal}
        setOpen={setOpenUserRoleConfirmationModal}
        name={user.name}
        role={user.role}
        id={user.id}
        groups={user.groups}
        prevGrp={user.prevGrp}
      />
      <MultiselectArchiveModal
        open={open}
        setOpen={setOpen}
        userIds={userIds}
        setUserIds={setUserIds}
      />
      <div data-testid="table-container" className="table-container">
        {data?.results?.length > 0 && (
          <div className="usertable-dropdown-container">
            <MenuDropDown
              disabled={userIds.length === 0}
              options={option}
              defaultSelected={getDefaultValue}
              onClickHandle={(selectedValue) => onClickHandler(selectedValue)}
            />{" "}
          </div>
        )}
        <div className="table-title-container">
          <div
            style={
              data?.results?.length > 0
                ? { paddingLeft: "0px", marginTop: "-4px" }
                : {}
            }
            className="table-title"
          >
            {data?.results?.length > 0 && (
              <Checkbox
                indeterminate={indeterminate}
                className="usertable-checkbox"
                checked={allSelected}
                onChange={handleAllSelectChange}
              />
            )}
            Username{""} {renderArrowIcon("user_name")}
          </div>
          <div style={{ marginLeft: "28px" }} className="table-title">
            ID {""}
            {renderArrowIcon("emp_id")}
          </div>
          <div className="table-title">User Roles</div>
          <div className="table-title">Status</div>
          <div className="table-option">{/*Menu*/}</div>
        </div>
        {userListLoading ? (
          <div className="spinner-container">
            <Spinner />
          </div>
        ) : (
          <UserList
            data={data?.results}
            editClickHandler={editClickHandler}
            archiveClickHandler={archiveClickHandler}
            enrollClickHandler={enrollClickHandler}
            roleSelectHandle={roleSelectHandle}
            setUserIds={setUserIds}
            userIds={userIds}
          />
        )}
      </div>
    </>
  );
};

export default UserTable;
