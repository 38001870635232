import { combineReducers } from "redux";
import auth from "./auth";
import courses from "./courses";
import admin from "./admin";
import rating from "./rating";
import banner from "./bannerReducer";
import wizard from "./wizard";
import listParams from "./listParams";
import profilePage from "./profilePage";
import graph from "./graph";
import genAi from "./genAi";
import jobActions from "./jobActions";
import navigationReducer from "./navigationReducer";

const rootReducer = combineReducers({
  auth,
  courses,
  admin,
  profilePage,
  rating,
  banner,
  wizard,
  listParams,
  graph,
  genAi,
  jobActions,
  navigation: navigationReducer,
});

export default rootReducer;
