/* eslint-disable quotes */
import PropTypes from "prop-types";
import { Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import "./NavSideBar.scss";

import Sider from "antd/es/layout/Sider";
import MenuItem from "antd/es/menu/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  groupArraySelector,
  userSelector,
} from "../../store/selectors/authSelectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faArrowRightFromBracket,
  faBell,
  faBookOpen,
  faDownload,
  faGear,
  faHome,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../../store/actions/authActions";
import constants from "../../constants";
import NavConfirmModal from "./NavConfirmModal";

const NavSideBar = (props) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  let path = useLocation();
  const isAdminSettingPage = location.hash.includes("admin-settings");
  const { routePaths, collapse } = props;
  const [collapsed, setCollapsed] = useState(collapse);
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const groupsArr = useSelector(groupArraySelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionBankClicked, setQuestionBankClicked] = useState(false);
  useEffect(() => {
    const pathMap = {
      "ask-ai": ["ask_ai", true],
      question: ["questions", true],
      course: ["candidate_course", false],
      users: ["admin_users", false],
      general: ["general", false],
      email: ["email", false],
      authentication: ["authentication", false],
      "aritifical-intelligence": ["aritifical-intelligence", false],
    };
    Object.entries(pathMap).forEach(([key, [selected, isClicked]]) => {
      if (path.pathname === "/") {
        setSelected("candidate_dashboard");
        setQuestionBankClicked(false);
      } else if (path.pathname.includes(key)) {
        setSelected(selected);
        setQuestionBankClicked(isClicked);
      }
    });
  }, [path.pathname]);

  const logoutHandle = () => {
    dispatch(logoutUser);
    navigate("/login", { replace: true });
  };

  const onClick = () => {
    logoutHandle();
  };

  const handlerQuestionBank = () => {
    setQuestionBankClicked(!questionBankClicked);
  };
  const onsuccesscallback = () => {
    setIsLoading(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setCollapsed(collapse);
  }, [collapse]);

  const handleMenuItems = (event, path) => {
    const currentHash = window.location.hash;
    const parts = currentHash.split("/");
    const askAiPart = parts[1];
    if (askAiPart === "ask-ai") {
      setQuestionBankClicked(true);
    }
    setSelected(event.key);
    navigate(path);
  };
  const questionBankRoutes = [
    {
      to: "/question-bank",
      name: "Questions",
      key: "questions",
      icon: !collapsed && (
        <FontAwesomeIcon icon={faBookOpen} style={{ fontSize: "19px" }} />
      ),
    },
    {
      to: "/ask-ai/add",
      name: "Ask-AI",
      key: "ask_ai",
      icon: !collapsed && (
        <FontAwesomeIcon icon={faRobot} style={{ fontSize: "19px" }} />
      ),
    },
  ];
  const questionBankMenu = (
    <Menu style={{ marginTop: "40px" }}>
      {questionBankRoutes.map((route) => (
        <Menu.Item
          key={route.key}
          onClick={(e) => handleMenuItems(e, route.to)}
          style={{ padding: "9px 30px 9px 30px" }}
        >
          {route.icon}
          {route.name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <NavConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onsuccesscallback={onsuccesscallback}
        loading={isLoading}
        setLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
      />
      <Sider
        width={collapsed ? '"fit-content"' : 250}
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="sideNavMenu-style">
          <Menu
            className="sideNav-menu"
            selectedKeys={selected}
            selectable={false}
          >
            <div className="sideNavMenu-title">
              {collapsed && (
                <img
                  className="collapsed-sideNavMenu-logo"
                  src={require("../../assets/eigenlearn-logo-dark-small.png")}
                />
              )}
              {!collapsed && (
                <img
                  className="sideNavMenu-logo"
                  src={require("../../assets/eigenlearn-logo-dark-large.png")}
                />
              )}
            </div>
            <Menu.ItemGroup className="top-sideNavMenu-group">
              {routePaths.map((path) => {
                return path.name === "Question Bank" ? (
                  <>
                    {collapsed ? (
                      <Dropdown
                        overlay={questionBankMenu}
                        trigger={["click", "hover"]}
                        placement="rightCenter"
                      >
                        <MenuItem key={path.key} data-testid={path.key}>
                          <div
                            className={`sideNavMenu-item ${
                              collapsed ? "sideNavMenu-collapsed" : ""
                            }`}
                          >
                            <div className="sideNavMenu-icon">{path.icon}</div>
                          </div>
                        </MenuItem>
                      </Dropdown>
                    ) : (
                      <MenuItem
                        key={path.key}
                        data-testid={path.key}
                        onClick={handlerQuestionBank}
                      >
                        <div
                          className={`sideNavMenu-item ${
                            collapsed ? "sideNavMenu-collapsed" : ""
                          }`}
                        >
                          <div className="sideNavMenu-icon">{path.icon}</div>
                          <div className="sideNavMenu-names">
                            <label>Question Bank</label>
                            <lablel>
                              <FontAwesomeIcon
                                className="arrowIcon-Navbar"
                                icon={
                                  questionBankClicked
                                    ? faAngleDown
                                    : faAngleRight
                                }
                              />
                            </lablel>
                          </div>
                        </div>
                      </MenuItem>
                    )}
                    {!collapsed &&
                      questionBankClicked &&
                      questionBankRoutes.map((route) => (
                        <MenuItem
                          key={route.key}
                          onClick={(e) => handleMenuItems(e, route.to)}
                        >
                          <div
                            id="questionBankItems"
                            className={`sideNavMenu-item ${
                              collapsed ? "sideNavMenu-collapsed" : ""
                            }`}
                          >
                            <div className="sideNavMenu-icon">{route.icon}</div>
                            {!collapsed && (
                              <div className="sideNavMenu-names">
                                <label>{route.name}</label>
                              </div>
                            )}
                          </div>
                        </MenuItem>
                      ))}
                  </>
                ) : (groupsArr?.includes(constants.PORTAL_ADMIN) ||
                    groupsArr?.includes(constants.HR)) &&
                  path.name === "Dashboard" ? null : (
                  <MenuItem
                    title={path.name}
                    id={path.key}
                    data-testid={path.key}
                    key={path.key}
                    onClick={(e) => handleMenuItems(e, path.to)}
                  >
                    <div
                      className={`sideNavMenu-item ${
                        collapsed ? "sideNavMenu-collapsed" : ""
                      }`}
                    >
                      <div className="sideNavMenu-icon">{path.icon}</div>
                      {!collapsed ? (
                        <div className="sideNavMenu-names">
                          <label>{path.name}</label>
                        </div>
                      ) : null}
                    </div>
                  </MenuItem>
                );
              })}
            </Menu.ItemGroup>
            <Menu.ItemGroup className="bottom-navbar">
              {collapsed ? null : (
                <div className="navbar-username">{user?.user_name}</div>
              )}
              <div>
                {groupsArr?.includes(constants.PORTAL_ADMIN) && (
                  <>
                    <Menu.Item
                      title={
                        isAdminSettingPage ? "Back to home" : "Admin Settings"
                      }
                      key={
                        isAdminSettingPage ? "back_to_home" : "general_setting"
                      }
                      data-testid={isAdminSettingPage ? "4" : "3"}
                      className="menu-item-bottom"
                      onClick={() =>
                        isAdminSettingPage
                          ? handleMenuItems(
                              { key: "back_to_home" },
                              "/courses/all"
                            )
                          : handleMenuItems(
                              { key: "general_setting" },
                              "/admin-settings/general"
                            )
                      }
                    >
                      <div
                        className={`sideNavMenu-item ${
                          collapsed ? "sideNavMenu-collapsed" : ""
                        }`}
                      >
                        <div className="sideNavMenu-icon">
                          <FontAwesomeIcon
                            icon={isAdminSettingPage ? faHome : faGear}
                            style={{ color: "#ffffff", fontSize: "20px" }}
                          />
                        </div>
                        {!collapsed ? (
                          <div className="sideNavMenu-names">
                            <label>
                              {" "}
                              {isAdminSettingPage
                                ? "Back to home"
                                : "Admin Settings"}
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </Menu.Item>
                    {!isAdminSettingPage && (
                      <Menu.Item
                        title="Export Data"
                        key="4"
                        data-testid={"4"}
                        className="menu-item-bottom"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <div
                          className={`sideNavMenu-item ${
                            collapsed ? "sideNavMenu-collapsed" : ""
                          }`}
                        >
                          <div className="sideNavMenu-icon">
                            <FontAwesomeIcon
                              icon={faDownload}
                              style={{ color: "#ffffff", fontSize: "20px" }}
                            />
                          </div>
                          {!collapsed ? (
                            <div className="sideNavMenu-names">
                              <label>Export Data</label>
                            </div>
                          ) : null}
                        </div>
                      </Menu.Item>
                    )}
                  </>
                )}

                {!isAdminSettingPage && (
                  <Menu.Item key="1" className="menu-item-bottom" disabled>
                    <div
                      className={`sideNavMenu-item ${
                        collapsed ? "sideNavMenu-collapsed" : ""
                      }`}
                    >
                      <div className="sideNavMenu-icon">
                        <FontAwesomeIcon
                          icon={faBell}
                          style={{ color: "#ffffff", fontSize: "20px" }}
                        />
                      </div>
                      {!collapsed ? (
                        <div className="sideNavMenu-names">
                          <label className="disbaled-notfiy">
                            Notification
                          </label>
                        </div>
                      ) : null}
                    </div>
                  </Menu.Item>
                )}
                <Menu.Item
                  title="Logout"
                  key="2"
                  data-testid={"2"}
                  className="menu-item-bottom"
                  onClick={() => onClick("2")}
                >
                  <div
                    className={`sideNavMenu-item ${
                      collapsed ? "sideNavMenu-collapsed" : ""
                    }`}
                  >
                    <div className="sideNavMenu-icon">
                      <FontAwesomeIcon
                        icon={faArrowRightFromBracket}
                        style={{ color: "#ffffff", fontSize: "20px" }}
                      />
                    </div>
                    {!collapsed ? (
                      <div className="sideNavMenu-names">
                        <label>Logout</label>
                      </div>
                    ) : null}
                  </div>
                </Menu.Item>
              </div>
            </Menu.ItemGroup>
          </Menu>
        </div>
      </Sider>
    </>
  );
};

NavSideBar.propTypes = {
  routePaths: PropTypes.arrayOf(Object).isRequired,
};

export default NavSideBar;
