/* eslint-disable no-case-declarations */
import {
  ACCEPT_IS_CHECKED,
  CHANGE_CURRENT_STEP,
  CREATE_REPO,
  CREATE_REPO_LOADING,
  CURRENT_EDITING_TEST_DETAILS,
  GET_MCQ,
  GET_MCQ_QUESTION_BANK_QUESTION_LIST,
  GET_QUESTION,
  GET_QUESTION_BANK_QUESTION_LIST,
  ISPREVIEW,
  WIZARD_CLEAR,
  WIZARD_CURRENT_STEP,
  WIZARD_LOADER_FALSE,
  WIZARD_LOADER_TRUE,
  WIZARD_NEXT,
  WIZARD_PREV,
  WIZARD_STEP_FOUR,
  WIZARD_STEP_MCQ_DELETE,
  WIZARD_STEP_THREE,
  WIZARD_STEP_TWO_DELETE,
  WIZARD_STEP_TWO_EDIT,
  WIZARD_STEP_TWO_REARRANGE,
  WIZRAD_STEP_ONE,
  WIZRAD_STEP_TWO,
  WIZRAD_STEP_TWO_ADD,
} from "../actiontypes";

const initialState = {
  currentStep: 0,
  steps: {},
  loading: false,
  isPreview: false,
  repodetails: {},
  isChecked: false,
  isUpdated: false,
  isRepoCreateLoading: false,
  currentEditingTestDetails: undefined,
  currentEditingQuestion: undefined,
  currentQuestionBankQuestionList: undefined,
  currentMCQQuestionBankQuestionList: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case WIZARD_CURRENT_STEP: {
      return {
        ...state,
        currentStep: payload,
      };
    }
    case WIZARD_NEXT:
      return {
        ...state,
        currentStep: state.currentStep + 1,
        isUpdated: true,
      };
    case WIZARD_PREV:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    case CREATE_REPO_LOADING:
      return { ...state, isRepoCreateLoading: payload };
    case WIZRAD_STEP_ONE:
      return {
        ...state,
        steps: { ...state.steps, stepOne: payload },
      };
    case CHANGE_CURRENT_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case WIZRAD_STEP_TWO:
      return {
        ...state,
        steps: {
          ...state.steps,
          stepTwo: payload,
        },
      };
    case WIZARD_STEP_THREE:
      return {
        ...state,
        steps: {
          ...state.steps,
          stepThree: payload,
        },
      };
    case WIZARD_STEP_FOUR:
      //For Edit Stage removing the current ID from stepFour and pushing it to StepFour
      if (payload.id) {
        const StepFourArray = [...(state.steps.stepFour || [])];
        const updatedStepFourArray = StepFourArray.filter(
          (item) => !(item.id && payload.id && item.id === payload.id)
        );
        return {
          ...state,
          steps: {
            ...state.steps,
            stepFour: [...updatedStepFourArray, payload],
          },
        };
      } else {
        //First Draft COurse Load and store in stepFour as an array
        return {
          ...state,
          steps: {
            ...state.steps,
            stepFour: [...payload],
          },
        };
      }
    case WIZRAD_STEP_TWO_ADD:
      const stepTwoData = payload;
      const updatedStepTwo = [...(state.steps.stepTwo || []), stepTwoData];
      return {
        ...state,
        steps: {
          ...state.steps,
          stepTwo: updatedStepTwo,
        },
      };
    case WIZARD_STEP_TWO_EDIT:
      const updatedStepTwoData = payload;
      const updatedStepTwoArray = state.steps.stepTwo.map((item) =>
        (item.key &&
          updatedStepTwoData.key &&
          item.key === updatedStepTwoData.key) ||
        (item.id && updatedStepTwoData.id && item.id === updatedStepTwoData.id)
          ? { ...item, ...updatedStepTwoData }
          : item
      );
      return {
        ...state,
        steps: {
          ...state.steps,
          stepTwo: updatedStepTwoArray,
        },
      };

    case WIZARD_STEP_TWO_DELETE:
      const deletePayload = payload;
      const updatedStepArray = state.steps.stepTwo.filter(
        (item) =>
          !(
            (item.key && deletePayload.key && item.key === deletePayload.key) ||
            (item.id && deletePayload.id && item.id === deletePayload.id)
          )
      );
      return {
        ...state,
        steps: {
          ...state.steps,
          stepTwo: updatedStepArray,
        },
      };
    case WIZARD_STEP_TWO_REARRANGE:
      return {
        ...state,
        steps: {
          ...state.steps,
          stepTwo: payload,
        },
      };
    case WIZARD_LOADER_TRUE:
      return {
        ...state,
        loading: true,
      };

    case WIZARD_LOADER_FALSE:
      return {
        ...state,
        loading: false,
      };

    case ISPREVIEW:
      return { ...state, isPreview: payload };
    case CREATE_REPO:
      return { repodetails: payload };
    case ACCEPT_IS_CHECKED:
      return { ...state, isChecked: payload };
    case WIZARD_CLEAR:
      return {
        currentStep: 0,
        steps: {},
        loading: false,
        isPreview: false,
        repodetails: {},
        isChecked: false,
        isUpdated: false,
      };
    case WIZARD_STEP_MCQ_DELETE:
      const updatedMcqArray = state.steps.stepFour.filter(
        (item) => !(item.id && payload.id && item.id === payload.id)
      );
      return {
        ...state,
        steps: {
          ...state.steps,
          stepFour: updatedMcqArray,
        },
      };
    case CURRENT_EDITING_TEST_DETAILS:
      return {
        ...state,
        currentEditingTestDetails: payload,
      };
    case GET_MCQ:
      return {
        ...state,
        currentEditingQuestions: payload,
      };
    case GET_QUESTION:
      return {
        ...state,
        currentEditingQuestion: payload,
      };
    case GET_QUESTION_BANK_QUESTION_LIST:
      return {
        ...state,
        currentQuestionBankQuestionList: payload,
      };
    case GET_MCQ_QUESTION_BANK_QUESTION_LIST:
      return {
        ...state,
        currentMCQQuestionBankQuestionList: payload,
      };
    default:
      return state;
  }
}
