import PropTypes from "prop-types";
import { get } from "lodash";
import { Input, InputNumber, Select } from "antd";

import "./StyledFields.scss";
import { InfoCircleFilled } from "@ant-design/icons";

export function StyledInputField({
  field,
  form,
  type,
  isRequired,
  isToggle = true,
  ...props
}) {
  const { label } = props;
  const { errors, touched, values, setFieldTouched, handleBlur } = form;
  const { name } = field;
  const Component =
    type === "textarea"
      ? Input.TextArea
      : type === "select"
      ? Select
      : type === "number"
      ? InputNumber
      : type === "comment"
      ? Input.TextArea
      : type === "password"
      ? Input.Password
      : Input;
  const fieldError = get(errors, name);
  const fieldTouched = get(touched, name);

  return (
    <div className="styled-input-field">
      <div className={`label ${isRequired ? "required-label" : ""}`}>
        {label}
      </div>
      <Component
        className="input-styled"
        data-testid={`input-${name}`}
        {...field}
        {...props}
        onBlur={(e) => {
          if (!fieldError && fieldTouched) {
            setFieldTouched(name, false, true);
          } else if (!values[name] && !fieldTouched) {
            handleBlur(e);
          }
        }}
      />
      {isToggle && (
        <span className="form-error-message" data-testid="form-error-message">
          {fieldError && fieldTouched ? (
            <>
              <InfoCircleFilled className="error-info-icon" />{" "}
              <span>{fieldError}</span>
            </>
          ) : null}
        </span>
      )}
    </div>
  );
}

StyledInputField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isToggle: PropTypes.bool.isRequired,
};
