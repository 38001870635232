/* eslint-disable no-unused-vars */
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./QuestionBank.scss";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { faPlus, faRobot } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { genAiAllQuestionBankList } from "../../../store/selectors/genAiSelectors";
import QuestionBankEmptyContainer from "./QuestionBankEmptyContainer";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import QuestionsBankNonEmptyContainer from "./QuestionBankNonEmptyContainer";
import { getQuestionBankList } from "../../../store/actions/genAiActions";
import Spinner from "../../../components/Spinner/Spinner";
import { GEN_AI_ALL_QUESTION_LIST_ID } from "../../../constants";
import { getListLimit } from "../../../store/selectors/listParamsSelectors";
import { updateOffset } from "../../../store/actions/listParamsAction";
import { searchPropsForQuestionBank } from "../../../constants/SearchBarProps";

export const QuestionBankHeader = () => {
  const defaultValue = useSelector((state) =>
    getListLimit(state, GEN_AI_ALL_QUESTION_LIST_ID)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearch = (params) => {
    if (defaultValue) {
      dispatch(
        updateOffset({
          id: GEN_AI_ALL_QUESTION_LIST_ID,
          offset: 0,
        })
      );
      dispatch(
        getQuestionBankList({
          ...params,
          limit: defaultValue,
        })
      );
    }
    searchPropsForQuestionBank.onSearch(params, dispatch);
  };
  const handleAdd = () => {
    navigate("/new-question");
  };
  const items = [
    {
      label: (
        <Link to="/ask-ai/add" className="links-ai">
          <div className="gen-ai-icon-div">
            <FontAwesomeIcon icon={faRobot} style={{ fontSize: "14px" }} />
            Ask AI
          </div>
        </Link>
      ),
      key: "1",
    },
  ];
  return (
    <div className="question-bank-container">
      <div className="qb-header-section">
        <div className="qb-title">Question Bank</div>
        <div className="add-button">
          <Dropdown.Button
            icon={<DownOutlined />}
            trigger={["click"]}
            menu={{
              items,
            }}
            onClick={handleAdd}
          >
            <FontAwesomeIcon icon={faPlus} color="#FFFFFF" />
            Add
          </Dropdown.Button>
        </div>
      </div>
      <div className="qb-searchbar">
        <SearchBar
          filterValues={searchPropsForQuestionBank.filterValues}
          onSearch={(params, _) => {
            handleSearch(params);
          }}
          onClear={(params, _) => {
            handleSearch(params);
          }}
        />
      </div>
    </div>
  );
};

const QuestionBank = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [calledFromParent, setCalledFromParent] = useState(false);

  useEffect(() => {
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }
    dispatch(getQuestionBankList({ ...filters }, setIsLoading));
    setIsLoading(true);
    setCalledFromParent(true);
  }, []);
  const questions = useSelector(genAiAllQuestionBankList);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isEmpty(questions?.results) && !searchParams.toString() ? (
            <QuestionBankEmptyContainer />
          ) : (
            <>
              <QuestionBankHeader />
              <QuestionsBankNonEmptyContainer
                calledFromParent={calledFromParent}
                setCalledFromParent={setCalledFromParent}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionBank;
