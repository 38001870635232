import {
  createHashRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import constants from "../constants";
import LoginPage from "../pages/LoginPage/LoginPage";
import PrivateRoute from "./PrivateRoute";
import { getUserRoutes } from "./userRoutes";
import PublicRoute from "./PublicRoute";
import Layouts from "../components/Layouts/Layouts";
import SetPasswordPage from "../pages/SetPassword/SetPasswordPage";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import SignUp from "../pages/SignupPage/SignUp";

export const appRoutes = (groups) => {
  return createHashRouter(
    createRoutesFromElements(
      <>
        <Route
          exact
          path={constants.LOGIN_PAGE_URL}
          element={<LoginPage />}
        ></Route>
        <Route
          exact
          path={constants.SIGNUP_PAGE_URL_WITH_OTP}
          element={<SetPasswordPage />}
        ></Route>
        <Route
          exact
          path={constants.FORGOT_PASSWORD}
          element={<ForgotPasswordPage />}
        ></Route>
        <Route
          exact
          path={constants.SET_PASSWORD}
          element={<SetPasswordPage />}
        ></Route>
        <Route
          exact
          path={constants.RESET_PASSWORD}
          element={<SetPasswordPage />}
        ></Route>

        <Route
          exact
          path={constants.SIGN_UP_PAGE_URL}
          element={<SignUp />}
        ></Route>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Layouts groups={groups} />}>
            {getUserRoutes(groups)}
          </Route>
        </Route>
        <Route path="/*" element={<PublicRoute />} />
      </>
    )
  );
};
