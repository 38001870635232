import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getQuestionBankList } from "../../../store/actions/genAiActions";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { GEN_AI_ALL_QUESTION_LIST_ID } from "../../../constants";
import {
  getListLimit,
  getListOffset,
} from "../../../store/selectors/listParamsSelectors";
import {
  updateLimit,
  updateOffset,
} from "../../../store/actions/listParamsAction";
import { Checkbox, List } from "antd";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../../components/Pagination/Pagination";
import Spinner from "../../../components/Spinner/Spinner";
import "./QuestionBank.scss";
import { QuestionListItemHeader } from "../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItemHeader";
import { QuestionListItem } from "../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionListItem";
import "../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionList.scss";
import { genAiAllQuestionBankList } from "../../../store/selectors/genAiSelectors";
import QuestionDeleteConfirmModal from "../../../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionDeleteConfirmModal";
const CustomEmptyContent = () => (
  <div className="questionList-container-empty">No results found</div>
);
const QuestionsBankNonEmptyContainer = ({
  calledFromParent,
  setCalledFromParent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const questions = useSelector(genAiAllQuestionBankList);
  /////`
  const [isAllSelected, setIsAllSelected] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const searchListParams = useSelector(
    (state) => state.listParams[GEN_AI_ALL_QUESTION_LIST_ID]
  );
  const [checkedArray, setCheckedArray] = useState([]);
  const defaultValue = useSelector((state) =>
    getListLimit(state, GEN_AI_ALL_QUESTION_LIST_ID)
  );
  const offSetValue = useSelector((state) =>
    getListOffset(state, GEN_AI_ALL_QUESTION_LIST_ID)
  );
  useEffect(() => {
    if (questions !== null) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    setIsLoading(false);
  }, [questions]);
  const totalCount = questions?.count;
  const changeDefaultValue = (value) => {
    setCalledFromParent(false);
    dispatch(
      updateLimit({
        id: GEN_AI_ALL_QUESTION_LIST_ID,
        limit: value[0].label,
        offset: 0,
      })
    );
  };
  const changeOffsetValue = (value) => {
    setCalledFromParent(false);
    dispatch(
      updateOffset({
        id: GEN_AI_ALL_QUESTION_LIST_ID,
        offset: value,
      })
    );
    dispatch(
      getQuestionBankList(
        {
          ...searchListParams,
          limit: defaultValue,
          offset: value,
        },
        setIsLoading
      )
    );
  };
  // will  delete the all questions if the User is the owner of the question
  const onDeleteAll = (questionIds) => {
    let question_list = [];
    questionIds.forEach((id) => {
      questions?.results.forEach((element) => {
        if (element.question_id == id && element.isOwnerOfQuestion)
          question_list.push(id);
      });
    });
    setIsModalOpen(true);
    setData(question_list); // Set the data to the array of objects
  };

  const onDelete = (question_id) => {
    setIsModalOpen(true);
    setData(question_id);
  };

  const loadAllQuestions = () => {
    setIsLoading(true);
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      filters[key] = value;
    }

    dispatch(
      getQuestionBankList(
        {
          ...filters,
          limit: defaultValue,
          offset: offSetValue,
        },
        setIsLoading
      )
    );
  };
  useEffect(() => {
    if (
      !calledFromParent &&
      defaultValue !== undefined &&
      offSetValue !== undefined
    ) {
      setCalledFromParent(false);
      loadAllQuestions();
    }
  }, [defaultValue]);
  useEffect(() => {
    dispatch(
      updateLimit({
        id: GEN_AI_ALL_QUESTION_LIST_ID,
        limit: 10,
        offset: 0,
      })
    );
  }, []);
  // eslint-disable-next-line no-unused-vars
  const successCallback = (respPromise, dispatch, getState) => {
    setIsLoading(true);
    respPromise
      .then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          loadAllQuestions();
          const updatedCheckedArray = checkedArray.filter(
            (id) => !data.questions.includes(id)
          );
          setCheckedArray(updatedCheckedArray);
          setIsModalOpen(false);
          ("");
        }
      })
      .catch(() => {
        setIsModalOpen(false);
        setIsLoading(false);
      });
    setCheckedArray([]);
  };
  const onChange = (e) => {
    const updatedCheckedArray = e.target.checked
      ? [...checkedArray, e.target.value]
      : checkedArray.filter((item) => item !== e.target.value);
    setCheckedArray(updatedCheckedArray);
    setIsAllSelected(
      updatedCheckedArray.length >= 2 ||
        updatedCheckedArray.length === questions?.results.length
    );
  };

  const [checkAll, setCheckAll] = useState(false);

  const onCheckAllChange = (e) => {
    const updatedCheckedArray = e.target.checked
      ? questions?.results.map((item) => item.question_id)
      : [];
    setCheckedArray(updatedCheckedArray);
    setCheckAll(e.target.checked);
    setIsAllSelected(e.target.checked);
  };
  useEffect(() => {
    setCheckAll(checkedArray.length === questions?.results?.length);
  }, [checkedArray, questions]);
  return !isLoading ? (
    <>
      <QuestionDeleteConfirmModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        data={data}
        onsuccesscallback={successCallback}
        loading={isLoading}
        setLoading={setIsLoading}
        setIsModalOpen={setIsModalOpen}
        fromQuestionBankList={true}
        fromDeleteAll={isAllSelected}
      />
      <div className="questionList-container">
        <div className="questionList-searchBar-bank">
          <div className="questionlist-checkbox-bank">
            <Checkbox
              className="question-list-checkbox"
              onChange={onCheckAllChange}
              checked={checkAll}
            />
            <span className="question-list-checkbox-text">
              {checkedArray.length === 0
                ? `Questions (${questions?.results?.length}) `
                : `${checkedArray.length} Selected`}
            </span>
            {checkedArray.length === 0 ? null : (
              <>
                {" "}
                <span className="question-list-checkbox-separator">|</span>
                <span
                  className="question-list-delete-button"
                  onClick={() => onDeleteAll(checkedArray)}
                >
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ color: "black", marginBottom: "1px" }}
                  />
                  <span className="question-list-delete-button-text">
                    Delete
                  </span>
                </span>
              </>
            )}
          </div>
        </div>

        <List
          itemLayout="horizontal"
          dataSource={questions?.results}
          className="questionList-items"
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Checkbox
                    value={item.question_id}
                    onChange={(e) => onChange(e)}
                    checked={checkedArray.includes(item.question_id)}
                  />
                }
                title={
                  <QuestionListItemHeader
                    header={item}
                    index={index}
                    onDelete={onDelete}
                    fromQuestionBank={true}
                  />
                }
                description={
                  <QuestionListItem
                    index={index}
                    question={item}
                    options={item.options}
                    disabled={true}
                    givingTest={false}
                    isQuestionReviewList={true}
                  />
                }
              />
            </List.Item>
          )}
          locale={{ emptyText: <CustomEmptyContent /> }}
        />
        <div className="questionList-pagination">
          <Pagination
            totalCount={totalCount}
            userList={questions}
            defaultValue={defaultValue}
            offSetValue={offSetValue}
            onClickHandlerDefaultValue={changeDefaultValue}
            onClickHandlerOffSetValue={changeOffsetValue}
          />
        </div>
      </div>
    </>
  ) : (
    <div className="questionReviewList">
      <Spinner />
    </div>
  );
};

export default QuestionsBankNonEmptyContainer;
