import GlobalEmptyContainer from "../../../../components/GlobalEmptyContainer/GlobalEmptyContainer";
import { ReactComponent as QuestionManagerEmptyConatainer } from "../../../../assets/QuestionManagerEmptyConatainer.svg";
import "./QuestionManagerMcq.scss";
const QuestionEmptyContainer = ({ buttonProps, isQuestionBank = false }) => {
  return (
    <div className="empty-container-question">
      {isQuestionBank ? null : (
        <h3 className="QME-heading">Question Manager</h3>
      )}{" "}
      <div className="QMEContainer">
        <GlobalEmptyContainer
          imgContent={
            <QuestionManagerEmptyConatainer
              style={{
                width: "410px",
                height: "350px",
              }}
              className="QME-img"
            />
          }
          content={
            <div className="question-manager-empty-content">
              <h3 className="question-manager-empty-heading">
                You don’t have any questions yet
              </h3>
              <span className="question-manager-empty-para">
                Click Add question to start with your first question
              </span>
            </div>
          }
          actionButton={buttonProps}
          isQuestionBank={isQuestionBank}
        />
      </div>
    </div>
  );
};

export default QuestionEmptyContainer;
