import { actionFactory } from "../../libs/actionFactory";
import {
  CREATE_QUESTION,
  GET_ALL_QUESTION_BANK_QUESTION_LIST,
  PDF_SUBMITTED,
  QUESTION_BANK_QUESTION_LIST,
  SET_JOB_SELECTED,
} from "../actiontypes";

export const generateQuestionsFromPdf = (
  file,
  user,
  tags,
  questionType,
  setIsModalOpen
) => {
  // tags = []
  // Tags should be array of Strings e.g
  // tags: ["POSH", "referral", "COMPANY policy"]
  return actionFactory({
    api: `post/api/gen-ai/${user}/generate-questions/`,
    failureToast: true,
    actionBody: {
      body: {
        file: file,
        tags: tags,
        question_type: questionType,
      },
    },
    multipartReq: true,
    successToast: "PDF Submitted Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: PDF_SUBMITTED, payload: resp["data"].data });
            setIsModalOpen(true);
          }
        })
        .catch((_) => {
          setIsModalOpen(false);
        });
    },
  });
};

export const getQuestionsFromPdf = (mcqId, params) => {
  return actionFactory({
    api: `get/api/gen-ai/${mcqId}/get_questions_list/`,
    failureToast: true,
    actionBody: {
      params,
    },
    multipartReq: false,
    successToast: "",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: QUESTION_BANK_QUESTION_LIST,
              payload: resp["data"].data,
            });
          }
        })
        .catch((_) => {});
    },
  });
};

export const updateQuestionsInQuestionBank = (mcqId, data, callback) => {
  return actionFactory({
    api: `patch/api/gen-ai/${mcqId}/questions-review/`,
    failureToast: true,
    actionBody: {
      body: { ...data },
    },
    multipartReq: false,
    successToast: "",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) =>
      callback(respPromise, dispatch, getState),
  });
};

export const createQuestioninQuestionBank = (
  params,
  setSaveButtonLoader,
  isEditPage
) => {
  return actionFactory({
    api: "post/api/gen-ai/question-bank/create-question/",
    failureToast: true,
    actionBase: CREATE_QUESTION,
    actionBody: {
      body: { ...params },
    },
    multipartReq: false,
    successToast: isEditPage
      ? "Question Edited Successfully"
      : "Question Added Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            setSaveButtonLoader(false);
            dispatch(getQuestionBankList());
          }
        })
        .catch((_) => {});
    },
  });
};

export const searchTag = (setSuggestionsTags) => {
  return actionFactory({
    api: "get/api/gen-ai/tags/",
    failureToast: true,
    actionBody: {},
    multipartReq: false,
    successToast: "",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            const formattedTags = resp["data"].data.map((tag) => ({
              id: tag.id,
              text: tag.tag_name,
            }));
            setSuggestionsTags(formattedTags);
          }
        })
        .catch((_) => {});
    },
  });
};

export const questionReviewOfJob = (
  mcqId,
  setIsJobSelected,
  setLoading,
  onCallBack
) => {
  return actionFactory({
    api: `post/api/gen-ai/${mcqId}/done/`,
    failureToast: true,
    actionBody: {},
    multipartReq: false,
    successToast: "",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            setLoading(false);
            dispatch({
              type: SET_JOB_SELECTED,
              payload: false,
            });
            onCallBack();
          }
        })
        .catch((_) => {});
    },
  });
};

export const deleteQuestionsInQuestionBank = (data, callback) => {
  return actionFactory({
    api: "delete/api/gen-ai/delete-questions/",
    failureToast: true,
    actionBody: {
      body: data,
    },
    multipartReq: false,
    successToast: "",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) =>
      callback(respPromise, dispatch, getState),
  });
};

export const getQuestionBankList = (params = {}, setIsLoading = () => {}) => {
  return actionFactory({
    api: "get/api/gen-ai/get-all-question-bank-list",
    actionBody: { params: { ...params } },
    actionBase: GET_ALL_QUESTION_BANK_QUESTION_LIST,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch({
            type: GET_ALL_QUESTION_BANK_QUESTION_LIST,
            payload: resp["data"].data,
          });
          setIsLoading(false);
        }
      });
    },
  });
};
