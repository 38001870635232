const authSelector = (state) => {
  return state.auth;
};

export const isLoggedInSelector = (state) => {
  return authSelector(state).isLoggedIn;
};

export const userSelector = (state) => {
  return authSelector(state).user;
};

export const roleSelector = (state) => {
  return authSelector(state)?.user?.role;
};
export const groupSelector = (state) => {
  return authSelector(state)?.user?.groups;
};

export const groupArraySelector = (state) => {
  let groupsArr = [];
  let groups = authSelector(state)?.user?.groups;
  groups?.forEach((group) => {
    groupsArr.push(group.name);
  });
  return groupsArr;
};
export const isCancelledSelector = (state) => {
  return authSelector(state).isCancel;
};

export const loginFormSelector = (state) => {
  return authSelector(state).clientId;
};

export const userEmailSelector = (state) => {
  return authSelector(state).userMail;
};
