import "./AuthenticationLdap.scss";
import Breadcrumbs from "../../../../components/BreadCrumb/BreadCrumbs";
import { adminSettingConstants } from "../../../../libs/constants";
import { Button, Switch } from "antd";
import { Field, Form, Formik } from "formik";
import { StyledInputField } from "../../../../components/StyledFields/StyledInputField";
import Spinner from "../../../../components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAuthLdapSetting,
  postAuthLdapSetting,
} from "../../../../store/actions/adminActions";
import { useSelector } from "react-redux";
import { LdapSettingUtils } from "../../AdminSettingsUtils";
import {
  LDAP_AUTH_DESC_LINKS,
  LDAP_AUTH_DESC_MESSAGE,
} from "../../../../constants";
import { getLdapAuthSetting } from "../../../../store/selectors/adminSelectors";
import withPrompt from "../../../../hooks/withPrompt";
import { setSaveButtonDisabled } from "../../../../store/actions/adminActions";
import { isSaveButtonDisabledSelector } from "../../../../store/selectors/adminSelectors";

const AuthenticationLdap = () => {
  const omittedPositions = [0];
  const [isLoading, setIsLoading] = useState(true);
  const [isLdapAuth, setIsLdapAuth] = useState(true);
  const [isAuthLdapLoading, setIsAuthLdapLoading] = useState(false);
  const currentAdminLdapSetting = useSelector(getLdapAuthSetting);
  const [saveButtonLoader, setSaveButtonLoader] = useState(false);
  const [isNotAcceptedLDAP, setIsNotAcceptedLDAP] = useState(false);
  const dispatch = useDispatch();
  const isSaveButtonDisabled = useSelector(isSaveButtonDisabledSelector);

  const INITIAL_FORM_VALUES = {
    ldap_port: currentAdminLdapSetting?.ldap_port || "",
    ldap_server_uri: currentAdminLdapSetting?.ldap_server_uri || "",
    ldap_bind_dn: currentAdminLdapSetting?.ldap_bind_dn || "",
    ldap_base_dn: currentAdminLdapSetting?.ldap_base_dn || "",
  };
  const [initialValues, setInitialValues] = useState(INITIAL_FORM_VALUES);
  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);

  useEffect(() => {
    setInitialValues(INITIAL_FORM_VALUES);
    setFormValues(INITIAL_FORM_VALUES);
  }, [currentAdminLdapSetting]);
  useEffect(() => {
    const isChanged =
      formValues.ldap_host !== initialValues.ldap_host ||
      formValues.ldap_port !== initialValues.ldap_port ||
      formValues.ldap_base_dn !== initialValues.ldap_base_dn ||
      formValues.ldap_bind_dn !== initialValues.ldap_bind_dn;
    dispatch(setSaveButtonDisabled(!isChanged));
  }, [formValues, initialValues, dispatch]);

  const handleSwitchChange = () => {
    setIsLdapAuth(!isLdapAuth);
    setIsNotAcceptedLDAP(false);
    const newValues = {
      ...currentAdminLdapSetting,
      allow_ldap_login: !isLdapAuth ? "YES" : "NO",
    };
    setIsAuthLdapLoading(true);
    dispatch(
      postAuthLdapSetting(
        newValues,
        setIsAuthLdapLoading,
        setIsNotAcceptedLDAP,
        () => {}
      )
    );
  };
  useEffect(() => {
    if (isNotAcceptedLDAP) {
      setIsLdapAuth(true);
    }
  }, [isNotAcceptedLDAP]);

  const onSave = (values) => {
    setSaveButtonLoader(true);
    delete values.allow_ldap_login;
    const newValues = {
      allow_ldap_login: isLdapAuth ? "YES" : "NO",
      ...values,
    };
    dispatch(postAuthLdapSetting(newValues, setSaveButtonLoader));
  };

  useEffect(() => {
    dispatch(getAuthLdapSetting(setIsLoading, setIsLdapAuth));
  }, []);

  const LdapFieldDescription = ({ isLdapAuth, description, link }) => {
    return (
      <span
        className={`ldap-field-description${!isLdapAuth ? "-disabled" : ""}`}
      >
        {description}
        {link && (
          <a href={link} target="_blank" rel="noopener noreferrer">
            Learn more
          </a>
        )}
      </span>
    );
  };
  const handleFormChange = (values) => {
    setFormValues(values);
  };
  return isLoading || !currentAdminLdapSetting ? (
    <Spinner />
  ) : (
    <div className="authentication-ldap-container">
      <Breadcrumbs omit={omittedPositions} />
      <div className="authentication-ldap-heading">
        <div>
          LDAP
          <p>{adminSettingConstants["LDAP_TEXT"]}</p>
        </div>
        <div className={isAuthLdapLoading ? "spinner_blank_ldap_login" : ""}>
          {!isAuthLdapLoading ? (
            <Switch
              onClick={handleSwitchChange}
              checked={isLdapAuth}
              size="small"
              style={{ backgroundColor: isLdapAuth ? "#1A2B3C" : "#D3D3D3" }}
            />
          ) : (
            <>
              <Spinner small={"blank"} />
            </>
          )}
        </div>
      </div>
      <Formik
        initialValues={INITIAL_FORM_VALUES}
        validationSchema={LdapSettingUtils}
        enableReinitialize
        isInitialValid={false}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form
              className="ldap-setting-form"
              onChange={() => handleFormChange(values)}
            >
              <div className="ldapsetting-form-container">
                <div>
                  <h4
                    className={`ldap-configuration-heading${
                      !isLdapAuth ? "-disabled" : ""
                    }`}
                  >
                    Configuration
                  </h4>
                  <div className="admin-ldap-details">
                    <div className="field-container">
                      <div>
                        <Field
                          disabled={!isLdapAuth}
                          label={
                            <span
                              style={{
                                color: !isLdapAuth ? "#B5B5B5" : "",
                              }}
                            >
                              Port
                            </span>
                          }
                          type="text"
                          name="ldap_port"
                          placeholder="example: localhost"
                          component={StyledInputField}
                          onChange={(event) => {
                            setFieldValue("ldap_port", event.target.value);
                            handleFormChange({
                              ...values,
                              ldap_port: event.target.value,
                            });
                          }}
                          isToggle={isLdapAuth}
                        />
                        <LdapFieldDescription
                          isLdapAuth={isLdapAuth}
                          description={LDAP_AUTH_DESC_MESSAGE?.LDAP_PORT}
                          link={LDAP_AUTH_DESC_LINKS?.LDAP_PORT_LINK}
                        />
                      </div>
                      <div>
                        <Field
                          disabled={!isLdapAuth}
                          label={
                            <span
                              style={{
                                color: !isLdapAuth ? "#B5B5B5" : "",
                              }}
                            >
                              Server URI
                            </span>
                          }
                          type="text"
                          name="ldap_server_uri"
                          onChange={(event) => {
                            setFieldValue("ldap_port", event.target.value);
                            handleFormChange({
                              ...values,
                              ldap_port: event.target.value,
                            });
                          }}
                          component={StyledInputField}
                          isToggle={isLdapAuth}
                          placeholder="Enter the server LDAP server uri"
                        />
                        <LdapFieldDescription
                          isLdapAuth={isLdapAuth}
                          description={LDAP_AUTH_DESC_MESSAGE?.LDAP_SERVER_URI}
                          link={LDAP_AUTH_DESC_LINKS?.LDAP_SERVER_URI_LINK}
                        />
                      </div>
                    </div>
                    <div className="field-container">
                      <div>
                        <Field
                          disabled={!isLdapAuth}
                          label={
                            <span
                              style={{
                                color: !isLdapAuth ? "#B5B5B5" : "",
                              }}
                            >
                              Bind DN
                            </span>
                          }
                          type="text"
                          name="ldap_bind_dn"
                          placeholder="Enter LDAP bind dn"
                          component={StyledInputField}
                          onChange={(event) => {
                            setFieldValue("ldap_port", event.target.value);
                            handleFormChange({
                              ...values,
                              ldap_port: event.target.value,
                            });
                          }}
                          isToggle={isLdapAuth}
                        />
                        <LdapFieldDescription
                          isLdapAuth={isLdapAuth}
                          description={LDAP_AUTH_DESC_MESSAGE?.LDAP_BIND_DN}
                          link={LDAP_AUTH_DESC_LINKS?.LDAP_BIND_DN_LINK}
                        />
                      </div>
                      <div>
                        <Field
                          disabled={!isLdapAuth}
                          label={
                            <span
                              style={{
                                color: !isLdapAuth ? "#B5B5B5" : "",
                              }}
                            >
                              Base DN
                            </span>
                          }
                          type="text"
                          name="ldap_base_dn"
                          placeholder="Enter LDAP base dn"
                          component={StyledInputField}
                          isToggle={isLdapAuth}
                          onChange={(event) => {
                            setFieldValue("ldap_port", event.target.value);
                            handleFormChange({
                              ...values,
                              ldap_port: event.target.value,
                            });
                          }}
                        />
                        <LdapFieldDescription
                          isLdapAuth={isLdapAuth}
                          description={LDAP_AUTH_DESC_MESSAGE?.LDAP_BASE_DN}
                          link={LDAP_AUTH_DESC_LINKS?.LDAP_BASE_DN_LINK}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="admin-ldap-buttons">
                  <Button
                    className="admin-ldap-savebutton"
                    disabled={isSaveButtonDisabled}
                    loading={saveButtonLoader}
                    onClick={() => onSave(values)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default withPrompt(AuthenticationLdap);
