/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { actionFactory } from "../../libs/actionFactory";
import {
  ALL_USERS,
  ARCHIVE_MULTIPLE_USERS,
  FETCH_LLM_DETAILS_FAILURE,
  FETCH_LLM_DETAILS_SUCCESS,
  FETCH_LLMS_FAILURE,
  FETCH_LLMS_SUCCESS,
  GET_GENERAL_SETTINGS,
  LOGIN_FAIL,
  POST_LLM_DETAILS_FAILURE,
  POST_LLM_DETAILS_SUCCESS,
  SAVED_AUTH_GOOGLE_SETTING,
  SAVED_AUTH_LDAP_SETTING,
  SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING,
  SAVED_EMAIL_SETTING,
  SELECTED_USER,
  SET_SAVE_BUTTON_DISABLED,
} from "../actiontypes/index";
export const setSaveButtonDisabled = (isDisabled) => ({
  type: SET_SAVE_BUTTON_DISABLED,
  payload: isDisabled,
});

export const getAllUserDetails = (params = {}, callback) => {
  return actionFactory({
    api: "get/api/users/",
    actionBody: {
      params: { ...params },
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: ALL_USERS,
              payload: resp["data"].data,
            });
          }
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAIL,
          });
        });
    },
  });
};

export const updateUser = (id, user, successCallback) => {
  return actionFactory({
    api: `patch/api/users/${id}/`,
    actionBody: {
      body: { ...user },
    },
    failureToast: true,
    successToast: `${user.user_name} has been successfully updated.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getAllUserDetails());
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const archiveUser = (id, user, successCallback) => {
  return actionFactory({
    api: `delete/api/users/${id}/`,
    actionBody: {
      body: user,
    },
    failureToast: true,
    successToast: `User ${user.user_name} has been successfully archived.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch(getAllUserDetails());
            successCallback();
          }
        })
        .catch((_err) => {});
    },
  });
};

export const archiveMultipleUser = (
  userIds,
  limit,
  offset,
  searchListParams,
  onsuccesscallback
) => {
  return actionFactory({
    api: `post/api/users/archive/`,
    failureToast: true,
    actionBody: {
      body: userIds,
    },
    successToast: "Selected users have been successfully archived",
    callback: (respPromise, dispatch) => {
      respPromise.then((resp) => {
        if (resp["data"].status.status_type === "SUCCESS") {
          dispatch({
            type: ARCHIVE_MULTIPLE_USERS,
          });
          dispatch(
            getAllUserDetails({
              ...searchListParams,
              limit: limit,
              offset: offset,
            })
          );
          onsuccesscallback();
        }
      });
    },
  });
};

export const updateEmployeeId = (
  id,
  userData,
  successCallback,
  setIsLoading
) => {
  return actionFactory({
    api: `patch/api/users/${id}/`,
    actionBody: {
      body: userData,
    },
    failureToast: true,
    successToast: `Details successfully updated.`,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            successCallback();
          }
        })
        .catch((_err) => {
          setIsLoading(false);
        });
    },
  });
};

export const selectedUserDetails = (id) => {
  return actionFactory({
    api: `get/api/users/${id}`,
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({ type: SELECTED_USER, payload: resp["data"].data });
          }
        })
        .catch((_err) => {});
    },
  });
};
export const loadAuthoredEnrolledCount = (id, setData) => {
  return actionFactory({
    api: `get/api/users/${id}/authored_enrolled_count/`,
    actionBody: {},
    failureToast: true,
    actionBase: {},
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          setData(resp["data"].data);
        })
        .catch((_) => {});
    },
  });
};

export const exportData = (onsuccesscallback) => {
  return actionFactory({
    api: "post/api/tests/export/",
    actionBody: {},
    failureToast: true,
    successToast: "Export Successfull",
    blobResponseType: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp?.status === 200) {
            const file = new Blob([resp.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            // Create a URL for the Blob object
            const fileUrl = window.URL.createObjectURL(file);
            // Create an anchor element to trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = fileUrl;
            downloadLink.setAttribute("download", "Mcq_Details_Sheet.xlsx"); // Set the filename here
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            onsuccesscallback();
          }
        })
        .catch((_) => {
          onsuccesscallback();
        });
    },
  });
};

export const downloadInvitationTemplateSheet = (param, onsuccesscallback) => {
  return actionFactory({
    api: `post/api/tests/download/invitation_template/${param}`,
    actionBody: {},
    failureToast: true,
    successToast: "Download Successfull",
    blobResponseType: true,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp?.status === 200) {
            const file = new Blob([resp.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            // Create a URL for the Blob object
            const fileUrl = window.URL.createObjectURL(file);
            // Create an anchor element to trigger the download
            const downloadLink = document.createElement("a");
            downloadLink.href = fileUrl;
            downloadLink.setAttribute("download", "Invitation_Template.xlsx"); // Set the filename here
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            onsuccesscallback();
          }
        })
        .catch((_) => {
          onsuccesscallback();
        });
    },
  });
};

export const inviteMultipleUsersFromSheet = (file, onSuccessCallBack) => {
  return actionFactory({
    api: `post/api/users/signup/invite/`,
    failureToast: true,
    actionBody: {
      body: {
        file: file,
      },
    },
    multipartReq: true,
    successToast: "Excel Sheet Uploaded Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          onSuccessCallBack();
        })
        .catch((_) => {
          onSuccessCallBack();
        });
    },
  });
};

export const postAuthLdapSetting = (
  values,
  setSaveButtonLoader,
  setIsNotAcceptedLDAP
) => {
  return actionFactory({
    api: `post/api/config/ldap/configurations`,
    failureToast: true,
    actionBody: {
      body: {
        ...values,
      },
    },
    multipartReq: false,
    successToast: "Configurations updated",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_LDAP_SETTING,
            payload: values,
          });
          setSaveButtonLoader(false);
          setIsNotAcceptedLDAP(false);
        })
        .catch((error) => {
          setSaveButtonLoader(false);
          if (error.request.status === 406) {
            setIsNotAcceptedLDAP(true);
          }
        });
    },
  });
};

export const getAuthLdapSetting = (setIsLoading, setIsLdapAuth) => {
  return actionFactory({
    api: `get/api/config/ldap/configurations`,
    failureToast: true,
    multipartReq: false,
    successToast: false,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_LDAP_SETTING,
            payload: resp["data"].data,
          });
          setIsLdapAuth(
            resp["data"].data.allow_ldap_login === "NO" ? false : true
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
};

export const addEmailSetting = (
  values,
  setSaveButtonLoader,
  setSaveButtonDisabled
) => {
  return actionFactory({
    api: `post/api/config/email/configuration/`,
    failureToast: true,
    actionBody: {
      body: values,
    },
    multipartReq: false,
    successToast: "Email Setting added Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_EMAIL_SETTING,
            payload: values,
          });
          setSaveButtonLoader(false);
          dispatch(setSaveButtonDisabled(true));
        })
        .catch((_) => {
          setSaveButtonLoader(false);
        });
    },
  });
};

export const getEmailSetting = (setIsLoading) => {
  return actionFactory({
    api: `get/api/config/email/configuration/`,
    failureToast: true,
    actionBody: {},
    multipartReq: false,
    successToast: false,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({ type: SAVED_EMAIL_SETTING, payload: resp["data"].data });
          setIsLoading(false);
        })
        .catch((_) => {
          setIsLoading(false);
        });
    },
  });
};

export const sendTestEmail = (values, sendTestMailLoader) => {
  return actionFactory({
    api: `post/api/config/send/test/mail/`,
    failureToast: true,
    actionBody: {
      body: values,
    },
    multipartReq: false,
    successToast: "Email Sent Successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          sendTestMailLoader(false);
        })
        .catch((_) => {
          sendTestMailLoader(false);
        });
    },
  });
};

export const enrollIntoSingleCourse = (file, course_id, onsuccesscallback) => {
  return actionFactory({
    api: `post/api/courses/${course_id}/enroll/bulk_enroll_user/`,
    failureToast: true,
    actionBody: {
      body: {
        file: file,
      },
    },
    multipartReq: true,
    successToast: "Excel Sheet Uploaded Successfully",
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          onsuccesscallback();
        })
        .catch((_) => {
          onsuccesscallback();
        });
    },
  });
};

export const fetchLLMs = () => {
  return actionFactory({
    api: "get/api/config/ai/preferred-llms/",
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp["data"].status.status_type === "SUCCESS") {
            dispatch({
              type: FETCH_LLMS_SUCCESS,
              payload: resp["data"].data,
            });
          } else {
            dispatch({
              type: FETCH_LLMS_FAILURE,
              payload: resp["data"].status.status_message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_LLMS_FAILURE,
            payload: error.message,
          });
        });
    },
  });
};
export const postLLMDetails = (
  name,
  token,
  setSaveButtonLoader,

  handleError = () => {}
) => {
  if (!name || !token) {
    setSaveButtonLoader(false);
    return;
  }
  return actionFactory({
    api: "post/api/config/ai/configuration/",
    failureToast: true,
    successToast: "Configurations updated",
    actionBody: {
      body: { name, token },
    },
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.status &&
            resp.data.status.status_type === "SUCCESS"
          ) {
            dispatch({
              type: POST_LLM_DETAILS_SUCCESS,
              payload: { name, token },
            });
          } else {
            const errorMessage =
              resp.data.status?.status_message || "Unknown error occurred.";
            dispatch({
              type: POST_LLM_DETAILS_FAILURE,
              payload: errorMessage,
            });
            handleError();
          }
          setSaveButtonLoader(false);
        })
        .catch((error) => {
          dispatch({
            type: POST_LLM_DETAILS_FAILURE,
            payload: error.message,
          });
          handleError();
          setSaveButtonLoader(false);
        });
    },
  });
};
export const fetchLLMDetails = () => {
  return actionFactory({
    api: "get/api/config/ai/configuration/",
    failureToast: true,
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          if (resp.data.status.status_type === "SUCCESS") {
            dispatch({
              type: FETCH_LLM_DETAILS_SUCCESS,
              payload: resp["data"].data,
            });
          } else {
            dispatch({
              type: FETCH_LLM_DETAILS_FAILURE,
              payload: resp.data.status.status_message,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_LLM_DETAILS_FAILURE,
            payload: error.message,
          });
        });
    },
  });
};

export const getAuthGoogleSetting = (setIsLoading, setIsGoogleAuth) => {
  return actionFactory({
    api: `get/api/config/google/configurations`,
    failureToast: true,
    actionBody: {},
    multipartReq: false,
    successToast: false,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_GOOGLE_SETTING,
            payload: resp["data"].data,
          });
          setIsGoogleAuth(
            resp["data"].data.allow_google_login === "NO" ? false : true
          );
          setIsLoading(false);
        })
        .catch((_) => {
          setIsLoading(false);
        });
    },
  });
};

export const postAuthGoogleSetting = (
  data,
  setIsNotAcceptedGoogle,
  setSaveButtonLoader
) => {
  return actionFactory({
    api: `post/api/config/google/configurations/`,
    failureToast: true,
    actionBody: {
      body: {
        ...data,
      },
    },
    successToast: "Configurations updated",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch, getState) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_GOOGLE_SETTING,
            payload: data,
          });
          setSaveButtonLoader(false);
          setIsNotAcceptedGoogle(false);
        })
        .catch((error) => {
          if (error.request.status === 406) {
            setIsNotAcceptedGoogle(true);
          }
          setSaveButtonLoader(false);
        });
    },
  });
};

export const getAuthPasswordBasedLoginSetting = (
  setIsAuthLoading,
  setPasswordChecked
) => {
  return actionFactory({
    api: `get/api/config/password_login/configurations`,
    failureToast: true,
    actionBody: {},
    successToast: false,
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING,
            payload: resp["data"].data,
          });
          setPasswordChecked(
            resp["data"].data.allow_password_login === "YES" ? true : false
          );
          setIsAuthLoading(false);
        })
        .catch((_) => {
          setIsAuthLoading(false);
        });
    },
  });
};
export const postAuthPasswordBasedSetting = (
  data,
  setToggleLoading,
  setIsNotAccepted
) => {
  return actionFactory({
    api: `post/api/config/password_login/configurations`,
    failureToast: true,
    actionBody: {
      body: { allow_password_login: data },
    },
    successToast: "Password based setting added successfully",
    // eslint-disable-next-line no-unused-vars
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          dispatch({
            type: SAVED_AUTH_PASSWORD_BASED_LOGIN_SETTING,
            payload: data,
          });
          setToggleLoading(true);
          setIsNotAccepted(false);
        })
        .catch((error) => {
          if (error.request.status === 406) {
            setIsNotAccepted(true);
          }
          setToggleLoading(true);
        });
    },
  });
};
export const GetGeneralSettingInstance = (
  setLoading,
  handleError = () => {}
) => {
  return actionFactory({
    api: "get/api/config/general/configuration/",
    failureToast: true,
    actionBody: {},
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp.data.status.status_type === "SUCCESS") {
            setLoading(false);
            dispatch({
              type: GET_GENERAL_SETTINGS,
              payload: resp["data"].data,
            });
          }
        })
        .catch((error) => {});
    },
  });
};
export const PostGeneralSettings = (
  instance_name,
  instance_email,
  instance_logo,
  setSaving,
  handleError = () => {}
) => {
  const actionBody = {
    body: {
      ...(instance_name && { instance_name }),
      ...(instance_email && { instance_email }),
      ...(instance_logo && { instance_logo }),
    },
  };

  return actionFactory({
    api: "post/api/config/general/configuration/",
    multipartReq: true,
    failureToast: true,
    successToast: "General settings changed successfully",
    actionBody,
    callback: (respPromise, dispatch) => {
      respPromise
        .then((resp) => {
          if (resp.data.status.status_type === "SUCCESS") {
            setSaving(false);
            dispatch({
              type: GET_GENERAL_SETTINGS,
              payload: resp["data"].data,
            });
          }
        })
        .catch((error) => {
          setSaving(false);
          handleError(error);
        });
    },
  });
};
