import { Navigate } from "react-router-dom";
import CoursePage from "../pages/CourseDetailsPage/CoursePage";
import CourseListPage from "../pages/CourseListPage/CourseListPage";
import UserDashboardPage from "../pages/UserDashboardPage/UserDashboardPage";
import AdminUserPage from "../pages/AdminUserPage/AdminUserPage";
import UserProfilePage from "../pages/UserProfilePage/UserProfilePage";
import McqTestCreation from "../features/CourseListFeature/WizardCourseSteps/McqStep/McqTestCreation";
import McqPreviewMode from "../features/CourseListFeature/WizardCourseSteps/McqStep/McqPreviewMode";
import QuestionForm from "../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionForm";
import PageNotFoundComp from "../pages/PageNotFound/PageNotFoundComp";
import CandidateTestContainer from "../components/TestContent/CandidateTestContainer";
import QuestionBankManager from "../features/CourseListFeature/WizardCourseSteps/McqStep/QuestionBankManager";
import AskAI from "../pages/Gen-AI/Ask-AI/AskAI";
import QuestionBank from "../pages/Gen-AI/QuestionBank/QuestionBank";
import AuthenticationSettingPage from "../pages/AdminSettingsPage/AuthenticationSetting/AuthenticationSettingPage";
import AuthenticationLdap from "../pages/AdminSettingsPage/AuthenticationSetting/Ldap/AuthenticationLdap";
import GeneralSettingPage from "../pages/AdminSettingsPage/GeneralSetting/GeneralSettingPage";
import AISettingPage from "../pages/AdminSettingsPage/AISetting/AISettingPage";
import EmailSettingPageContainer from "../pages/AdminSettingsPage/EmailSetting/EmailSettingPageContainer";
import AuthenticationGoogleContainer from "../pages/AdminSettingsPage/AuthenticationSetting/Google/AuthenticationGoogleContainer";
// import { Empty } from "antd";

export const commonRoute = [
  {
    path: "/",
    element: <UserDashboardPage />,
  },
  {
    path: "/*",
    element: <Navigate to="/login" />,
  },
  {
    path: "courses",
    element: <CourseListPage />,
  },
  {
    path: "courses/:id/content/",
    element: <CoursePage />,
  },
  {
    path: "courses/:id/course/",
    element: <CoursePage />,
  },
  {
    path: "/404",
    element: <PageNotFoundComp />,
  },
  {
    path: "/tests/:id/start/:id/",
    element: <CandidateTestContainer />,
  },
];
export const portalAdminRoute = [
  {
    path: "/",
    element: <h1>IN PROGRESS ...</h1>,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },

  {
    path: "/courses/:id/candidate/",
    element: <CoursePage />,
  },
  {
    path: "users",
    element: <AdminUserPage />,
  },
  {
    path: "/users/:id/authored",
    element: <UserProfilePage />,
  },
  {
    path: "/question-bank",
    element: <QuestionBank />,
  },

  {
    path: "/Ask-ai/add",
    element: <AskAI />,
  },
  {
    path: "/Ask-ai/jobs",
    element: <AskAI />,
  },
  {
    path: "/new-question",
    element: <QuestionForm />,
  },
  {
    path: "/Ask-ai/jobs/:name",
    element: <AskAI />,
  },
  {
    path: "/users/:id/enrolled",
    element: <UserProfilePage />,
  },
  {
    path: "/edit-question",
    element: <QuestionForm />,
  },
  {
    path: "/admin-settings/general",
    element: <GeneralSettingPage />,
  },
  {
    path: "/admin-settings/email",
    element: <EmailSettingPageContainer />,
  },
  {
    path: "/admin-settings/authentication",
    element: <AuthenticationSettingPage />,
  },
  {
    path: "/admin-settings/authentication/ldap",
    element: <AuthenticationLdap />,
  },
  {
    path: "/admin-settings/authentication/google",
    element: <AuthenticationGoogleContainer />,
  },
  {
    path: "/admin-settings/aritifical-intelligence",
    element: <AISettingPage />,
  },
];
export const creatorRoute = [
  {
    path: "/",
    element: <UserDashboardPage />,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/create-mcq/",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/preview",
    element: <McqPreviewMode />,
  },
  {
    path: "/courses/:id/test/:id/details",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/manager",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/manager?id=",
    element: <QuestionForm />,
  },
  {
    path: "/courses/:id/test/:id/manager?questionbank=true",
    element: <QuestionBankManager />,
  },
];
export const hRRoute = [
  {
    path: "/",
    element: <h1>IN PROGRESS ...</h1>,
  },
  {
    path: "/courses/all",
    element: <CourseListPage />,
  },
  {
    path: "/courses/owned",
    element: <CourseListPage />,
  },
  {
    path: "/question-bank",
    element: <QuestionBank />,
  },

  {
    path: "/courses/approval",
    element: <CourseListPage />,
  },
  {
    path: "/courses/:id/preview",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/statistics/",
    element: <CoursePage />,
  },
  {
    path: "/courses/:id/candidate/",
    element: <CoursePage />,
  },
  {
    path: "users",
    element: <AdminUserPage />,
  },
  {
    path: "/users/:id/authored",
    element: <UserProfilePage />,
  },
  {
    path: "/courses/:id/test/:id/manager",
    element: <McqTestCreation />,
  },
  {
    path: "/courses/:id/test/:id/manager?id=",
    element: <QuestionForm />,
  },
  {
    path: "/courses/:id/test/:id/manager?questionbank=true",
    element: <QuestionBankManager />,
  },
  {
    path: "/users/:id/enrolled",
    element: <UserProfilePage />,
  },
  {
    path: "/Ask-ai",
    element: <AskAI />,
  },

  {
    path: "/Ask-ai/add",
    element: <AskAI />,
  },
  {
    path: "/Ask-ai/jobs",
    element: <AskAI />,
  },
  {
    path: "/Ask-ai/jobs/:name",
    element: <AskAI />,
  },
  {
    path: "/edit-question",
    element: <QuestionForm />,
  },
];
