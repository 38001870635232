import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./QuestionBankButton.scss";
import {
  faCheckCircle,
  faMinusCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "antd";

const QuestionBankButton = ({
  buttonType,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  let buttonContent, buttonClass, buttonIcon;

  switch (buttonType) {
    case "Add To Test":
      buttonContent = "Add To Test";
      buttonClass = "buttonAdd";
      buttonIcon = <FontAwesomeIcon icon={faPlusCircle} />;
      break;
    case "Added":
      buttonContent = "Added";
      buttonClass = "buttonAdded";
      buttonIcon = <FontAwesomeIcon icon={faCheckCircle} color="#3AA738" />;
      break;
    case "Remove":
      buttonContent = "Remove from test";
      buttonClass = "buttonRemove";
      buttonIcon = <FontAwesomeIcon icon={faMinusCircle} color="#CD4444" />;
      break;
    default:
      buttonContent = "Default Button";
      buttonClass = "defaultButton";
      break;
  }

  return (
    <Button
      className={buttonClass}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {buttonIcon && <span className="buttonIcon">{buttonIcon}</span>}
      {buttonContent}
    </Button>
  );
};

export default QuestionBankButton;
