/* eslint-disable no-unused-vars */
import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import NavSideBar from "../NavSideBar/NavSideBar";
import "./Layouts.scss";
import AddEmpDetails from "../../features/AddEmpDetails/AddEmpDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "../../store/selectors/authSelectors";
import { loadUserDetails } from "../../store/actions/authActions";
import { CollapsedRoutes } from "../../constants/SideNavRoutes";
import { sidenavbar } from "../../constants/sideNavBarRoutes";
import { group } from "../../constants/groups";
// eslint-disable-next-line react/prop-types
const Layouts = ({ groups }) => {
  const { Content } = Layout;
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const dynamicRoutes = [];
  const groupArr = [];
  const isAdminSettingPage = location.pathname.includes("/admin-settings");
  const [excludeNav, setExcludeNav] = useState(false);
  groups?.forEach((item) => {
    dynamicRoutes.push(...sidenavbar[group[item.id]]);
    groupArr.push(item.id);
  });
  const allRoutes = [...sidenavbar[group[0]], ...dynamicRoutes];
  const uniquePaths = new Set();

  const filteredData = allRoutes.filter((item) => {
    if (!uniquePaths.has(item.to)) {
      uniquePaths.add(item.to);
      return true;
    }
    return false;
  });
  for (let i = 0; i < filteredData.length; i++) {
    if (
      (groupArr.includes(1) || groupArr.includes(3)) &&
      filteredData[i].to === "/courses"
    ) {
      filteredData.splice(i, 1); // Remove the element at index i
      i--;
    }
  }
  const idRegrex =
    "([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})";
  const checkRoute = (routes) => {
    let collapsed = false;
    const url = window.location.hash;

    const regrex = new RegExp(`^/([a-zA-Z0-9_.~-]+)/${idRegrex}`);

    const match = regrex.test(url.replace("#", ""));
    if (match) {
      collapsed = true;
    } else if (url.endsWith("/preview")) {
      // Check if URL ends with "/preview"
      collapsed = true;
    }
    return collapsed;
  };
  const collapse = checkRoute(CollapsedRoutes);
  const is404Page = location.pathname === "/404";
  const isNavExcluded = () => {
    let regrex = new RegExp(`/tests/${idRegrex}/start/${idRegrex}`);
    return location.pathname === "/404" || regrex.test(location.pathname);
  };
  useEffect(() => {
    if (isNavExcluded()) setExcludeNav(true);
    else setExcludeNav(false);
  }, [location.pathname]);
  useEffect(() => {
    if (!user) dispatch(loadUserDetails());
  }, [user]);
  return (
    <Layout className="home-layout" data-testid="layout">
      {!excludeNav && (
        <NavSideBar
          routePaths={
            isAdminSettingPage
              ? sidenavbar["PORTAL_ADMIN_SETTINGS"]
              : filteredData
          }
          collapse={collapse}
        />
      )}
      <Layout>
        {/* <Header className="header-section">
          <Navbar />
        </Header> */}
        <Content className="main-section">
          <Outlet />
        </Content>
      </Layout>
      {user?.emp_id === "CTE-00000" ||
      (user?.gitlab_username === null && !is404Page) ? (
        <AddEmpDetails user={user} />
      ) : null}
    </Layout>
  );
};

export default Layouts;
