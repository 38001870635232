import "./SignUpPage.scss";
import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import LogoAndInfoSection from "../LoginPage/LogoAndInfoSection.js";
import SignUpForm from "../../features/Auth/SignUpForm.js";
import {
  groupArraySelector,
  isLoggedInSelector,
  loginFormSelector,
} from "../../store/selectors/authSelectors.js";
import GoogleLoginButton from "../../features/Auth/GoogleLoginButton.js";
import { useDispatch } from "react-redux";
import {
  //   createTenant,
  getClientId,
  validateTokenAction,
} from "../../store/actions/authActions.js";
import { isTenantMode } from "../../libs/utils.js";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner.js";

const SignUpContainer = () => {
  const isTenant = isTenantMode();
  const loginSelector = useSelector(loginFormSelector);
  const dispatch = useDispatch();

  return (
    <div className="form-section">
      <div className="form-content">
        <div className="login-form">
          <div className="auth-form-title">Sign Up</div>

          <SignUpForm />

          {loginSelector?.google_creds && (
            <GoogleLoginButton
              action={() => !isTenant && dispatch(validateTokenAction())}
            />
          )}
          <p>
            Already have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTenant = isTenantMode();
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = localStorage.getItem("token");
  const isLoggedIn = useSelector(isLoggedInSelector);
  const groupsArr = useSelector(groupArraySelector);
  const isHrGroup = () => {
    return groupsArr.includes("HR") || groupsArr.includes("PORTAL_ADMIN");
  };

  const redirectToHome = () => {
    if (isLoggedIn) {
      if (isHrGroup()) {
        navigate("/courses/all", { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  };
  useEffect(() => {
    if (isTenant) dispatch(getClientId());
  }, [isTenant]);

  useEffect(() => {
    const url = window.location.hash;
    const token = url.split("?token=")[1];
    if (token) {
      localStorage.setItem("token", token);
      window.location.replace("/#/courses/all");
    }
  }, []);

  useEffect(() => {
    if (accessToken === null) {
      setIsLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    redirectToHome();
    return () => {};
  }, [isLoggedIn]);

  return (
    <>
      {isLoading ? (
        <div className="login-spinner">
          <Spinner />
        </div>
      ) : (
        <div className="auth-container">
          <div className="logo-info">
            <LogoAndInfoSection />
          </div>
          <div className="forms-info">
            <SignUpContainer />
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
