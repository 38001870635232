import "./ForgotPasswordPage.scss";
import ForgotPasswordContainer from "./ForgotPassWordContainer";
import LogoAndInfoSection from "../LoginPage/LogoAndInfoSection";

const ForgotPassword = () => {
  return (
    <div className="login-container">
      <div
        className={`sign-header  ""
        }`}
      >
        <img
          className="login-image"
          src={require("../../assets/learning.png")}
        />

        <div className="login-titles">Reset your Password</div>
        <div className="login-subtitle">
          Access your mail to reset your password to EigenLearn
        </div>
      </div>

      <>
        <ForgotPasswordContainer />
      </>
    </div>
  );
};

const ForgotPasswordPage = () => {
  return (
    <div data-testid="section" className="section">
      <div className="loginPage-container">
        <LogoAndInfoSection />
        <ForgotPassword />
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
