/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMcqs } from "../../../../store/actions/wizardActions";
import {
  currentEditingQuestions,
  currentEditingTestDetails,
} from "../../../../store/selectors/wizardSelectors";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import QuestionsList from "./QuestionsList";
import QuestionEmptyContainer from "./QuestionEmptyContainer";
import "./QuestionManagerMcq.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import QuestionForm from "./QuestionForm";
import _ from "lodash";
import QuestionReorder from "./QuestionReorder";
import Spinner from "../../../../components/Spinner/Spinner";
import QuestionBankManager from "./QuestionBankManager";

const QuestionManagerMcq = () => {
  const navigate = useNavigate();
  const mcqListId = useSelector(currentEditingTestDetails);
  const dispatch = useDispatch();
  const courseId = location.hash.split("/")[2];
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [searchParams] = useSearchParams();
  const [reorderSection, setReorderSection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionBank, setQuestionBank] = useState(false);
  const userLocation = useLocation();
  useEffect(() => {
    if (location.hash.includes("id")) {
      setShowQuestionForm(true);
    } else {
      setShowQuestionForm(false);
    }
    if (searchParams.get("questionbank")) {
      setQuestionBank(true);
    } else {
      setQuestionBank(false);
    }
  }, [location.hash, showQuestionForm, questionBank]);
  const handleButtonClick = () => {
    navigate({
      pathname: `/courses/${courseId}/test/${mcqListId?.id}/manager`,
      search: "?id=new",
    });
  };
  const handleButtonClickChooseQB = () => {
    navigate({
      pathname: `/courses/${courseId}/test/${mcqListId?.id}/manager`,
      search: "?questionbank=true",
    });
  };
  const handlCancel = () => {
    setShowQuestionForm(false);
    navigate(`/courses/${courseId}/test/${mcqListId?.id}/manager`);
  };
  const currentEditingQuestionsList = useSelector(currentEditingQuestions);

  useEffect(() => {
    if (mcqListId?.id) {
      setIsLoading(true);
      if (!userLocation.search.includes("?search="))
        dispatch(getMcqs(mcqListId.id, {}, setIsLoading));
    }
  }, [userLocation.search]);

  const handleReorderSectionStateChange = (updatedState) => {
    setReorderSection(updatedState);
  };

  const buttonProps = [
    {
      label: "Choose From Question Bank",
      className: "choose-from-question-button",
      onClick: handleButtonClickChooseQB,
    },
    {
      label: "Add Question",
      className: "add-question-button",
      onClick: handleButtonClick,
      style: {
        backgroundColor: "#1A2B3C",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "21px",
        letterSpacing: "0em",
        textAlign: "center",
      },
    },
  ];
  return (
    <>
      {questionBank && <QuestionBankManager />}
      {showQuestionForm && !questionBank && (
        <QuestionForm handlCancel={handlCancel} />
      )}
      {!showQuestionForm && !questionBank && (
        <>
          {reorderSection ? (
            <QuestionReorder
              reorderSectionStateChange={handleReorderSectionStateChange}
            />
          ) : (
            <>
              {isEmpty(currentEditingQuestionsList?.results) &&
              !searchParams.toString() &&
              !isLoading ? (
                <QuestionEmptyContainer
                  buttonProps={buttonProps}
                  isQuestionBank={true}
                />
              ) : isEmpty(currentEditingQuestionsList?.results) &&
                !searchParams.toString() &&
                isLoading ? (
                <Spinner />
              ) : (
                <QuestionsList
                  reorderSectionStateChange={handleReorderSectionStateChange}
                  questions={currentEditingQuestionsList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionManagerMcq;
