import "./AuthenticationSettingPage.scss";
import Breadcrumbs from "../../../components/BreadCrumb/BreadCrumbs";
import { Switch } from "antd";
import { adminSettingConstants } from "../../../libs/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getAuthPasswordBasedLoginSetting,
  postAuthPasswordBasedSetting,
} from "../../../store/actions/adminActions";
import { useDispatch } from "react-redux";
import Spinner from "../../../components/Spinner/Spinner";
getAuthPasswordBasedLoginSetting;
const AuthenticationSettingPage = () => {
  const omittedPositions = [0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordChecked, setPasswordChecked] = useState(true);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [toggleLoading, setToggleLoading] = useState(true);
  const [isNotAccepted, setIsNotAccepted] = useState(false);
  const path = useLocation();
  useEffect(() => {
    dispatch(
      getAuthPasswordBasedLoginSetting(setIsAuthLoading, setPasswordChecked)
    );
  }, [path.pathname]);

  const handleConfigure = (path) => {
    navigate(`/admin-settings/authentication/${path}/`);
  };

  const handleSwitchChange = () => {
    setToggleLoading(false);
    setIsNotAccepted(false);
    const data = passwordChecked ? "NO" : "YES";
    dispatch(
      postAuthPasswordBasedSetting(data, setToggleLoading, setIsNotAccepted)
    );
    setPasswordChecked(!passwordChecked);
  };
  useEffect(() => {
    if (isNotAccepted) {
      setPasswordChecked(true);
    }
  }, [isNotAccepted]);

  return (
    <>
      {isAuthLoading ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className="admin-authentication-container">
          <Breadcrumbs omit={omittedPositions} />
          <div className="admin-authentication-heading">
            Manage Authentication
            <p>Configure authentication modes for your team.</p>
          </div>
          <div className="admin-authentication-modes-container">
            Authentication modes
            <div className="authentication-mode-item">
              <div>
                Password based login
                <p>{adminSettingConstants["PASSWORD_TEXT"]}</p>
              </div>
              {toggleLoading ? (
                <Switch
                  onClick={handleSwitchChange}
                  checked={passwordChecked}
                  size="small"
                  onChange={() => {}}
                  style={{
                    backgroundColor: passwordChecked ? "#1A2B3C" : "#D3D3D3",
                  }}
                />
              ) : (
                <div className="Blank_spinner_toggle">
                  <Spinner text={" "} />
                </div>
              )}
            </div>
            <div className="authentication-mode-item">
              <div>
                Google
                <p>{adminSettingConstants["GOOGLE_TEXT"]}</p>
              </div>
              <button
                className="authentication-mode-configure-button"
                onClick={() => handleConfigure("google")}
              >
                Configure
              </button>
            </div>
            <div className="authentication-mode-item">
              <div>
                LDAP
                <p>{adminSettingConstants["LDAP_TEXT"]}</p>
              </div>
              <button
                className="authentication-mode-configure-button"
                onClick={() => handleConfigure("ldap")}
              >
                Configure
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthenticationSettingPage;
