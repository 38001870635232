import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddAI from "../pages/Gen-AI/Ask-AI/AddComponent/AddAI";
import AskAiTabScreen from "../pages/Gen-AI/Ask-AI/AskAiTabScreen/AskAiTabScreen";

export const AskAItabConfigurations = [
  {
    key: "add",
    icon: <FontAwesomeIcon icon={faPlus} />,
    label: "Add",
    content: <AddAI />,
    shouldRender: true,
  },
  {
    key: "jobs",
    icon: <FontAwesomeIcon icon={faEdit} />,
    label: "Jobs",
    content: <AskAiTabScreen />,
    shouldRender: true,
  },
];

export const JOB_FILTER_VALUES = [
  { id: "REVIEWED", value: "Reviewed" },
  { id: "IN_PROGRESS", value: "Processing" },
  { id: "IN_REVIEW", value: "In Review" },
];

export const JOB_DEFAULT_PAGE_PARAMS = {
  defaultLimit: 10,
  defaultOffset: 0,
};
