import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AskAItabConfigurations } from "../../../constants/AskAITabs";
import "./AskAI.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_JOB_SELECTED } from "../../../store/actiontypes";
const AskAITabsDisplay = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTabChange = (key) => {
    setActiveTab(key);
    dispatch({
      type: SET_JOB_SELECTED,
      payload: false,
    });
    navigate(`/ask-ai/${key}`);
  };

  return (
    <div className="askaiLayout-container">
      <Tabs
        defaultActiveKey="details"
        activeKey={activeTab}
        className="tabs-container"
        onChange={handleTabChange}
      >
        {AskAItabConfigurations.map((tab) => {
          if (tab.shouldRender) {
            return (
              <TabPane
                key={tab.key}
                tab={
                  <div className="container1">
                    <span>
                      {tab.icon}
                      {tab.label}
                    </span>
                  </div>
                }
                className={
                  activeTab === tab.key ? "active-tab" : "inactive-tab"
                }
                forceRender={true}
              >
                {activeTab === tab.key && tab.content}
              </TabPane>
            );
          } else {
            return null;
          }
        })}
      </Tabs>
    </div>
  );
};

export default AskAITabsDisplay;
