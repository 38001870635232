import { usePrompt } from "../hooks/usePrompt";
import { UNSAVED_CHANGES_MESSAGE } from "../pages/AdminSettingsPage/AdminSettingsUtils";

const withPrompt = (Component, promptConfig) => {
  const WrappedComponent = (props) => {
    usePrompt({
      ...promptConfig,
      message: UNSAVED_CHANGES_MESSAGE,
    });

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `withPrompt(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

export default withPrompt;
