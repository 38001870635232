import { invalidEmailDomains } from "../../constants";
import { isTenantMode } from "../../libs/utils";
import { StyledInputField } from "../../components/StyledFields/StyledInputField";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "antd";
import { createTenant, signUpAction } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
const SignUpForm = () => {
  const dispatch = useDispatch();
  const isTenant = isTenantMode();
  const isCorporateEmail = (email) => {
    const domain = email.split("@")[1];
    return (
      !invalidEmailDomains.includes(domain) &&
      !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    );
  };

  const commonValidationSchema = {
    email: Yup.string()
      .email("Please enter a valid email address")
      .test(
        "is-corporate-email",
        "Please use your valid work email address",
        isCorporateEmail
      )
      .required("Work email is required"),
    password: Yup.string().required("Password is required").min(8),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  };

  const nonTenantValidationSchema = Yup.object({
    user_name: Yup.string().required("Full name is required"),
    organization_name: Yup.string()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Invalid name format. Only alphabets, numeric, and underscores are allowed"
      )
      .required("Organization name is required"),
    // Invalid name format. Only alphabets, numeric and underscores are allowed
    ...commonValidationSchema,
  });

  const tenantValidationSchema = Yup.object({
    ...commonValidationSchema,
  });

  const fieldConfigs = (isTenant) => [
    {
      name: "user_name",
      label: "Full Name",
      type: "text",
      placeholder: "Enter full name",
      required: !isTenant,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "name@work.co.in",
      required: true,
    },
    {
      name: "organization_name",
      label: "Organization Name",
      type: "text",
      placeholder: "Enter organization name",
      required: !isTenant,
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Enter password",
      required: true,
    },
    {
      name: "confirm_password",
      label: "Confirm Password",
      type: "password",
      placeholder: "Confirm Password",
      required: true,
    },
  ];

  const validationSchema = isTenant
    ? tenantValidationSchema
    : nonTenantValidationSchema;
  const fieldConfig = fieldConfigs(isTenant);

  const submitHandler = (values, { setSubmitting }) => {
    const handleError = () => {
      setSubmitting(false);
    };
    const handleSuccess = () => {
      setSubmitting(false);
    };

    setSubmitting(true);
    isTenant &&
      dispatch(
        signUpAction(
          {
            user_name: values.user_name,
            password: values.password,
            email: values.email,
          },
          handleSuccess,
          handleError
        )
      );
    !isTenant && dispatch(createTenant(values, handleSuccess, handleError));
  };

  return (
    <div>
      <Formik
        initialValues={fieldConfig.reduce((acc, field) => {
          acc[field.name] = "";
          return acc;
        }, {})}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ setFieldValue, setFieldTouched, isSubmitting, isValid, dirty }) => (
          <Form className="auth-form">
            {fieldConfig.map(
              (field) =>
                (field.required ||
                  !isTenant ||
                  field.name !== "organization_name") && (
                  <Field
                    key={field.name}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    isRequired
                    component={StyledInputField}
                    placeholder={field.placeholder}
                    onChange={(e) => {
                      setFieldTouched(field.name, true, true);
                      setFieldValue(field.name, e.target.value);
                    }}
                  />
                )
            )}
            <Button
              type="primary"
              size="large"
              block
              loading={isSubmitting}
              disabled={!isValid || !dirty}
              htmlType="submit"
              className="auth-btn"
            >
              Register
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
