import "./QuestionListItem.scss";
import parse from "html-react-parser";
import OptionMcq from "./McqOptions";
import { Tag } from "antd";
import { isEmpty } from "lodash";
import { Field, Form, Formik } from "formik";
export const QuestionListItem = ({
  question,
  options,
  userSelectedAnswer,
  disabled,
  handleChange,
  givingTest,
  isQuestionReviewList = false,
}) => {
  const checkQuestionImgTag = (text) => {
    if (!givingTest) {
      let imageTagStr = text.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/i);
      if (imageTagStr) {
        text = text.replaceAll(/<img[^>]*>/g, "< Question Image >&nbsp;");
      }
    }
    return text;
  };
  const questiontext = parse(
    `<div classname=${!givingTest ? "para-graph" : ""}>${checkQuestionImgTag(
      question?.question_text
    )}</div>`
  );
  return (
    <div className="questionListitem">
      <div className="questionListitem-body">{questiontext}</div>
      {options?.length > 0 ? (
        options?.map((option, index) => (
          <div key={index}>
            {/* {question?.question_type !== "Subjective" || disabled ? ( */}
            <OptionMcq
              userSelectedAnswer={userSelectedAnswer}
              option={option}
              question_type={question.question_type}
              disabled={disabled}
              handleChange={handleChange}
            />
          </div>
        ))
      ) : (
        <Formik initialValues={{ subjectiveAnswer: userSelectedAnswer }}>
          {({ setFieldValue }) => (
            <Form className="form-subjective" data-testid="form-div">
              <Field
                as="textarea"
                type="textarea"
                name={"subjectiveAnswer"}
                className="subjective-answer-text-area"
                showCount={true}
                maxLength={5000}
                onChange={(event) => {
                  setFieldValue("subjectiveAnswer", event.target.value);
                  handleChange({
                    target: {
                      name: "userSelectedAnswer",
                      value: event.target.value,
                    },
                  });
                }}
                rows={15}
                autoSize={{
                  minRows: 15,
                  maxRows: 15,
                }}
                placeholder="Enter your answer here."
              />
            </Form>
          )}
        </Formik>
      )}
      {isQuestionReviewList && (
        <div className="questionListitem-tags">
          {!isEmpty(question.tags) && (
            <>
              Tags:{" "}
              {question.tags.map((tag, index) => (
                <Tag key={index} className="questionList-tag">
                  {tag.tag_name}
                </Tag>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};
